import { Component, OnInit, Inject, ElementRef, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PhotoService } from 'src/app/core/services/photo.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tagged-dogs-dialog',
  templateUrl: './tagged-dogs-dialog.component.html',
  styleUrls: ['./tagged-dogs-dialog.component.scss']
})
export class TaggedDogsDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  dogsFormGroup = [];
  dogId: number;
  showSpinner = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public overlayContainer: OverlayContainer,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private photoService: PhotoService,
    private googleAnalyticsEvents: GooleAnalyticsEventsService) {

    this.matIconRegistry.addSvgIcon(
      'close_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/close_icon.svg')
    );

    overlayContainer.getContainerElement().classList.add('tagged-dogs-dialog_overlay');
    this.form = this.formBuilder.group({
      dogs: new UntypedFormArray([])
    });
  }

  ngOnInit() {
    this.data.imageObj.taggedDogsObj.forEach(obj => {
      const control = new UntypedFormControl(false);

      if (obj.onFlexi) {
        control.setValue(obj.dog_id);
      }
      (this.form.controls.dogs as UntypedFormArray).push(control);
    });

    this.dogsFormGroup.push(this.form.controls.dogs['controls']);
  }

  radioButtonOnChange(dogId) {
    this.dogId = dogId;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(form) {
    if (this.dogId) {
      this.showSpinner = true;
      const dogObj = {
        dog_id: this.dogId,
        photo_id: this.data.imageObj.id
      };
      this.photoService.setTaggedDogPic(dogObj).subscribe(resp => {
        if (resp['success']) {
          this.dialogRef.close();
          this.googleEvent('set profile photo', 'viewing photo');
          this.showSpinner = false;
          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Success', body: 'Your profile picture has been updated.', type: 'success' }
          });
        }
      }, err => {
        console.warn('FAILED!', err);
      });
    } else {
      this.dialog.open(NotificationMessageComponent, {
        data: { heading: 'Missing selection', body: 'Please select a dog', type: 'warning' }
      });
    }
  }

  ngOnDestroy() {
    this.overlayContainer.getContainerElement().classList.remove('tagged-dogs-dialog_overlay');
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'photo viewer - ' + section, 'photo viewer', 10);
  }

}
