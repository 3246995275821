import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { A11yModule } from '@angular/cdk/a11y';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { ErrorComponent } from 'src/app/core/error/error.component';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@NgModule({
  declarations: [ErrorComponent],
  imports: [CommonModule, OverlayModule, A11yModule, MatButtonModule]
})
export class ErrorHandlerModule {
  public static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
      ]
    };
  }
}
