import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-arrow-text-button',
  templateUrl: './arrow-text-button.component.html',
  styleUrls: ['./arrow-text-button.component.scss']
})
export class ArrowTextButtonComponent {
  @Input() linkPath: string;
  @Input() isMatButton: boolean;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'chevron_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/chevron_right.svg')
    );
  }

}
