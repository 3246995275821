import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, Form } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/shared/models/user';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { BrowserHelper } from 'src/app/core/helpers/browser-helper'
import { checkIfHtmlExists } from '../validators/sanitize-validator';
import { CookieService } from 'ngx-cookie-service';
import { SanitizeHelper } from '../helpers/sanitizer';
import { MobileAppService } from '../services/mobile-app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  private breakpointSub: Subscription;
  form: UntypedFormGroup;
  showPasswordValue = false;
  loginErrorMessage: string;
  headerImage: string;
  isLoading = false;
  isDesktopLayout: boolean;
  year: number;
  loginForm: Form;
  showPageSpinner: boolean;
  appLoaderAnimation = './../../../../../assets/images/dogloading.gif';
  logoImage = './../../../../../assets/images/bddc-bouncer-logo.png';
  googleLogo = './../../../../../assets/images/icons/android_icon.png';
  appleLogo = './../../../../../assets/images/icons/apple_icon.png';
  topImage = './../../../../../assets/images/backgrounds/components/top_image.png';
  bottomImage = './../../../../../assets/images/backgrounds/components/bottom_image.png';
  formIsValid = false;
  sanitizeHelper: SanitizeHelper;

  // tslint:disable-next-line: max-line-length
  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    breakpointObserver: BreakpointObserver,
    private app: AppComponent,
    private title: Title,
    private browserHelper: BrowserHelper,
    private mobileAppService: MobileAppService,
    private sanitizer: DomSanitizer) {
    this.sanitizeHelper = new SanitizeHelper(this.sanitizer);
    this.showPageSpinner = false;
    this.breakpointSub = breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/mobile_bg.jpg';
        this.isDesktopLayout = false;
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/desktop_bg.jpg';
        this.isDesktopLayout = true;
      }
    });
  }
  ngOnInit() {
    this.title.setTitle('Bruce\'s | Login');
    this.year = new Date().getFullYear();
    if (this.authService.isAuth()) {
      this.router.navigate(['/home']);
    }
    this.initForm({} as User);
  }

  isMobile(): boolean {
    return this.mobileAppService.shouldOnShowMobileDevice();
  }

  ngAfterViewInit(): void {
    this.app.pageReady.subscribe(res => {
      this.showPageSpinner = res;
    }, err => {
      console.error('PAGE LOADER BROKEN! ', err);
    });
  }
  initForm(user: User): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(user.email, [Validators.required, checkIfHtmlExists, Validators.maxLength(254)]),
      password: new UntypedFormControl(user.password, [Validators.required, Validators.maxLength(100), checkIfHtmlExists]),
    });
  }
  // TODO: extract into abstract helper class:
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  onBlurMethod() {
    if (this.form.controls.email.value) {
      this.form.controls.email.setValue(this.form.controls.email.value.replace(/\s+/g, ''));
      this.form.controls.email.setValue(this.form.controls.email.value.replace('\s*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})\s*', ''));
    }

    let errorPresent = false;
    if (this.form.controls.email.dirty) {
      if (!this.form.controls.email.value) {
        errorPresent = true;
      }

      // "i" modifier passed to make expression case insensitive
      const validationExpression = new RegExp('\s*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})\s*', 'i');

      if (!this.form.controls.email.value.match(validationExpression)) {
        errorPresent = true;
      }
    }

    if (errorPresent) {
      this.form.controls.email.setErrors({ valid: false });
    }

    if (this.form.controls.password.dirty) {
      if (!this.form.controls.password.value) {
        this.form.controls.password.setErrors({ valid: false });
      }
    }

    if (this.form.controls.email.valid && this.form.controls.password.valid) {
      this.formIsValid = true;
    }
  }

  logUserIn(data: any): void {
    const formValues = Object.assign({}, data);
    const trackData: any = this.browserHelper.getBrowserInfo();
    const defaultLoginError = `The login details you've provided are incorrect.`;
    let validationLoginError = `Email or password are too long or contains invalid content.`;

    let email = this.form.controls.email.value;
    let password = this.form.controls.password.value;

    let emailEmpty = (email == null || email.trim() == '');
    let passwordEmpty = (password == null || password.trim() == '');

    if (emailEmpty && passwordEmpty) {
      validationLoginError = `Email and password cannot be empty`;
    } else {
      if (emailEmpty) {
        validationLoginError = `Email cannot be empty`;
      } else if (passwordEmpty) {
        validationLoginError = `Password cannot be empty`;
      }
    }

    if (this.form.valid && Validators.email(this.form.controls.email) == null) {
      this.authService.trackLoginAttempt(this.sanitizeHelper.fullSanitize(formValues.email), this.sanitizeHelper.fullSanitize(trackData)).subscribe(response => {
        this.isLoading = true;
        formValues.email = this.sanitizeHelper.fullSanitize(formValues.email);
        formValues.password = this.sanitizeHelper.fullSanitize(formValues.password);
        this.authService.loginUser(formValues)
          .subscribe(
            resp => {
              // Redirect if staff member
              if (resp.isStaff) {
                this.enableErrorMode(defaultLoginError);
                return;
              }
              if (resp === false) {
                this.enableErrorMode(defaultLoginError);
                return;
              } else {
                this.setTitle(`Bruce\'s | Home`);
                this.router.navigate(['/home']);
              }
            },
            error => {
              this.enableErrorMode(error.error.message);
            }
          );
      }
      );
    } else {
      this.loginErrorMessage = validationLoginError;
    }
  }
  enableErrorMode(message) {
    this.isLoading = false;
    this.loginErrorMessage = message;
    this.form.controls.email.markAsDirty();
    this.form.controls.password.markAsDirty();
  }
  showPassword(): void {
    this.showPasswordValue = !this.showPasswordValue;
  }
  rememberMe(): void {
    this.authService.rememberMeSession();
  }
  goToforgotPassword(): void {
    this.router.navigate(['forgot']);
  }
  goToCookies(): void {
    this.router.navigate(['cookies-public']);
  }
  ngOnDestroy() {
    this.breakpointSub.unsubscribe();
  }
  detectWebBrowser(agent, data) {
    let i = 0;
    let j = 0;
    let regex;
    let regexv;
    let match;
    let matches;
    let version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(agent);
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
        matches = agent.match(regexv);
        version = '';
        if (matches) { if (matches[1]) { matches = matches[1]; } }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) { version += matches[j] + '.'; } else { version += matches[j]; }
          }
        } else { version = '0'; }
        return {
          name: data[i].name,
          version: parseFloat(version)
        };
      }
    }
    return { name: 'unknown', version: 0 };
  }

  openUrl(url) {
    window.open(
      url, "_blank")
  }
}
