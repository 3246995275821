import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';

import { PhotoService } from 'src/app/core/services/photo.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PhotoViewComponent } from 'src/app/modules/main/pages/photos/photo-view/photo-view.component';
import { SwiperPhotos } from 'src/app/shared/interfaces/swiper-photos.interface';
import { SharePhotoComponent } from 'src/app/modules/main/pages/photos/share-photo/share-photo.component';

@Component({
  selector: 'app-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss']
})

export class PhotoCarouselComponent implements OnInit, OnChanges, OnDestroy {
  private subscription: Subscription;
  @Input() heading: string;
  images: any[];
  photoCount: any;
  photoCountCurrent: any;
  domEl: HTMLElement;
  startIndex: number;
  modalPhotosData: any;
  config: SwiperConfigInterface;
  currentIndex = 1;
  photoViewerImages = null;
  showDialog = false;
  xDown: number;
  yDown: number;
  showSpinner: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(private photoService: PhotoService, private domSanitizer: DomSanitizer, private router: Router, private googleAnalyticsEvents: GooleAnalyticsEventsService, private dialog: MatDialog, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.config = {
          a11y: true,
          direction: 'horizontal',
          slidesPerView: 1,
          keyboard: false,
          mousewheel: false,
          scrollbar: false,
          pagination: false,
          initialSlide: 1,
          navigation: false,
          observer: true,
          simulateTouch: true,
          watchSlidesProgress: true,
          watchSlidesVisibility: true,
          centeredSlides: true,
          touchStartForcePreventDefault: true,
          touchStartPreventDefault: true
        };
      } else {
        this.config = {
          a11y: true,
          direction: 'horizontal',
          centeredSlides: true,
          slidesPerView: 3,
          keyboard: true,
          mousewheel: false,
          scrollbar: false,
          pagination: false,
          initialSlide: 1,
          navigation: true,
          simulateTouch: false
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.images) {
      if (changes.images.currentValue !== undefined) {
        // build carousel from images passed into component
        this.buildCarouselPhotoObject(this.images);
        // get list of photos and then build counter
        this.buildPhotoCounter();
      }
    }
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'home - ' + section, 'home', 10);
  }

  ngOnInit(): void {
    this.startIndex = 1;
    this.images = [];

    this.subscription = this.photoService.getPhotos(10).subscribe((result) => {
      if (result['photos'].length > 0) {
        this.images = result['photos'];
      }
      this.buildCarouselPhotoObject(this.images);
      this.sanitizeImages(this.images);
      this.buildPhotoCounter();
    }, err => {
      console.error('GET PHOTOS FETCH ERROR! ', err);
    });
  }

  public onIndexChange(index: number): void {
    this.currentIndex = index;
    this.photoCountCurrent = index + 1;

    this.googleEvent('recent photos', 'photos swipe');
  }

  buildCarouselPhotoObject(list: any[]) {
    const tempObj = list.map(item => {
      const photo = {
        id: item['id'],
        small: item['small'],
        medium: item['medium'],
        big: item['big'],
        isFavourite: item['isFavourite'] ? true : false,
        taggedDogs: item['taggedDogs'],
        taggedDogsObj: item['taggedDogsObj']
      };
      return photo;
    });

    this.images = tempObj;
  }

  buildPhotoCounter(): void {
    this.photoCount = this.images.length;
    this.photoCountCurrent = 2;
  }

  sanitizeImages(images) {
    images.forEach(image => {
      image['medium'] = this.domSanitizer.bypassSecurityTrustStyle(image['medium'])['changingThisBreaksApplicationSecurity'];
      image['small'] = this.domSanitizer.bypassSecurityTrustStyle(image['small'])['changingThisBreaksApplicationSecurity'];
      image['big'] = this.domSanitizer.bypassSecurityTrustStyle(image['big'])['changingThisBreaksApplicationSecurity'];
    });
  }

  navigateTo(path: string) {
    if (path === 'recent-photos') {
      this.googleEvent('recent photos', 'view all');
      this.showSpinner = true;
      setTimeout(() => {
        this.router.navigate(['/photos', 'my-photos']);
      }, 1000);
    }
  }

  openPhotoPreviewModal(clickEvent: any) {
    const selectedEl = clickEvent.target.parentElement;
    const clickedTarget = clickEvent.target;

    if (clickedTarget.className === 'swiper-button-prev' || clickedTarget.className === 'swiper-button-next') {
      this.showDialog = false;
    }
    if (clickedTarget.className === 'image') {
      const tempID = selectedEl.className.split('slide-item-');
      this.currentIndex = parseInt(tempID[1], 10);
      this.showDialog = true;
    }
    if (this.showDialog) {
      this.formatImagesForViewer(this.images);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        id: this.currentIndex,
        photos: this.photoViewerImages
      };

      const previewPhotoDialog = this.dialog.open(PhotoViewComponent, dialogConfig);
      previewPhotoDialog.componentInstance.photoToBeShared.subscribe(
        photoIdx => {
          this.openSharePhotoModal(photoIdx);
        },
        err => {
          console.error('PHOTO PREVIEW SHARING ERROR! ', err);
        }
      );
    }
  }

  openSharePhotoModal(photoIdx: string) {
    const shareDialogConfig = new MatDialogConfig();
    shareDialogConfig.data = {
      title: 'Share on social media',
      subtitle: 'Choose which social media platform you want to share this to.',
      tip: 'Having trouble sharing to Facebook or Twitter?',
      // tslint:disable-next-line: radix
      photoPath: this.photoViewerImages[parseInt(photoIdx)].path,
      // tslint:disable-next-line: radix
      dogsTagged: this.photoViewerImages[parseInt(photoIdx)].dogsTagged
    };

    this.dialog.open(SharePhotoComponent, shareDialogConfig);
  }

  formatImagesForViewer(photos: any) {
    // tslint:disable-next-line: prefer-const
    let photoObj: SwiperPhotos[] = [];
    photos.forEach(photo => {
      // tslint:disable-next-line: prefer-const
      let temp = {
        id: photo.id,
        path: photo.medium,
        isFavourite: photo.isFavourite,
        taggedDogs: photo.taggedDogs ? photo.taggedDogs : '',
        taggedDogsObj: photo.taggedDogsObj
      };
      photoObj.push(temp);
    });
    this.photoViewerImages = photoObj;
  }

  handleTouchStart(event) {
    const firstTouch = this.getTouches(event);

    if (firstTouch) {
      this.xDown = firstTouch[0].clientX;
      this.yDown = firstTouch[0].clientY;
    }
  }

  getTouches(event) {
    return event.touches;
  }

  swiperTouch(event) {
    if (event.touches) {
      const xUp = event.touches[0].clientX;
      const yUp = event.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      const positionX = event.pageX;
      const positionY = event.pageY - event.offsetY;
      const pageElement = event.offsetParent;

      if (yDiff > 0) {
        // window.scrollBy(0, 10);
        window.scrollBy({
          top: 15,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        // window.scrollBy(0, -10);
        window.scrollBy({
          top: -15,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
