import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  headerImage: string;
  headerTitle = '';

  constructor(breakpointObserver: BreakpointObserver, private title: Title) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerTitle = 'FAQs';
        this.headerImage = './../../../../../assets/images/backgrounds/settings/faq_header_mobile.jpg';
      } else {
        this.headerTitle = 'Our FAQs';
        this.headerImage = './../../../../../assets/images/backgrounds/settings/faq_header_desktop.jpg';
      }
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Bruce\'s | Fequently Asked Questions');
  }

}
