import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SafeHtml, Title, DomSanitizer } from '@angular/platform-browser';
import { TermsService } from 'src/app/core/services/terms.service';
import { Terms } from 'src/app/shared/interfaces/terms.interface';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  headerImage: string;
  termsandconditions: SafeHtml;

  constructor(breakpointObserver: BreakpointObserver, private title: Title, private termsService: TermsService, private domSanitizer: DomSanitizer) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/settings/terms_and_conditions_header_mobile.jpg';
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/settings/terms_and_conditions_header_desktop.jpg';
      }
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Bruce\'s | Terms And Conditions');

    this.termsService.getTerms().subscribe(
      (data: Terms) => {
        this.termsandconditions = this.domSanitizer.bypassSecurityTrustHtml(data.terms_and_conditions);
      },
      (error) => {
        console.error('Error fetching terms and conditions:', error);
      }
    );
  }
}
