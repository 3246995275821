<div class="email-share-dialog">
  <div class="email-share-dialog_title">
    <h1 class="{{ titleClass }}">{{ data.title }}</h1>
  </div>
  <div class="email-share-dialog_form">
    <form [formGroup]="form"
      (ngSubmit)="sendEmail(form.value)">
      <mat-form-field hideRequiredMarker="true"
        floatLabel="always"
        class="login-form_input login-form-field">
        <mat-label>Email Address</mat-label>
        <input matInput
          type="email"
          formControlName="email"
          placeholder="Email address"
          required
          class="border-none"
          email />
      </mat-form-field>

      <div mat-dialog-actions>
        <button *ngIf="!loading"
          mat-raised-button
          color="primary">
          {{ data.confirmButton }}
        </button>

        <button *ngIf="loading"
          [disabled]="loading"
          mat-raised-button>
          <div class="u-flex-center">
            <mat-spinner color="primary"
              diameter="30"></mat-spinner>
          </div>
        </button>
        <a mat-raised-button
          (click)="closeDialog($event)"
          class="cancel-button medium">{{ data.cancelButton }}</a>
      </div>
    </form>
  </div>
</div>