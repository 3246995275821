import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from './material/material.module';

import { AccountBalanceCardComponent } from './components/card/account-balance-card/account-balance-card.component';
import { ArrowTextButtonComponent } from './components/button/arrow-text-button/arrow-text-button.component';
import { DogSummaryCardComponent } from './components/card/dog-summary-card/dog-summary-card.component';
import { MilestoneBannerComponent } from './components/card/milestone-banner/milestone-banner.component';
import { MilestonesComponent } from '../modules/main/pages/profiles/milestones/milestones.component';
import { NotificationMessageComponent } from './components/notification/notification-message/notification-message.component';
import { PageHeaderComponent } from './components/layout/page-header/page-header.component';
import { NotificationSettingsComponent } from '../modules/main/pages/settings/notification-settings/notification-settings.component';
import { SharePhotoComponent } from '../modules/main/pages/photos/share-photo/share-photo.component';
import { EmailShareDialogComponent } from '../modules/main/pages/photos/share-photo/email-share-dialog/email-share-dialog.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DownloadAppButtonsComponent } from './components/download-app-buttons/download-app-buttons.component';

@NgModule({
  declarations: [
    NotificationMessageComponent,
    AccountBalanceCardComponent,
    PageHeaderComponent,
    MilestonesComponent,
    DogSummaryCardComponent,
    MilestoneBannerComponent,
    ArrowTextButtonComponent,
    NotificationSettingsComponent,
    SharePhotoComponent,
    EmailShareDialogComponent,
    DownloadAppButtonsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    FlexLayoutModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    NotificationMessageComponent,
    AccountBalanceCardComponent,
    PageHeaderComponent,
    MilestonesComponent,
    DogSummaryCardComponent,
    MilestoneBannerComponent,
    ArrowTextButtonComponent,
    NotificationSettingsComponent,
    SharePhotoComponent,
    EmailShareDialogComponent,
    SlickCarouselModule,
    DownloadAppButtonsComponent,
  ],
  providers: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
