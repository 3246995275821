import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header.component';
import { MessageUsDialogComponent } from 'src/app/modules/main/pages/contact/message-us-dialog/message-us-dialog.component';

@NgModule({
  declarations: [SidenavComponent, HeaderComponent, MessageUsDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [SidenavComponent, HeaderComponent]
})
export class HeaderModule { }
