import { Component } from '@angular/core';

@Component({
  selector: 'app-download-app-buttons',
  templateUrl: './download-app-buttons.component.html',
  styleUrls: ['./download-app-buttons.component.scss']
})
export class DownloadAppButtonsComponent {
  googleLogo = './../../../../../assets/images/icons/android_icon.png';
  appleLogo = './../../../../../assets/images/icons/apple_icon.png';

  constructor() { }

  openUrl(url) {
    window.open(url, "_blank");
  }
}
