<ng-container *ngIf="showPageSpinner">
  <div class="app-loader">
    <div class="u-flex-center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showPageSpinner">
  <app-page-header hideDebitStatus="true" extraClass="full-height {{ isDesktopLayout ? 'hidden' : '' }}"
    headerImage="{{ headerImage }}" imageSrc="{{ logoImage }}" imageAlt="BDDC Logo"></app-page-header>

  <div class="login-form_wrapper cr" [style.background-image]="isDesktopLayout ? 'url(' + headerImage + ')' : ''">
    <ng-container *ngIf="isDesktopLayout">
      <img img src="{{ logoImage }}" alt="BDDC Logo" />
    </ng-container>
    <div class="login-form {{ loginErrorMessage ? 'form-has-error' : '' }}">
      <h1 class="mat-headline">Pawtal Login</h1>
      <div class="mat-body-1 login-form_error {{
          loginErrorMessage ? 'show-error' : ''
        }}">
        {{ loginErrorMessage }}
      </div>

      <div class="login-form_wrapper">
        <form [formGroup]="form" (ngSubmit)="logUserIn(form.value)">
          <mat-form-field hideRequiredMarker="true" floatLabel="always" class="login-form_input login-form-field">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" placeholder="example@email.co.uk" (blur)="onBlurMethod()" required
              autocomplete="email" />
          </mat-form-field>

          <mat-form-field hideRequiredMarker="true" floatLabel="always" class="login-form_input login-form-field">
            <mat-label>Password</mat-label>
            <input matInput type="{{ showPasswordValue ? 'text' : 'password' }}" formControlName="password"
              placeholder="Password" (blur)="onBlurMethod()" required />
            <mat-icon matSuffix (click)="showPassword()" class="login-form_password-icon" color="primary">remove_red_eye
            </mat-icon>
          </mat-form-field>
          <div class="login-form_buttons {{
              loginErrorMessage ? 'form-has-error' : ''
            }}">
            <!-- <mat-checkbox (click)="rememberMe()" color="primary">Remember me</mat-checkbox> -->
            <a mat-button (click)="goToforgotPassword()" class="login-form-link">
              Forgot Password?
            </a>
          </div>

          <div class="login-form_buttons is-centered {{
              loginErrorMessage ? 'form-has-error' : ''
            }}">
            <button mat-raised-button color="primary" class="rounded">
              Login
            </button>
          </div>
          <ng-container *ngIf="isMobile()">
            <div class="u-text-center">
              <h1 class="mat-headline u-margin-bottom-15">Get your paws on our app!</h1>

              <p class="mat-body-1 u-text-center margin-bottom_30">For the best experience download our app today and
                bring your
                dog's day to life!
              </p>
              <app-download-app-buttons></app-download-app-buttons>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
    <div class="mat-body-1 copy-right-text">
      &copy; Bruce's {{ year }} |
      <span class="mat-body-2 copy-right-text_link" (click)="goToCookies()">Use of cookies</span>
    </div>
  </div>

  <div class="app-loader" *ngIf="isLoading">
    <div class="u-flex-center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
  </div>
</ng-container>