import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/core/services/auth.service';
import { MessageUsDialogComponent } from 'src/app/modules/main/pages/contact/message-us-dialog/message-us-dialog.component';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { GooleAnalyticsEventsService } from '../services/goole-analytics-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  logoPath = 'assets/images/bddc-bouncer-logo.png';
  customImages = [
    {
      image_name: 'chat_icon',
      image_path: 'assets/images/icons/chat_icon.svg'
    },
    {
      image_name: 'notification_icon',
      image_path: 'assets/images/icons/notification_icon.svg'
    },
    {
      image_name: 'menu_icon',
      image_path: 'assets/images/icons/menu_icon.svg'
    }
  ];
  disableBoxShadow: boolean;
  userActive: boolean;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(
    private focusMonitor: FocusMonitor,
    private titleService: Title,
    private authServce: AuthService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private googleAnalyticsEvents: GooleAnalyticsEventsService
  ) {
    this.userActive = localStorage.getItem('active') === 'true';
    this.customImages.forEach(image => {
      this.matIconRegistry.addSvgIcon(
        image.image_name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + image.image_path)
      );
    });
  }

  ngOnInit() {
    this.focusMonitor.stopMonitoring(document.getElementById('menu_bar'));
    if (this.router.url.includes('/photos')) {
      this.disableBoxShadow = true;
    } else {
      this.disableBoxShadow = false;
    }

    this.subscription = this.router.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.router.url.includes('/photos')) {
            this.disableBoxShadow = true;
          } else {
            this.disableBoxShadow = false;
          }
        }
      });
  }

  contactUsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Send us a message',
      confirmButton: 'Submit',
      cancelButton: 'Cancel'
    };

    this.googleEvent('contact us');

    const dialogRef = this.dialog.open(MessageUsDialogComponent, dialogConfig);
    dialogRef.componentInstance.sendMessage.subscribe(result => {
      if (result === true) {
        this.googleEvent('contact us');
        this.dialog.open(NotificationMessageComponent, {
          data: { heading: 'Your message has been sent!', body: 'Thank you for your message. We will be in contact soon.', type: 'success' }
        });
      }
    });
  }

  onLogOut() {
    this.authServce.logoutUser();
    this.googleEvent('log out');
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public setTitle(newTitle: string, navTitle: string) {
    this.titleService.setTitle(newTitle);
    this.googleEvent(navTitle);
  }

  googleEvent(cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'header', 'header', 10);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
