<app-page-header headerImage="{{ headerImage }}" extraClass="homepage"></app-page-header>
<div class="core-content">
  <div class="home-content_thumbnail-cards_container">
    <div class="home-content_thumbnail-cards_heading">
      <h1 class="mat-display-1">Quick booking</h1>
    </div>
    <div class="quick-booking-container">
      <ng-container *ngIf="thumbnailCards ">
        <div class="swiper-custom-prev quick-booking-prev"></div>
        <div class="home-content_thumbnail-cards" *ngIf="recommendedCards.length > 0">
          <swiper class="swiper-container-home-page home-override" [ngClass.lt-sm]="{ 'margin-right_30': true }"
            touch-action="none" data-swiper-parallax="100%" [config]="configQuickBooking"
            (swiperTouchStart)="handleTouchStart($event)" (swiperTouchMoveOpposite)="swiperTouch($event)">
            <app-thumbnail-icon-card (click)="
          viewService(
            thumbnailCard.cardText,
            'quick booking',
            thumbnailCard.cardText
          )
        " *ngFor="let thumbnailCard of thumbnailCards" thumbnail="{{ thumbnailCard.cardImage }}"
              text="{{ thumbnailCard.cardText }}"></app-thumbnail-icon-card>
          </swiper>
        </div>
        <div class="swiper-custom-next quick-booking-next"></div>
      </ng-container>
    </div>
    <!-- <div class="home-content_thumbnail-cards">
      <app-thumbnail-icon-card (click)="
          viewService(
            thumbnailCard.cardText,
            'quick booking',
            thumbnailCard.cardText
          )
        " *ngFor="let thumbnailCard of thumbnailCards" thumbnail="{{ thumbnailCard.cardImage }}"
        text="{{ thumbnailCard.cardText }}"></app-thumbnail-icon-card>
    </div> -->
  </div>

  <div class="home-content_dog-summary content-wrapper u-space-v_30">
    <app-dog-summary-card></app-dog-summary-card>
  </div>

  <div class="home-content_recommended_container content-wrapper u-space-v_30">
    <div class="home-content_heading">
      <h2 class="mat-display-2">Recommended for you</h2>
      <div class="home-content_recommended_heading-action">
        <app-arrow-text-button linkPath="test()" [routerLink]="userActive ? ['/services/booking'] : ''"
          (click)="googleEvent('recommended for you', 'view all')">
          <div linkText>view all</div>
        </app-arrow-text-button>
      </div>
    </div>

    <ng-container *ngIf="!recommendedHasData">
      <div class="u-flex-center">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
    </ng-container>

    <div id="recommended_swiper">
      <ng-container *ngIf="recommendedHasData && recommendedCards ">
        <div class="swiper-custom-prev recommended-prev"></div>
        <div class="home-content_recommended" *ngIf="recommendedCards.length > 0">
          <swiper class="swiper-container-home-page" [ngClass.lt-sm]="{ 'margin-right_30': true }" touch-action="none"
            data-swiper-parallax="100%" [config]="configRecommended" (swiperTouchStart)="handleTouchStart($event)"
            (swiperTouchMoveOpposite)="swiperTouch($event)">
            <app-medium-image-card *ngFor="let card of recommendedCards; let i = index" layout="vertical"
              hasOverlay="{{ card.overlayText }}" overlayText="{{ card.overlayText }}"
              hasNotification="{{ card.cardNotification }}" cardNotification="{{
                card.cardNotification ? card.cardNotification : ''
              }}" bannerImage="{{ card.image != null ? card.image : placeholder }}"
              cardText="{{ card.serviceDescription }}" cardPrice="{{ card.price }}" class="override-margin">
              <h2 card-title class="mat-subheading-2">{{ card.title }}</h2>
              <button (click)="viewService(i + 2, 'recommended for you', 'request')" card-button mat-raised-button
                color="primary" class="medium-image-card_button">
                Request
              </button>
            </app-medium-image-card>
          </swiper>
        </div>
        <div class="swiper-custom-next recommended-next"></div>
      </ng-container>
    </div>
  </div>

  <div class="home-content_recommended_container content-wrapper u-space-v_30" *ngIf="bookAgainCards.length > 0">
    <div class="home-content_heading">
      <h2 class="mat-display-2">Book again</h2>
      <div class="home-content_recommended_heading-action" *ngIf="bookAgainCards.length > 4">
        <app-arrow-text-button linkPath="test()">
          <div linkText>view all</div>
        </app-arrow-text-button>
      </div>
    </div>

    <ng-container *ngIf="!bookAgainHasData">
      <div class="u-flex-center">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="bookAgainHasData">
      <div class="swiper-custom-prev book-again-prev" *ngIf="bookAgainCards.length > 4"></div>
      <div class="home-content_recommended">
        <swiper class="swiper-container-home-page" [ngClass.lt-sm]="{ 'margin-right_30': true }" touch-action="none"
          data-swiper-parallax="100%" [config]="configBookAgain" (swiperTouchStart)="handleTouchStart($event)"
          (swiperTouchMoveOpposite)="swiperTouch($event)">
          <app-medium-image-card *ngFor="let card of bookAgainCards; let i = index" layout="vertical"
            hasOverlay="{{ card.overlayText }}" overlayText="{{ card.overlayText }}"
            hasNotification="{{ card.cardNotification }}" cardNotification="{{
              card.cardNotification ? card.cardNotification : ''
            }}" bannerImage="{{ card.image != null ? card.image : placeholder }}"
            cardText="{{ card.serviceDescription }}" cardPrice="{{ card.price }}" class="book-again">
            <h2 card-title class="mat-subheading-2">{{ card.title }}</h2>
            <button (click)="
                viewService(card.sliderIndex + 2, 'book again', 'request')
              " card-button mat-raised-button color="primary" class="medium-image-card_button">
              Request
            </button>
          </app-medium-image-card>
        </swiper>
      </div>
      <div class="swiper-custom-next book-again-next" *ngIf="bookAgainCards.length > 4"></div>
    </ng-container>
  </div>

  <div class="home-content_photos u-space-v_30">
    <app-photo-carousel heading="Recent photos"></app-photo-carousel>
  </div>

  <div class="home-content_service-calendar content-wrapper u-space-v_30">
    <div class="home-content_service-calendar_heading">
      <h2 class="mat-display-2">Services calendar</h2>
    </div>
    <div class="home-content_service-calendar_container">
      <app-service-calendar page="home"></app-service-calendar>
    </div>
  </div>

  <div class="home-content_service-calendar content-wrapper u-space-v_30">
    <div class="home-content_heading">
      <h2 class="mat-display-2">Your current balance due</h2>
    </div>
    <div class="home-content_service-calendar_container">
      <app-account-balance-card></app-account-balance-card>
    </div>
  </div>
</div>