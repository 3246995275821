<div style="background-image: url({{topImage}}), url({{bottomImage}}); background-position: left top, right bottom;
background-repeat: no-repeat;
background-size: 60%;
 padding: 20px">
  <h1 class="mat-headline u-margin-bottom-15">Get your paws on our app!</h1>

  <p class="mat-body-1 u-text-center margin-bottom_30">For the best experience download our app today and bring your
    dog's day to life!
  </p>

  <div class="u-text-center">
    <app-download-app-buttons></app-download-app-buttons>
    <div>
      <button mat-raised-button color="primary" class="rounded button-sizing" (click)="closeDialog()">
        Remind Me Later
      </button>
    </div>
  </div>
</div>