import { Component, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { AccountService } from 'src/app/core/services/account.service';
import { BookingService } from 'src/app/core/services/booking.service';
import { ReviewDialogComponent } from 'src/app/modules/main/pages/home/review-dialog/review-dialog.component';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { RequestStoreService } from 'src/app/core/services/request-store.service';
import { RequestModel } from 'src/app/core/models/request.model';
import { RequestType } from 'src/app/core/enums/request-type.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() serviceIndex: EventEmitter<any> = new EventEmitter();
  isAuth = false;
  headerImage: string;
  configRecommended: SwiperConfigInterface;
  configBookAgain: SwiperConfigInterface;
  configQuickBooking: SwiperConfigInterface;
  recommendedHasData = false;
  bookAgainHasData = false;
  placeholder = '';
  categories = [];
  xDown: number;
  yDown: number;
  userActive: boolean;

  dogBanners = [
    './../../assets/images/backgrounds/components/bath_brush_blow_dry.jpg',
    './../../assets/images/backgrounds/components/grooming.jpg',
    './../../assets/images/backgrounds/components/first_aid_course.jpg',
    './../../assets/images/backgrounds/components/nail_clipping.jpg',
    './../../assets/images/backgrounds/components/daycare.jpg',
    './../../assets/images/backgrounds/profile/sleepover_desktop.jpg'
  ];

  thumbnailCards = [
    {
      cardImage: this.dogBanners[4],
      cardText: 'Day care'
    },
    {
      cardImage: this.dogBanners[5],
      cardText: 'Sleepover'
    }
  ];

  recommendedCards: any;
  bookAgainCards: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private bookingService: BookingService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private titleService: Title,
    private cookieService: CookieService,
    private requestStore: RequestStoreService) {
    this.userActive = localStorage.getItem('active') === 'true';
    this.headerImage = './../../../../../assets/images/backgrounds/home/home_header.jpg';
    breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.placeholder = './../../../../../assets/images/backgrounds/photos/photos_placeholder_mobile.png';
      } else {
        this.placeholder = './../../../../../assets/images/backgrounds/photos/photos_placeholder_desktop.png';
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Bruce\'s | Home');

    this.recommendedCards = [];
    this.bookAgainCards = [];

    let requests: RequestModel[] = [];

    requests.push(new RequestModel(
      '/customer_object/has_direct_debit_update',
      RequestType.GET
    ));

    requests.push(new RequestModel(
      '/services/get_all_dynamic_services_home_page_update',
      RequestType.GET,
    ));

    requests.push(new RequestModel(
      '/services/get_book_again_services',
      RequestType.GET,
    ));

    this.requestStore.addMultipleRequest(requests);

    this.requestStore.dispatch().subscribe((data) => {
      if (data.length != 0) {
        if (data[0]) {
          const response = data[0]['response'];
          this.categories = response['categories'];
          this.thumbnailCards = [];
          if (this.categories) {
            this.thumbnailCards = [
              {
                cardImage: this.dogBanners[4],
                cardText: 'Day care'
              },
            ];

            this.categories.forEach(category => {
              let tempObj = {
                cardImage:
                  category['image'] !== null
                    ? category['image']
                    : this.dogBanners[1],
                cardText: category['name']
              };

              this.thumbnailCards.push(tempObj);
            });

            this.thumbnailCards.push({
              cardImage: this.dogBanners[5],
              cardText: 'Sleepover'
            });

          } else {
            this.thumbnailCards = [
              {
                cardImage: this.dogBanners[4],
                cardText: 'Day care'
              },
              {
                cardImage: this.dogBanners[5],
                cardText: 'Sleepover'
              }
            ];
          }

          if (response['display']) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              place_id: response['place_id']
            };
            this.dialog.open(ReviewDialogComponent, dialogConfig);
          }
        }

        if (data[1]) {
          this.recommendedCards = data[1]['all_services'];
          this.recommendedHasData = true;
        }

        if (data[2]) {
          this.bookAgainCards = data[2]['book_again_services'];
          this.bookAgainHasData = true;
        }
      }
    }, err => {
      this.dialog.open(NotificationMessageComponent, {
        data: {
          heading: 'Something went wrong our side!',
          body: 'Please contact Bruce\'s and ask for assistance',
          type: 'danger'
        }
      });

      console.error('MULTIPLE REQUEST FETCH ERROR! ', err);
    });

    this.configRecommended = {
      a11y: true,
      direction: 'horizontal',
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      pagination: false,
      initialSlide: 0,
      observer: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: true,
      centeredSlides: false,
      slidesPerView: 4,
      spaceBetween: 24,

      navigation: {
        nextEl: '.recommended-next',
        prevEl: '.recommended-prev'
      },

      // Responsive breakpoints
      breakpoints: {
        414: {
          slidesPerView: 1,
          spaceBetween: 35
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        // when window width is <= 1129px
        1129: {
          slidesPerView: 'auto',
          spaceBetween: 15
        }
      }
    };

    this.configBookAgain = {
      a11y: true,
      direction: 'horizontal',
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      pagination: false,
      initialSlide: 0,
      observer: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: true,
      centeredSlides: false,
      slidesPerView: 4,
      spaceBetween: 24,

      navigation: {
        nextEl: '.book-again-next',
        prevEl: '.book-again-prev'
      },

      // Responsive breakpoints
      breakpoints: {
        414: {
          slidesPerView: 1,
          spaceBetween: 35
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        // when window width is <= 1300px
        1280: {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    };

    this.configQuickBooking = {
      a11y: true,
      direction: 'horizontal',
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      pagination: false,
      initialSlide: 0,
      observer: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: true,
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 5,
      navigation: {
        nextEl: '.quick-booking-next',
        prevEl: '.quick-booking-prev'
      },

      // Responsive breakpoints
      breakpoints: {
        414: {
          slidesPerView: 2,
          spaceBetween: 1
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        // when window width is <= 1300px
        1280: {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    };
  }

  ngAfterViewInit(): void {
    this.setGlobalDate();
  }

  setGlobalDate() {
    const defaultSelectedDate = moment().format('YYYY-MM-DD');
    const selectedDate = localStorage.getItem('selected_date');

    if (!selectedDate) {
      localStorage.setItem('selected_date', defaultSelectedDate);
    }
  }

  handleTouchStart(event) {
    const firstTouch = this.getTouches(event);

    if (firstTouch) {
      this.xDown = firstTouch[0].clientX;
      this.yDown = firstTouch[0].clientY;
    }
  }

  getTouches(event) {
    return event.touches;
  }

  swiperTouch(event) {
    if (event.touches) {
      const xUp = event.touches[0].clientX;
      const yUp = event.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      const positionX = event.pageX;
      const positionY = event.pageY - event.offsetY;
      const pageElement = event.offsetParent;

      if (yDiff > 0) {
        // window.scrollBy(0, 10);
        window.scrollBy({
          top: 15,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        // window.scrollBy(0, -10);
        window.scrollBy({
          top: -15,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  viewService(sliderIndex, analyticsSection, analyticsTitle) {
    let dynamicCategory = this.categories.find(category => category['name'] === sliderIndex);
    if (this.userActive) {
      if (sliderIndex === 'Day care') {
        this.router.navigate(['/services/booking'], {
          queryParams: { serviceIndex: 0 },
          relativeTo: this.route
        });
      } else if (sliderIndex === 'Sleepover') {
        this.router.navigate(['/services/booking'], {
          queryParams: { serviceIndex: 1 },
          relativeTo: this.route
        });
      } else if (dynamicCategory) {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(['/services/service-category'], {
          queryParams: { categoryId: dynamicCategory['id'] },
          relativeTo: this.route
        });
      } else {
        this.router.navigate(['/services/booking'], {
          queryParams: { serviceIndex: sliderIndex },
          relativeTo: this.route
        });
      }
    }

    this.googleEvent(analyticsSection, analyticsTitle);
  }

  googleEvent(section, cardTitle) {
    this.googleAnalyticsEvents.eventEmitter(
      cardTitle,
      'home - ' + section,
      'home',
      10
    );
  }

  quickBooking(id, serviceDescription) {
    this.googleEvent('quick booking', serviceDescription);
    this.router.navigate(['service-category'], {
      queryParams: { categoryId: id },
      relativeTo: this.route
    });
  }

  onLogOut() {
    this.authService.logoutUser();
  }

  ngOnDestroy() {

  }
}
