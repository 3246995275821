import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-thumbnail-icon-card',
  templateUrl: './thumbnail-icon-card.component.html',
  styleUrls: ['./thumbnail-icon-card.component.scss']
})
export class ThumbnailIconCardComponent implements OnInit {
  @Input() text: string;
  @Input() thumbnail: string;
  @Input() size?: string;

  constructor() { }

  ngOnInit() {
  }

}
