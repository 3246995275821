import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';

import { RateUsDialogComponent } from '../../footer/rate-us-dialog/rate-us-dialog.component';

@Component({
  selector: 'app-email-rating',
  templateUrl: './email-rating.component.html',
  styleUrls: ['./email-rating.component.scss']
})
export class EmailRatingComponent implements OnInit {
  rating: any;
  customerId: any;

  constructor(private route: ActivatedRoute, private dialog: MatDialog) {
    this.rating = this.route.snapshot.paramMap.get('rating');
    this.customerId = this.route.snapshot.paramMap.get('customer');
  }

  ngOnInit() {
    const dialogConfig = new MatDialogConfig();
    switch (this.rating) {
      case '1': {
        this.rating = 'poor';
        break;
      }
      case '2': {
        this.rating = 'average';
        break;
      }
      case '3': {
        this.rating = 'good';
        break;
      }
    }

    dialogConfig.data = {
      usersRating: this.rating,
      customersId: this.customerId,
      closeAction: 'redirect'
    };
    this.dialog.open(RateUsDialogComponent, dialogConfig);
  }

}
