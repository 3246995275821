import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterComponent } from './footer.component';
import { RateUsDialogComponent } from './rate-us-dialog/rate-us-dialog.component';

@NgModule({
  declarations: [FooterComponent, RateUsDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [FooterComponent]
})
export class FooterModule {
}
