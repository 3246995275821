import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';

@Injectable({
  providedIn: 'root'
})

export class BannerNotificationService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  getBannerNotifications(): Observable<[]> {
    const apiUrl = '/customer_object/banner_notifications_all_html';
    const notifications = this.http.get<[]>(this.baseApiService.API_URL + apiUrl, {});
    return notifications;
  }
  sendReadBannerNotification(id: number) {
    const apiUrl = '/customer_object/banner_notification_read';
    const readNotification = this.http.post(this.baseApiService.API_URL + apiUrl, id);
    return readNotification
  }
}