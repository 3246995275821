import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export abstract class BaseApiService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(protected http: HttpClient, private cookieService: CookieService) { }

  API_TOKEN = this.cookieService.get('_bddc_access_token');
  API_URL = environment.apiUrl;

  keysToCamel = (o) => {
    if (this.isObject(o)) {
      const n = {};

      Object.keys(o)
        .forEach((k) => {
          n[this.toCamel(k)] = this.keysToCamel(o[k]);
        });

      return n;
    } else if (this.isArray(o)) {
      return o.map((i) => {
        return this.keysToCamel(i);
      });
    }

    return o;
  }

  toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  }

  isArray = (a) => {
    return Array.isArray(a);
  }

  isObject = (o) => {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
  }

  // Handle error method
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
