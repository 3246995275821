import { Component, Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Output() sidenavClose = new EventEmitter();
  logoPath = 'assets/images/bddc-bouncer-logo-resized.png';
  userActive: boolean;
  customImages = [
    {
      image_name: 'home_icon',
      image_path: 'assets/images/icons/home_icon.svg'
    },
    {
      image_name: 'services_icon',
      image_path: 'assets/images/icons/services_icon.svg'
    },
    {
      image_name: 'photos_icon',
      image_path: 'assets/images/icons/photos_icon.svg'
    },
    {
      image_name: 'profiles_icon',
      image_path: 'assets/images/icons/profiles_icon.svg'
    },
    {
      image_name: 'billing_icon',
      image_path: 'assets/images/icons/billing_icon.svg'
    },
    {
      image_name: 'settings_icon',
      image_path: 'assets/images/icons/settings_icon.svg'
    },
    {
      image_name: 'logout_icon',
      image_path: 'assets/images/icons/logout_icon.svg'
    },
  ];

  constructor(private authService: AuthService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.userActive = localStorage.getItem('active') === 'true';
    this.customImages.forEach(image => {
      this.matIconRegistry.addSvgIcon(
        image.image_name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + image.image_path)
      );
    });
  }

  onLogOut() {
    this.authService.logoutUser();
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }
}
