import { Injectable } from '@angular/core';

declare let gtag;

@Injectable({
  providedIn: 'root'
})

export class GooleAnalyticsEventsService {

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      event_value: eventValue,
      non_interaction: true
    });
  }
}
