import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FullCalendarModule } from '@fullcalendar/angular';

import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

import { MediumImageCardComponent } from './components/card/medium-image-card/medium-image-card.component';
import { ThumbnailIconCardComponent } from './components/card/thumbnail-icon-card/thumbnail-icon-card.component';
import { ServiceCalendarComponent } from './components/calendar/service-calendar/service-calendar.component';
import { CalendarDialogComponent } from './components/calendar/calendar-dialog/calendar-dialog.component';
import { PhotoViewComponent } from '../modules/main/pages/photos/photo-view/photo-view.component';
// tslint:disable-next-line: max-line-length
import { ServiceCalendarDialogComponent } from './components/calendar/service-calendar/service-calendar-dialog/service-calendar-dialog.component';
// tslint:disable-next-line: max-line-length
import { MiniCalendarPickerSwapDaysDialogComponent } from './components/calendar/mini-calendar-picker-swap-days/mini-calendar-picker-swap-days-dialog/mini-calendar-picker-swap-days-dialog.component';
import { SwapDayDialogCardComponent } from './components/card/swap-day-dialog-card/swap-day-dialog-card.component';
import { AddServiceDialogComponent } from './components/calendar/service-calendar/add-service-dialog/add-service-dialog.component';
import { TaggedDogsDialogComponent } from '../modules/main/pages/photos/photo-view/tagged-dogs-dialog/tagged-dogs-dialog.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    MediumImageCardComponent,
    ThumbnailIconCardComponent,
    ServiceCalendarComponent,
    CalendarDialogComponent,
    PhotoViewComponent,
    TaggedDogsDialogComponent,
    ServiceCalendarDialogComponent,
    MiniCalendarPickerSwapDaysDialogComponent,
    SwapDayDialogCardComponent,
    AddServiceDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FullCalendarModule,
    SwiperModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MediumImageCardComponent,
    ThumbnailIconCardComponent,
    ReactiveFormsModule,
    ServiceCalendarComponent,
    CalendarDialogComponent,
    FullCalendarModule,
    PhotoViewComponent,
    TaggedDogsDialogComponent,
    SwiperModule,
    SlickCarouselModule,
    ServiceCalendarDialogComponent,
    MiniCalendarPickerSwapDaysDialogComponent,
    SwapDayDialogCardComponent,
    AddServiceDialogComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
})
export class MainSharedModule {
  static forRoot(): ModuleWithProviders<MainSharedModule> {
    return {
      ngModule: MainSharedModule,
      providers: []
    };
  }
}
