<app-page-header
  hideDebitStatus="true"
  headerImage="{{ headerImage }}"
></app-page-header>
<div class="privacy-policy">
  <div class="privacy-policy_header">
    <h1 class="mat-display-1">Cookies</h1>
  </div>
  <div class="core-content">
    <p class="mat-body-1">
      This notice describes the use of Cookies on our website.
    </p>
    <h1 class="mat-display-1">
      What are cookies?
    </h1>
    <p class="mat-body-1">
      Cookies are alphanumeric identifiers that we transfer to your computer's
      hard drive through your web browser. They are widely used in order to make
      websites work, or to work more efficiently, as well as providing
      information to the owners of the site.
    </p>
    <h1 class="mat-display-1">
      The table below shows the cookies we use:
    </h1>
    <p class="mat-body-1 bold">
      Cookies in use on the site
    </p>

    <table mat-table [dataSource]="dataSource" class="width-percentage_100">
      <ng-container matColumnDef="cookie">
        <mat-header-cell *matHeaderCellDef class="u-text-size_15-override"
          >Cookie</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          class="gray-blue_override padding_10"
        >
          {{ element.cookie }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="u-text-size_15-override"
          >Name</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          class="gray-blue_override padding_10 "
        >
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="what_this_cookie_does">
        <mat-header-cell *matHeaderCellDef class="u-text-size_15-override"
          >What this cookie does</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="padding_10">
          <div
            [innerHTML]="element.cookieDescription"
            class="width-percentage_100 gray-blue"
          >
            {{ element.cookieDescription }}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <p class="mat-body-1">
      The majority of web browsers allow some control of cookies. To find out
      how to change cookie settings in your browser,
      <a href="http://civicuk.com/cookie-law/browser-settings">click here</a>.
    </p>
    <h1 class="mat-display-1">
      Youtube videos
    </h1>
    <p class="mat-body-1 bold">
      You will find a number of YouTube videos embedded on this website. Videos
      on our website are embedded using privacy-enhanced mode. This mode may set
      cookies on your computer if you start to play a video, but will not set
      cookies if you do not click on the video. For more information, view
      <a
        href="http://support.google.com/youtube/bin/answer.py?hl=en-GB&answer=171780"
        >Youtube's embedded video information page</a
      >.
    </p>
  </div>
</div>
