import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router, ActivatedRoute } from '@angular/router';

import { Password } from 'src/app/shared/interfaces/password.interface';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { PasswordFormCardComponent } from 'src/app/shared/components/card/password-form-card/password-form-card.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  password: Password;
  key: string;
  success: boolean;
  loading = false;
  headerImage: string;
  isDesktopLayout: boolean;
  private breakpointSub: Subscription;

  @ViewChild('passwordForm')
  passwordForm: PasswordFormCardComponent;

  constructor(
    private titleService: Title,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    breakpointObserver: BreakpointObserver
  ) {
    this.breakpointSub = breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .subscribe((result) => {
        if (result.matches) {
          this.headerImage =
            './../../../../../assets/images/backgrounds/mobile_bg.jpg';
          this.isDesktopLayout = false;
        } else {
          this.headerImage =
            './../../../../../assets/images/backgrounds/desktop_bg.jpg';
          this.isDesktopLayout = true;
        }
      });
  }

  ngOnInit() {
    this.setTitle('Bruce\'s | Password Reset');
    this.key = this.route.snapshot.queryParamMap.get('key');
  }

  onFormSubmit($event) {
    this.passwordForm.loading = true;
    if (this.authService.fromProfile.value) {
      this.authService
        .resetPasswordFromProfile({
          password: $event.value.password,
          email: this.authService.currentUser.email
        })
        .subscribe(
          (response) => {
            if (this.success) {
              this.passwordForm.loading = false;
              this.dialog.open(NotificationMessageComponent, {
                // tslint:disable-next-line: max-line-length
                data: {
                  heading: 'Password Reset Successful!',
                  body:
                    'Your password has been reset! You can now log in with your new password',
                  type: 'success',
                },
              });
              this.authService.resetUserTokensAndLogIn(response);
            }
          },
          (err) => {
            this.dialog.open(NotificationMessageComponent, {
              // tslint:disable-next-line: max-line-length
              data: {
                heading: 'Something went wrong our side!',
                body:
                  'Please contact Bruce\'s and ask for assistance',
                type: 'danger',
              },
            });
            this.router.navigate(['/profiles']);
          },
          () => this.router.navigate(['/profiles'])
        );
    } else {
      this.password = { password: $event.value.password, key: this.key, signUp: $event.value.signUp };
      this.authService.resetPassword(this.password).subscribe(
        (response) => {
          if (this.success) {
            this.passwordForm.loading = false;
            this.dialog.open(NotificationMessageComponent, {
              // tslint:disable-next-line: max-line-length
              data: {
                heading: 'Password Reset Successful!',
                body:
                  'Your password has been reset! You can now log in with your new password',
                type: 'success',
              },
            });
            this.router.navigate(['/login']);
          }
        },
        (err) => {
          this.dialog.open(NotificationMessageComponent, {
            // tslint:disable-next-line: max-line-length
            data: {
              heading: 'Something went wrong our side!',
              body:
                'Please contact Bruce\'s and ask for assistance',
              type: 'danger',
            },
          });
        }
      );
    }
  }

  state($event) {
    this.success = $event;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
