import { ErrorHandler, Injectable, Injector, ComponentRef, NgZone } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ErrorComponent, ERROR_INJECTOR_TOKEN } from 'src/app/core/error/error.component';
import { SanitizedError } from 'src/app/shared/interfaces/sanitized-error.interface';
import { isDevMode } from '@angular/core';

export const DEFAULT_OVERLAY_CONFIG: OverlayConfig = {
  hasBackdrop: true,
  backdropClass: 'error-backdrop'
};

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private overlay: Overlay;

  constructor(private injector: Injector) {
    this.overlay = this.injector.get(Overlay);
  }

  handleError(error: any): void {
    if (isDevMode()) {
      console.error(error);
    }

    const sanitised: SanitizedError = this.sanitiseError(error);
    const ngZone = this.injector.get(NgZone);
    const overlayRef = this.overlay.create(DEFAULT_OVERLAY_CONFIG);

    ngZone.run(() => {
      this.attachPortal(overlayRef, sanitised).subscribe(() => {
        overlayRef.dispose();
      });
    });
  }

  private sanitiseError(error: Error | HttpErrorResponse): SanitizedError {
    const sanitisedError: SanitizedError = {
      title: 'Error',
      message: ''
    };
    if (error instanceof Error) {
      if (error.stack.includes('Cannot match any routes')) {
        sanitisedError.message = 'We\'re sorry. The page you are looking for does not exist. Please try again.';
      } else if (error.stack.includes('undefined')) {
        sanitisedError.message = 'We\'re sorry. It seems something went wrong. Please try again.';
      } else {
        sanitisedError.message = 'We\'re sorry. It seems something went wrong. Please try again.';
      }
    } else if (error instanceof HttpErrorResponse) {
      if (error.status.toString().includes('404') || error.status.toString().includes('400')) {
        sanitisedError.message = 'We\'re sorry. The page you are looking for does not exist. Please try again.';
      } else if (error.status.toString().includes('401')) {
        sanitisedError.message = 'We\'re sorry. You are not authorized to access this page.';
      } else if (error.status.toString().includes('500')) {
        sanitisedError.message = 'We\'re sorry. It seems something went wrong. Please try again.';
      }
    } else {
      sanitisedError.message = 'We\'re sorry. It seems something went wrong. Please try again.';
    }
    return sanitisedError;
  }

  private attachPortal(overlayRef: OverlayRef, error: any): Observable<{}> {
    const ErrorHandlerPortal: ComponentPortal<ErrorComponent> = new ComponentPortal(
      ErrorComponent,
      null,
      this.createInjector(error)
    );
    const compRef: ComponentRef<ErrorComponent> = overlayRef.attach(ErrorHandlerPortal);
    return compRef.instance.dismiss$;
  }

  private createInjector(error: any): PortalInjector {
    const injectorTokens = new WeakMap<any, any>([
      [ERROR_INJECTOR_TOKEN, error]
    ]);

    return new PortalInjector(this.injector, injectorTokens);
  }
}
