<div class="bddc-calendar">

  <div class="app-loader" *ngIf="!hasData">
    <div class="u-flex-center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
  </div>

  <div class="bddc-calendar_container">
    <full-calendar #calendar [options]="calendarOptions">
    </full-calendar>
  </div>

  <div class="bddc-calendar_panel" *ngIf="dateTitle">
    <div class="bddc-calendar_panel-inner">
      <div class="bddc-calendar_panel-heading">
        <div class="bddc-calendar_add-service-mobile">
          <div class="calendar-divider"></div>
          <ng-container *ngIf="activeUser">
            <button mat-raised-button color="primary" (click)="addService()" [disabled]="!addServiceButtonEnabled">
              <mat-icon>add</mat-icon>
              Add service
            </button>
          </ng-container>
          <ng-container *ngIf="!activeUser">
            <button mat-raised-button color="primary" (click)="addService()" [disabled]="!activeUser">
              <mat-icon>add</mat-icon>
              Add service
            </button>
          </ng-container>

        </div>
        <div class="heading-wrapper">
          <h2 class="mat-display-3">
            <span class="u-mobile-only" *ngIf="displayOnlyForToday">Today </span>{{ dateTitle }}
          </h2>
        </div>
        <div class="calendar-divider"></div>
      </div>
      <div class="bddc-calendar_panel-content">
        <ng-container *ngIf="selectedDateServices.length === 0">
          <p class="mat-body-1 u-text-center">
            Sorry, no services for this day
          </p>
        </ng-container>
        <ng-container *ngIf="selectedDateServices">
          <ng-container *ngFor="let service of selectedDateServices">
            <div class="bddc-calendar-panel-item">
              <div class="bddc-calendar-panel-item_status {{
                  service.status ? service.status : 'yellow'
                }}"></div>
              <p class="mat-body-1 bddc-calendar-panel-item_title u-text-no-margin">
                <ng-container *ngIf="service.serviceCategory">{{ service.serviceCategory }}:
                </ng-container>
                <b class="margin-right_5">{{ service.serviceType }}</b>
                <ng-container *ngIf="service.dogName">
                  <span>
                    ({{ service.dogName }}){{ service['notAttending'] ? ' - ****' : ''}}{{service.swapDay &&
                    !service.oldSwap.swapDay && !dateHasPendingSwap ? ' - *****' :
                    ''}}<br>{{ service.oldSwap.swapDay ? ' Swapped to: ' + service.oldSwap.swappedTo : '' }}
                  </span>

                  <div *ngIf="service.pickupOwnersDrop == false && service.dropoffOwnersDrop == false"
                    class="route-icons">
                    <mat-icon matTooltip="Doggy Bus">
                      directions_bus_filled_rounded
                    </mat-icon>
                  </div>

                  <div *ngIf="service.pickupOwnersDrop == true && service.dropoffOwnersDrop == true"
                    class="route-icons">
                    <mat-icon matTooltip="Parent Drop">
                      person_rounded
                    </mat-icon>
                  </div>

                  <div *ngIf="service.pickupOwnersDrop == true && service.dropoffOwnersDrop == false"
                    class="route-icons">
                    <mat-icon matTooltip="Parent Drop">
                      person_rounded
                    </mat-icon>
                    /
                    <mat-icon matTooltip="Doggy Bus">
                      directions_bus_filled_rounded
                    </mat-icon>
                  </div>

                  <span>
                    <div *ngIf="service.pickupOwnersDrop == false && service.dropoffOwnersDrop == true"
                      class="route-icons">
                      <mat-icon matTooltip="Doggy Bus">
                        directions_bus_filled_rounded
                      </mat-icon>
                      /
                      <mat-icon matTooltip="Parent Drop">
                        person_rounded
                      </mat-icon>
                    </div>
                  </span>
                </ng-container>
                <ng-container *ngIf="service.pendingCancellation">
                  *</ng-container>
                <ng-container *ngIf="service.isRequest"> **</ng-container>
                <ng-container *ngIf="service.pendingSwap"> ***</ng-container>
              </p>

            </div>
            <div class="bddc-calendar-panel-item_actions">
              <ng-container *ngIf="activeUser">
                <div class="bddc-calendar-panel-item_action remove">
                  <button mat-button aria-label="Remove service" (click)="remove(service)" [disabled]="
                    !addServiceButtonEnabled || service.pendingCancellation || service.oldSwap.swapDay || (service.pendingSwap && service.id)
                    || service['notAttending']">
                    Cancel
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="activeUser">
                <div class="bddc-calendar-panel-item_action"
                  [ngClass]="{'swap' : !showGreySwapButton , 'swap-grey': showGreySwapButton}" *ngIf="
                  service.canSwap &&
                  !service.pendingSwap &&
                  !service.fiveDaysAWeek &&
                  !service.pendingCancellation &&
                  !service.oldSwap.swapDay
                ">
                  <button mat-button aria-label="Swap service" (click)="swapService(service)">
                    Swap
                  </button>
                </div>
              </ng-container>
            </div>
            <div class="calendar-divider"></div>
            <!-- </ng-container> -->
          </ng-container>
        </ng-container>
        <p class="mat-body-1 cancellation-legend" *ngIf="dateHasPendingCancellation">
          * Pending cancellation
        </p>
        <p class="mat-body-1 cancellation-legend" *ngIf="dateHasPendingRequest">
          ** Pending request
        </p>
        <p class="mat-body-1 cancellation-legend" *ngIf="dateHasPendingSwap">
          *** Pending swap day
        </p>
        <p class="mat-body-1 cancellation-legend" *ngIf="notAttendingLegendDisplay">
          **** Not attending
        </p>
        <p class="mat-body-1 cancellation-legend" *ngIf="notAttendingRefundedLegendDisplay">
          **** Not attending (Absent)
        </p>
        <p class="mat-body-1 cancellation-legend" *ngIf="dateHasSwapDay && !dateHasPendingSwap">
          ***** Swapped Day
        </p>
      </div>
      <div class="bddc-calendar_panel-footer">
        <div class="calendar-divider"></div>
        <ng-container *ngIf="activeUser">
          <button id="add-service-button" mat-raised-button color="primary" (click)="addService()"
            [disabled]="!addServiceButtonEnabled">
            <mat-icon>add</mat-icon>
            Add service
          </button>
        </ng-container>
        <ng-container *ngIf="!activeUser">
          <button mat-raised-button color="primary" (click)="addService()" [disabled]="!activeUser">
            <mat-icon>add</mat-icon>
            Add service
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>