import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable, of } from "rxjs";
import { RequestType } from "../enums/request-type.enum";
import { RequestModel } from "../models/request.model";
import { BaseApiService } from "./base-api.service";
import { RefreshService } from "./refresh.service";

@Injectable({ providedIn: 'root' })
export class RequestStoreService {
  private currentRequests: RequestModel[] = [];

  isRefreshing: boolean = false;

  constructor(
    private refreshService: RefreshService,
    private http: HttpClient,
    private baseApi: BaseApiService,
    ) {

  }

  addMultipleRequest(models: RequestModel[]): void {
    this.currentRequests.push(...models);
  }

  addRequest(model: RequestModel): void {
    this.currentRequests.push(model);
  }

  dispatch(): Observable<any[]> {
    let tempRequests: RequestModel[] = this.currentRequests;
    this.clearRequests();
    return new Observable((subscriber) => {
      let responses: any[] = [];
      let requests: Observable<any>[] = [];
      this.refreshService.checkCurrentToken(this).then((_: boolean)  => {
        tempRequests.forEach((model: RequestModel) => {
          requests.push(this.apiTypeCase(model));
        });
        forkJoin(requests).subscribe((res) => {
          responses = res;
          subscriber.next(responses);
        });
      });
    });
  }

  private apiTypeCase(model: RequestModel): Observable<any> {
    let apiUrl: string = this.baseApi.API_URL + model.url;
    switch (model.type) {
      case RequestType.GET:
        return this.http.get<any>(apiUrl, { params: model.params });
      case RequestType.POST:
        return this.http.post<any>(apiUrl, model.data, { params: model.params });
      case RequestType.DELETE:
        return this.http.delete<any>(apiUrl, { params: model.params });
      case RequestType.PUT:
        return this.http.put<any>(apiUrl, model.data, { params: model.params });
      default:
        return of();
    }
  }

  private clearRequests() {
    this.currentRequests = [];
  }
}
