<div class="app-loader" *ngIf="showSpinner">
  <div class="u-flex-center">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
</div>

<div
  class="photo-carousel_container"
  [ngClass]="images.length < 1 ? 'short' : ''"
>
  <div class="photo-carousel_header">
    <h2 class="mat-display-2 is-component">{{ heading }}</h2>
    <ng-container *ngIf="images.length > 1">
      <app-arrow-text-button>
        <div linkText (click)="navigateTo('recent-photos')">
          view all
        </div>
      </app-arrow-text-button>
    </ng-container>
  </div>
  <ng-container *ngIf="images.length < 1">
    <p class="mat-body-1">No photos yet.</p>
  </ng-container>
  <ng-container *ngIf="images.length >= 1">
    <div class="photo-carousel_slider">
      <div class="swiper-photo-carousel">
        <swiper
          class="swiper-photo-carousel_slider"
          [config]="config"
          (indexChange)="onIndexChange($event)"
          (click)="openPhotoPreviewModal($event)"
          (swiperTouchStart)="handleTouchStart($event)"
          (swiperTouchMoveOpposite)="swiperTouch($event)"
        >
          <ng-container *ngFor="let image of images; let i = index">
            <div class="slider-item slide-item-{{ i }}">
              <div
                class="image"
                [style.background-image]="'url(' + image.medium + ')'"
              >
                <div
                  class="photo-carousel_count"
                  (click)="navigateTo('recent-photos')"
                >
                  {{ i + 1 }} / {{ photoCount }}
                </div>
              </div>
            </div>
          </ng-container>
        </swiper>
        <div class="thumbnails">
          <ng-container *ngFor="let image of images; let i = index">
            <ng-container *ngIf="i < 5">
              <div
                class="thumbnail"
                [style.background-image]="'url(' + image.small + ')'"
              >
                <ng-container *ngIf="i === 4">
                  <span class="mat-body-1">+{{ images.length - i }}</span>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
