import Bugsnag, { Client } from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment } from 'src/environments/environment';

// configure Bugsnag asap
const client = Bugsnag.start({
  apiKey: environment.bug_snag_api_key,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: environment.release,
});

class ErrorHandler extends BugsnagErrorHandler {
  bugsnagClient: Client;
  bugsnagErrorHandler: BugsnagErrorHandler;

  constructor(bugsnagClient: Client) {
    super(bugsnagClient);
  }

  handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load new version?')) {
        window.location.reload();
      }
    } else {
      super.handleError(error);
    }
  }
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new ErrorHandler(client);
}