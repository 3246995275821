import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';

import { CustomerService } from 'src/app/core/services/customer.service';
import { checkIfHtmlExists } from 'src/app/core/validators/sanitize-validator';
import { fromEventPattern } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { SanitizeHelper } from 'src/app/core/helpers/sanitizer';

@Component({
  selector: 'app-message-us-dialog',
  templateUrl: './message-us-dialog.component.html',
  styleUrls: ['./message-us-dialog.component.scss']
})
export class MessageUsDialogComponent implements OnInit {
  @Output() sendMessage: EventEmitter<boolean> = new EventEmitter();
  titleClass: string;
  form: UntypedFormGroup;

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, overlayContainer: OverlayContainer, public breakpointObserver: BreakpointObserver, private customerService: CustomerService, private sanitizer:  DomSanitizer) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('message-us-dialog_overlay');
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });

    this.initForm();
  }

  setMobileLayout() {
    this.titleClass = 'mat-headline u-margin-bottom-15';
    if (window.innerWidth > 1129 && window.innerWidth < 1360) {
      this.dialogRef.updateSize('343px', '375px');
    } else {
      this.dialogRef.updateSize('343px', '375px');
    }
  }

  setDesktopLayout() {
    this.titleClass = 'mat-display-1';
    this.dialogRef.updateSize('400px', '363px');
  }

  initForm() {
    if (this.data.additionalInfo) {
      this.form = new UntypedFormGroup({
        customer_feedback: new UntypedFormControl('', [Validators.required, checkIfHtmlExists, Validators.maxLength(1500),]),
        bug_report: new UntypedFormControl(this.data.additionalInfo),
      });
    } else {
      this.form = new UntypedFormGroup({
        customer_feedback: new UntypedFormControl('', [Validators.required,  checkIfHtmlExists, Validators.maxLength(1500),]),
      });
    }
  }

  sendCustomerMessage(formData: any) {
    let sanitizeHelper: SanitizeHelper = new SanitizeHelper(this.sanitizer);
    if (this.data.additionalInfo) {
      if (this.form.valid) {
        this.form.controls.customer_feedback.setValue(sanitizeHelper.fullSanitize(this.form.controls.customer_feedback.value));
        this.customerService.sendCustomerFeedbackDebug(formData).subscribe(resp => { }, err => {
          console.error('SEND CUSTOMER FEEDBACK ERROR! ', err);
        });
        this.sendMessage.emit(true);
        this.dialogRef.close();
      } else {
        return;
      }
    } else {
      if (this.form.valid) {
        this.form.controls.customer_feedback.setValue(sanitizeHelper.fullSanitize(this.form.controls.customer_feedback.value));
        this.customerService.sendCustomerFeedback(formData).subscribe(resp => { }, err => {
          console.error('SEND CUSTOMER FEEDBACK ERROR! ', err);
        });
        this.sendMessage.emit(true);
        this.dialogRef.close();
      } else {
        return;
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
