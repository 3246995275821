import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SharedModule } from '../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordFormCardComponent } from '../shared/components/card/password-form-card/password-form-card.component';
import { CookiesPublicComponent } from './cookies-public/cookies-public.component';

@NgModule({
  declarations: [
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PasswordFormCardComponent,
    CookiesPublicComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
