import { UntypedFormControl } from '@angular/forms';

export function checkIfHtmlExists(control: UntypedFormControl) {
  if (control.value !== null) {
    let hasMatch: RegExpMatchArray = (control.value as String).match(/<[\/]*[a-z][\s\S]*[\/]*>/i);
    if (hasMatch) {
      return {
        htmlExists: 'This message contains HTML tags',
      };
    }
  }
  return null;
}
