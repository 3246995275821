import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { GooleAnalyticsEventsService } from '../services/goole-analytics-events.service';

import { RateUsDialogComponent } from './rate-us-dialog/rate-us-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // this is the better solution than rather trying to get the mat-icon registry working here
  facebookIcon = './../../../assets/images/icons/facebook_icon.png';
  twitterIcon = './../../../assets/images/icons/twitter_icon.png';
  instagramIcon = './../../../assets/images/icons/instagram_icon.png';
  tiktokIcon = './../../../assets/images/icons/tiktok_icon.png';
  sadIcon = './../../../assets/images/icons/sad_icon.png';
  mehIcon = './../../../assets/images/icons/meh_icon.png';
  smileIcon = './../../../assets/images/icons/smile_icon.png';

  // tslint:disable-next-line: max-line-length
  constructor(private googleAnalyticsEvents: GooleAnalyticsEventsService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  showRatingModal(rating: string) {
    if (rating === 'poor') {
      this.googleEvent('how do you feel about us', 'poor');
    } else if (rating === 'average') {
      this.googleEvent('how do you feel about us', 'average');
    } else if (rating === 'good') {
      this.googleEvent('how do you feel about us', 'good');
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      usersRating: rating
    };
    this.dialog.open(RateUsDialogComponent, dialogConfig);
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'footer - ' + section, 'footer', 10);
  }

}
