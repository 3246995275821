import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ServiceBooking } from 'src/app/shared/interfaces/service-booking.interface';
import { FlexiPass } from 'src/app/shared/interfaces/flexi-pass.interface';
import { SleepOver } from 'src/app/shared/interfaces/sleep-over.interface';
import { DayCare } from 'src/app/shared/interfaces/day-care.interface';
import { Dogs } from 'src/app/shared/interfaces/dogs.interface';


@Injectable({
  providedIn: 'root'
})

export class BookingService {

  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  // get dogs
  getDogs(): Observable<Dogs[]> {
    const apiUrl = '/customer_object/get_dogs';
    const service = this.http.get<Dogs[]>(this.baseApiService.API_URL + apiUrl, {});

    return service;
  }

  getDogsWithService(serviceId) {
    const apiUrl = '/services/get_dogs_dynamic_service';
    return this.http.post(this.baseApiService.API_URL + apiUrl, serviceId)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  // get all dynamic services for Carousel
  getAllServices(): Observable<ServiceBooking[]> {
    const apiUrl = '/services/get_all_dynamic_services_update';
    const services = this.http.get<ServiceBooking[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }

  // get all dynamic services for carousel on home page
  getAllServicesForHomePage(): Observable<ServiceBooking[]> {
    const apiUrl = '/services/get_all_dynamic_services_home_page_update';
    const services = this.http.get<ServiceBooking[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }

  // get all dynamic services for category
  getServicesForCategory(categoryId): Observable<ServiceBooking[]> {
    const params = new HttpParams().set('category_id', categoryId);
    const apiUrl = '/services/get_dynamic_services_for_category_update';
    const services = this.http.get<ServiceBooking[]>(this.baseApiService.API_URL + apiUrl, { params });

    return services;
  }

  // get "Book again" services for carousel on home page
  getBookAgainServices(): Observable<ServiceBooking[]> {
    const apiUrl = '/services/get_book_again_services';
    const services = this.http.get<ServiceBooking[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }

  // Flexipass Services
  getFlexiDogs(): Observable<FlexiPass[]> {
    const apiUrl = '/dogs/get_flexi_pass_dogs';
    const services = this.http.get<FlexiPass[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }
  addFlexiPass(services: {}) {
    // tslint:disable-next-line: max-line-length
    const apiUrl = '/services/add_flexi_pass_service';
    return this.http.post(this.baseApiService.API_URL + apiUrl, services)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  // Day Care Services
  getDayCareServices(): Observable<DayCare[]> {
    const apiUrl = '/services/get_day_care_services';
    const services = this.http.get<DayCare[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }

  addDayCare(service: {}) {
    // tslint:disable-next-line: max-line-length
    const apiUrl = '/services/add_day_care_service';
    return this.http.post(this.baseApiService.API_URL + apiUrl, service)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  dayCareServiceDateCheck(dataObject) {
    const apiUrl = '/services/day_care_service_date_check';
    return this.http.post(this.baseApiService.API_URL + apiUrl, dataObject)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  bankedDaysCheck(dataObject) {
    const apiUrl = '/dogs/get_all_dog_banked_days_for_day';
    let params = new HttpParams();
    params = params.append('dog_ids', dataObject.dogs.filter(dog => dog.checked).map(dog => dog.id));
    params = params.append('date', dataObject.date);
    params = params.append('service_id', dataObject.adhocServiceId);

    return this.http.get(this.baseApiService.API_URL + apiUrl, { params: params })
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  // Sleep Over Services(Boarding Service)
  getSleepOverServices(): Observable<SleepOver[]> {
    const apiUrl = '/services/get_sleep_over_services';
    const services = this.http.get<SleepOver[]>(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }
  // Get Sleep Over Information(Only the information)
  getSleepOverInformation() {
    const apiUrl = '/services/get_sleep_over_information';
    const services = this.http.get(this.baseApiService.API_URL + apiUrl, {});

    return services;
  }

  updateSleepOverInformation(service) {
    const params = new HttpParams().set('sleepover', service);
    const apiUrl = '/services/update_sleep_over_information';
    const serviceUpdate = this.http.post(this.baseApiService.API_URL + apiUrl, service);
    return serviceUpdate;
  }

  addSleepOver(service) {
    // tslint:disable-next-line: max-line-length
    const apiUrl = '/services/add_sleep_over_service';
    return this.http.post(this.baseApiService.API_URL + apiUrl, service)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }
  boardingServiceDateCheck(dataObject) {
    const apiUrl = '/services/sleep_over_service_date_check';
    return this.http.post(this.baseApiService.API_URL + apiUrl, dataObject)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  // Dynamic Services
  addDynamicService(service: {}) {
    // tslint:disable-next-line: max-line-length
    const apiUrl = '/services/add_dynamic_service';
    return this.http.post(this.baseApiService.API_URL + apiUrl, service)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  dynamicServiceDateCheck(dataObject) {
    const apiUrl = '/services/dynamic_service_date_check';
    const service = this.http.post(this.baseApiService.API_URL + apiUrl, dataObject);

    return service;
  }
}
