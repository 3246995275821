<div class="footer-container">
  <div class="footer-main">
    <div class="footer-main_interactions">
      <div class="interactions_feedback">
        <p class="mat-body-1 u-text-no-margin how-do-you-feel">How do you feel about us?</p>
        <div class="interactions_feedback-icons">
          <img src="{{ sadIcon }}"
            (click)="showRatingModal('poor')"
            class="footer-icons" />
          <img src="{{ mehIcon }}"
            (click)="showRatingModal('average')"
            class="footer-icons" />
          <img src="{{ smileIcon }}"
            (click)="showRatingModal('good')"
            class="footer-icons smile" />
        </div>
      </div>

      <hr class="hr-styling" />

      <div class="footer-main_reach-out">
        <button mat-raised-button
          class="mat-footer"
          routerLink="/contact"
          (click)="googleEvent('footer','contact us')">
          Contact Us
        </button>

        <div class="footer-main_reach-out_socials">
          <a href="https://www.facebook.com/brucesdoggydaycare"
            (click)="googleEvent('social media','facebook')"
            target="_blank"
            title="Facebook"
            alt="Link to Facebook page"><img src="{{ facebookIcon }}"
              class="footer-icons" /></a>

          <a href="https://twitter.com/brucesdogdays"
            (click)="googleEvent('social media','twitter')"
            target="_blank"
            title="Twitter"
            alt="Link to Twitter account"><img src="{{ twitterIcon }}"
              class="footer-icons" /></a>

          <a href="https://www.instagram.com/brucesdoggydaycare/"
            (click)="googleEvent('social media','instagram')"
            target="_blank"
            title="Instagram"
            alt="Link to Instagram account"><img src="{{ instagramIcon }}"
              class="footer-icons" /></a>

          <a href="https://www.tiktok.com/@brucesdoggydaycare" (click)="googleEvent('social media','tiktok')"
            target="_blank" title="Instagram" alt="Link to TikTok account"><img src="{{ tiktokIcon }}"
              class="footer-icons" /></a>
        </div>
      </div>
    </div>

    <div class="footer-policies">
      <div class="footer-policies_links">
        <a mat-buttonLink
          class="mat-body-2 small-print"
          (click)="googleEvent('footer','terms & conditions')"
          routerLink="/terms">Terms & conditions</a>
        <span class="mat-body-1 link-divider white"> | </span>
        <a mat-buttonLink
          class="mat-body-2 small-print"
          (click)="googleEvent('footer','privacy')"
          routerLink="/privacy">Privacy</a>
        <span class="mat-body-1 link-divider white"> | </span>
        <a mat-buttonLink
          class="mat-body-2 small-print"
          (click)="googleEvent('footer','cookies')"
          routerLink="/cookies">Cookies</a>
      </div>

      <p class="mat-body-1 small-print">
        Bruce’s Pet Care Ltd, All rights reserved
      </p>
    </div>
  </div>
</div>