import { Component, Inject, OnDestroy, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerService } from 'src/app/core/services/customer.service';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Router } from '@angular/router';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent implements OnDestroy, AfterViewInit {

  facebookUrl = 'https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Fbrucesdoggydaycare%2Freviews%2F';

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public overlayContainer: OverlayContainer,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private customerService: CustomerService,
    private focusMonitor: FocusMonitor,
    private router: Router,
    private el: ElementRef,
    private googleAnalyticsEvents: GooleAnalyticsEventsService) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('review-dialog_overlay');

    this.matIconRegistry.addSvgIcon(
      'facebook_icon_2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/facebook_icon_2.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'google_icon_2',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/google_icon_2.svg')
    );
  }

  ngAfterViewInit() {
    const socialButtons = this.el.nativeElement.getElementsByClassName('mat-button-focus-overlay');
    for (let i = 0; 0 < socialButtons.length; i++) {
      socialButtons[0].parentElement.removeChild(socialButtons[0]);
    }
    this.focusMonitor.stopMonitoring(document.getElementById('remove-cdk'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateReview(customerId, type: string) {
    this.dialogRef.close();
    this.googleEvent(type);
    this.customerService.updateReview(customerId).subscribe((result) => {
      if (customerId) {
        this.router.navigate(['/login']);
      }
    }, err => {
      console.error('REVIEW NOT UPDATED: ', err);
    });
  }

  closeDialog(customerId, type: string) {
    this.googleEvent(type);
    this.dialogRef.close();
    this.customerService.updateRemindMeLater(customerId).subscribe((result) => {
      if (customerId) {
        this.router.navigate(['/login']);
      }
    }, err => {
      console.error('REVIEW DATE NOT SAVE: ', err);
    });
  }

  googleEvent(type: string) {
    this
      .googleAnalyticsEvents
      .eventEmitter('reviews', `${type} - review`, `${type} reviews`, 10);
  }


  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('review-dialog_overlay');
  }
}
