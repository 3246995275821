import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/core/helpers/auth-interceptor';

import { HomeComponent } from 'src/app/modules/main/pages/home/home.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderModule } from 'src/app/core/header/header.module';
import { FooterModule } from 'src/app/core/footer/footer.module';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';
import { PhotoCarouselComponent } from 'src/app/shared/components/carousel/photo-carousel/photo-carousel.component';
import { MainSharedModule } from 'src/app/shared/main-shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { TaggedDogDialogComponent } from 'src/app/shared/components/carousel/tagged-dog-dialog/tagged-dog-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MainSharedModule,
    HomeRoutingModule,
    HeaderModule,
    FooterModule,
    NgxGalleryModule
  ],
  exports: [
    NgxGalleryModule
  ],
  declarations: [
    HomeComponent,
    ReviewDialogComponent,
    PhotoCarouselComponent,
    TaggedDogDialogComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }]
})

export class HomeModule { }
