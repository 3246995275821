<div class="banner-dialog">
  <div class="banner-dialog-title">
    <h1 mat-dialog-title>Notice</h1>
  </div>
  <mat-dialog-content [innerHtml]="data.body.message"></mat-dialog-content>
  <mat-dialog-actions>
    <button *ngIf="data.body.vax" mat-raised-button mat-dialog-close (click)="onUpdate()" class="close-btn">Upload
      now</button>
    <button mat-raised-button mat-dialog-close class="close-btn">Close</button>
  </mat-dialog-actions>
</div>