import { HttpParams } from "@angular/common/http";
import { RequestType } from "../enums/request-type.enum";

export class RequestModel  {
  url: String;
  data?: Object;
  type: RequestType;
  params?: HttpParams;

  constructor(url: String, type: RequestType, data?: any, params?: HttpParams) {
    this.url = url;
    this.type = type;
    this.data = data;
    this.params = params;
  }
}
