import { Component, Input } from '@angular/core';
import { Milestone } from 'src/app/shared/models/milestone';
import { Router } from '@angular/router';

@Component({
  selector: 'app-milestone-banner',
  templateUrl: './milestone-banner.component.html',
  styleUrls: ['./milestone-banner.component.scss']
})
export class MilestoneBannerComponent {
  @Input() milestones: Milestone[];
  // tslint:disable-next-line: variable-name ban-types
  @Input() completed_milestones: Number[];
  @Input() page?: string;
  @Input() dogId?: any;
  slideCount: any;
  sliderEl: any;
  tempMilestones: Milestone[]
  extraMilestones: any;
  showMilestoneOverlay: boolean = false;

  constructor(private router: Router) {
  }
  calcNumberOfMilestones() {
    this.tempMilestones = this.milestones
    if (this.tempMilestones.length > 5) {
      this.showMilestoneOverlay = true;
      this.milestones = this.milestones.slice(0, 5);
      this.extraMilestones = this.tempMilestones.length - this.milestones.length
    }
    return this.milestones
  }

  showMilestone(id) {
    if (this.page === 'milestones') {
      document.getElementById('milestone_' + id).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    } else {
      this.router.navigate(['/milestones'], {
        // tslint:disable-next-line: radix
        queryParams: { dogId: parseInt(this.dogId), milestoneId: parseInt(id) }
      });
    }
  }
}
