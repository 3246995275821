import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/app/core/services/auth.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-service-dialog',
  templateUrl: './add-service-dialog.component.html',
  styleUrls: ['./add-service-dialog.component.scss']
})
export class AddServiceDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private dataSubscription: Subscription;
  paramsDate: string;
  categories: any;
  hasData: boolean;
  userActive: boolean;
  xDown: number;
  yDown: number;
  configQuickBooking: SwiperConfigInterface;


  thumbnailCards = [
    {
      cardImage: './../../../../assets/images/backgrounds/components/daycare.jpg',
      cardText: 'Day care'
    },
    {
      cardImage: './../../../../assets/images/backgrounds/profile/sleepover_desktop.jpg',
      cardText: 'Sleepover'
    }
  ];

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private router: Router,
    private route: ActivatedRoute,
    private overlayContainer: OverlayContainer,
    public breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    overlayContainer.getContainerElement().classList.add('add-service-dialog_overlay');
    this.matIconRegistry.addSvgIcon(
      'close_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/close_icon.svg')
    );
    this.hasData = false;
    this.userActive = localStorage.getItem('active') === 'true';
  }

  ngOnInit() {
    this.paramsDate = this.data.date;

    this.subscription = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });

    this.dialogRef.addPanelClass('service-dialog-container');

    this.configQuickBooking = {
      a11y: true,
      direction: 'horizontal',
      keyboard: false,
      mousewheel: false,
      scrollbar: false,
      pagination: false,
      initialSlide: 0,
      observer: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: true,
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 5,
      slideActiveClass: 'none',

      navigation: {
        nextEl: '.quick-booking-next',
        prevEl: '.quick-booking-prev'
      },

      // Responsive breakpoints
      breakpoints: {
        414: {
          slidesPerView: 'auto',
          spaceBetween: 1
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 1
        },
        // when window width is <= 1300px
        1280: {
          slidesPerView: 'auto',
          spaceBetween: 10
        }
      }
    };

    this.dataSubscription = this.accountService.getDirectDebitStatus().subscribe((result) => {
      const response = result['response'];
      this.categories = response['categories'];
      this.thumbnailCards = [];

      if (this.categories) {
        this.thumbnailCards = [
          {
            cardImage: './../../../../assets/images/backgrounds/components/daycare.jpg',
            cardText: 'Day care'
          },
          {
            cardImage: './../../../../assets/images/backgrounds/profile/sleepover_desktop.jpg',
            cardText: 'Sleepover'
          }
        ];

        this.categories.forEach(category => {
          let tempObj = {
            cardImage:
              category['image'] !== null
                ? category['image']
                : './../../assets/images/backgrounds/components/grooming.jpg',
            cardText: category['name']
          };

          this.thumbnailCards.push(tempObj);
        });
      } else {
        this.thumbnailCards = [
          {
            cardImage: './../../../../assets/images/backgrounds/components/daycare.jpg',
            cardText: 'Day care'
          },
          {
            cardImage: './../../../../assets/images/backgrounds/profile/sleepover_desktop.jpg',
            cardText: 'Sleepover'
          }
        ];
      }
      this.hasData = true;
    }, err => {
      console.error('GET DIRECT DEBIT STATUS FETCH ERROR! ', err);
    });
  }

  setMobileLayout() {
    this.dialogRef.updateSize('343px', '285px');
  }

  setDesktopLayout() {
    this.dialogRef.updateSize('977px', '330px');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  viewService(sliderIndex, analyticsSection, analyticsTitle) {
    let dynamicCategory = this.categories.find(category => category['name'] === sliderIndex);

    if (this.userActive) {
      if (sliderIndex === 'Day care') {
        this.router.navigate(['/services/booking'], { queryParams: { serviceIndex: 0, date: this.paramsDate }, relativeTo: this.route });
      } else if (sliderIndex === 'Sleepover') {
        this.router.navigate(['/services/booking'], { queryParams: { serviceIndex: 1, date: this.paramsDate }, relativeTo: this.route });
      } else if (dynamicCategory) {
        // tslint:disable-next-line: max-line-length
        this.router.navigate(['/services/service-category'], { queryParams: { categoryId: dynamicCategory['id'], date: this.paramsDate }, relativeTo: this.route });
      } else {
        this.router.navigate(['/services/booking'], { queryParams: { serviceIndex: sliderIndex }, relativeTo: this.route });
      }
    }

    this.closeDialog();
    this.googleEvent(analyticsSection, analyticsTitle);
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'add service - ' + section, 'add service', 10);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.overlayContainer.getContainerElement().classList.remove('add-service-dialog_overlay');
  }


  handleTouchStart(event) {
    const firstTouch = this.getTouches(event);

    if (firstTouch) {
      this.xDown = firstTouch[0].clientX;
      this.yDown = firstTouch[0].clientY;
    }
  }

  getTouches(event) {
    return event.touches;
  }

  swiperTouch(event) {
    if (event.touches) {
      const xUp = event.touches[0].clientX;
      const yUp = event.touches[0].clientY;

      const xDiff = this.xDown - xUp;
      const yDiff = this.yDown - yUp;

      const positionX = event.pageX;
      const positionY = event.pageY - event.offsetY;
      const pageElement = event.offsetParent;

      if (yDiff > 0) {
        // window.scrollBy(0, 10);
        window.scrollBy({
          top: 15,
          left: 0,
          behavior: 'smooth'
        });
      } else {
        // window.scrollBy(0, -10);
        window.scrollBy({
          top: -15,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

}
