<ng-container *ngIf="!hideDebitStatus">
  <ng-container *ngIf="!directDebitEnabled">
    <div class="notification-bar warn" (click)="navigateTo('direct-debit')">
      <mat-icon svgIcon="exclamation_icon" class="exclamation-icon"></mat-icon>
      <span class="mat-body-1 red-text">Direct debit is not enabled! </span>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="showNotifications">
  <div *ngFor="let notification of notifications" class="notification-bar info" (click)="openDialog(notification)">
    <mat-icon>remove_red_eye</mat-icon>
    <span *ngIf="!notification.vax" class="mat-body-1 banner-title"
      [innerHTML]="notification.message.length < characters ? notification.message : notification.message.substring(0, characters) + '... '">
    </span>
    <span *ngIf="notification.vax" class="mat-body-1"
      [innerHTML]="notification.title.length < characters ? notification.title : notification.title.substring(0,characters) + '... '"></span>
  </div>
</ng-container>

<header *ngIf="headerImage" class="page-header {{ extraClass }}" [style.background-image]="'url(' + headerImage + ')'">
  <ng-container *ngIf="imageSrc">
    <ng-container *ngIf="imageLink">
      <a href="{{ imageLink }}">
        <img img src="{{ imageSrc }}" alt="{{ imageAlt }}" />
      </a>
    </ng-container>
    <ng-container *ngIf="!imageLink">
      <img img src="{{ imageSrc }}" alt="{{ imageAlt }}" />
    </ng-container>
  </ng-container>
  <div class="page-header_curve"></div>
</header>