<div class="milestones-banner {{
    slideCount ? 'slides-' + slideCount + ' wrapped' : ''
  }}" [ngClass]="page && milestones.length > 4 ? page : ''">
  <ng-container *ngIf="page === 'profiles-milestones'">
    <div *ngFor="let milestone of calcNumberOfMilestones(); index as i" class="milestone-container"
      title="{{ milestone['customer_milestone_description'] }}">
      <img [class.image-locked]="!completed_milestones.includes(milestone.id)" [src]="milestone['badge_image']"
        (click)="showMilestone(i)" />
      <p class="mat-body-1" color="mat-primary">
        {{ milestone["internal_milestone_name"] }}
      </p>
      <p (click)="showMilestone(i)" *ngIf="i == 4 && showMilestoneOverlay" class="extra-text-profile">
        +{{extraMilestones}}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="!page || page !== 'profiles-milestones'">
    <div *ngFor="let milestone of calcNumberOfMilestones(); index as i" class="milestone-container">
      <img [class.image-locked]="!completed_milestones.includes(milestone.id)" [src]="milestone['badge_image']"
        title="{{ milestone['customer_milestone_description'] }}" (click)="showMilestone(i)" />
      <p (click)="showMilestone(i)" *ngIf="i == 4 && showMilestoneOverlay" class="u-text-color_white extra-text">
        +{{extraMilestones}}
      </p>
    </div>
  </ng-container>
</div>