<ng-container *ngIf="isMatButton">
  <a mat-button class="arrow-text-button mat-button-link centered" (click)="linkPath">
    <div class="arrow-text-button_text">
      <ng-content select="[matButtonText]"></ng-content>
    </div>
    <mat-icon class="arrow-text-button_icon is-white">chevron_right</mat-icon>
  </a>
</ng-container>

<ng-container *ngIf="!isMatButton">
  <a class="arrow-text-button" (click)="linkPath">
    <ng-content select="[linkText]"></ng-content>
    <mat-icon class="arrow-text-button_icon">chevron_right</mat-icon>
  </a>
</ng-container>