import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { timer, Subscription } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-session-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.scss']
})
export class SessionDialogComponent implements OnInit {
  @Output() userAction: EventEmitter<string> = new EventEmitter();
  hasUserAction = false;
  action = '';
  counter = 30;
  private subscription: Subscription;
  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public overlayContainer: OverlayContainer, public breakpointObserver: BreakpointObserver) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('session-dialog_overlay');
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });

    this.subscription = timer(1000, 1000)
      .pipe(
        takeWhile(() => this.counter > 0),
        tap(() => this.counter--)
      )
      .subscribe(() => {
        if (this.action !== '' && this.counter > 0) {
          this.actionByUser(this.action);
        } else if (this.counter === 0) {
          this.actionByUser('logout');
        }
        console.log(this.counter);
      });
  }
  setMobileLayout() {
    this.dialogRef.updateSize('343px', '335px');
  }

  setDesktopLayout() {
    this.dialogRef.updateSize('377px', '325px');
  }

  closeDialog() {
    this.overlayContainer.getContainerElement().classList.remove('session-dialog_overlay');
    this.dialogRef.close();
  }

  actionByUser(action: string) {
    this.action = action;
    this.subscription.unsubscribe();
    this.userAction.emit(action);
    this.closeDialog();
  }

}
