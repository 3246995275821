import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/core/services/account.service';
import { AccountBalance } from 'src/app/shared/interfaces/account-balance.interface';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-balance-card',
  templateUrl: './account-balance-card.component.html',
  styleUrls: ['./account-balance-card.component.scss']
})
export class AccountBalanceCardComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  accountDetails: AccountBalance;
  cardStatus: string;
  hasData = false;
  directDebitLink = '';
  fetchingDirectDebitLink = false;
  userActive: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(
    private router: Router,
    private accountService: AccountService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private authService: AuthService) {
    this.userActive = localStorage.getItem('active') === 'true';
    this.matIconRegistry.addSvgIcon(
      'chevron_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + 'assets/images/icons/chevron_right.svg')
    );
  }

  ngOnInit() {
    this.fetchAccountData();
  }

  fetchAccountData() {
    this.subscription = this.accountService.getAccountBalance().subscribe(resp => {

      this.accountDetails = resp['accountDetails'];
      this.directDebitLink = this.accountDetails['directDebitLink'];

      if (this.accountDetails.accountStatus) {
        if (this.accountDetails.accountStatus === 'Balance due') {
          this.cardStatus = '';
        } else if (this.accountDetails.accountStatus === 'Balance overdue') {
          this.cardStatus = 'warn';
        }
      }
      this.hasData = true;
    }, err => {
      console.error('GET ACCOUNT BALANCE FETCH ERROR! ', err);
    });
  }

  navigateTo(path: string) {
    if (path === 'billing') {
      this.router.navigate(['/billing']);
      this.googleEvent('current balance', 'billing');
    } else if (path === 'direct-debit') {
      alert('Direct debit page still needs to be built!');
      this.googleEvent('current balance', 'enable direct debit');
    }
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'home - ' + section, 'home', 10);
  }

  setupDirectDebit() {
    if (this.directDebitLink) {
      this.fetchingDirectDebitLink = true;
      window.open(this.directDebitLink, '_self');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
