<div class="content-wrapper u-space-v_30">
  <app-dog-summary-card
    [dogId]="dogId"
    page="milestones"
  ></app-dog-summary-card>
</div>

<div class="content-wrapper milestone-stepper_wrapper u-space-v_30">
  <h2 class="mat-display-2">Milestones</h2>
  <ng-container *ngIf="!hasData">
    <div class="u-flex-center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="hasData">
    <mat-accordion [multi]="true" class="according-stepper">
      <ng-container *ngFor="let milestone of milestones; index as i">
        <div
          id="milestone_{{ milestone.id }}"
          class="milestone-stepper_step {{
            milestone.active ? '' : 'disabled'
          }}"
        >
          <div class="milestone-stepper_step-icon">
            <img src="{{ milestone.icon }}" alt="{{ milestone.title }}" />
          </div>
          <mat-expansion-panel
            [expanded]="true"
            (opened)="setStep(milestone.id)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span
                  class="mat-body-1"
                  [ngClass]="{ incomplete: !milestone.active }"
                  >{{ milestone.title }}</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            {{ milestone.description }}
          </mat-expansion-panel>
        </div>
      </ng-container>
    </mat-accordion>
  </ng-container>
</div>
