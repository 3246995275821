import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-swap-day-cut-off-dialog',
  templateUrl: './swap-day-cut-off-dialog.component.html',
  styleUrls: ['./swap-day-cut-off-dialog.component.scss']
})
export class SwapDayCutOffDialogComponent {
  @Output() confirm: EventEmitter<boolean> = new EventEmitter();
  private subscription: Subscription;
  titleClass: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public overlayContainer: OverlayContainer,
    public breakpointObserver: BreakpointObserver
  ) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('swap-day-dialog_overlay');
  }

  ngOnInit(): void {
    this.subscription = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
  }

  cancelService() {
    this.dialogRef.close();
    this.confirm.emit(true);
  }

  closeDialog() {
    this.dialogRef.close();
    this.confirm.emit(false);
  }

  setMobileLayout() {
    this.titleClass = 'mat-headline u-margin-bottom-15';
    this.dialogRef.updateSize('343px', '288px');
  }

  setDesktopLayout() {
    this.titleClass = 'mat-display-1';
    this.dialogRef.updateSize('377px');
  }

  ngOnDestroy() {
    this.overlayContainer.getContainerElement().classList.remove('swap-day-dialog_overlay');
    this.subscription.unsubscribe();
  }
}
