import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgForm, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { BrowserHelper } from 'src/app/core/helpers/browser-helper';
import { checkIfHtmlExists } from '../validators/sanitize-validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private breakpointSub: Subscription;
  logoImage = './../../../../../assets/images/bddc-bouncer-logo.png';
  headerImage: string;
  isDesktopLayout: boolean;
  year: number;
  loading = false;
  form: UntypedFormGroup;
  hasError: boolean = false;
  forgotPasswordValidationMessage: String = '';
  forgotErrorMessage: string;
  // tslint:disable-next-line: max-line-length
  constructor(private titleService: Title, private dialog: MatDialog, private authService: AuthService, private router: Router, breakpointObserver: BreakpointObserver, private title: Title, private browserHelper: BrowserHelper) {
    this.breakpointSub = breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/mobile_bg.jpg';
        this.isDesktopLayout = false;
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/desktop_bg.jpg';
        this.isDesktopLayout = true;
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('Bruce\'s | Forgot Password');
    this.year = new Date().getFullYear();
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, checkIfHtmlExists, Validators.maxLength(254)])
    });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  onBlurMethod() {
    if (this.form.controls.email.value) {
      this.form.controls.email.setValue(this.form.controls.email.value.replace(/\s+/g, ''));
      this.form.controls.email.setValue(this.form.controls.email.value.replace('\s*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})\s*', ''));
    }

    let errorPresent = false;
    this.hasError = false;
    if (this.form.controls.email.dirty) {
      if (!this.form.controls.email.value) {
        errorPresent = true;
      }

      // "i" modifier passed to make expression case insensitive
      const validationExpression = new RegExp('\s*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})\s*', 'i');

      if (!this.form.controls.email.value.match(validationExpression)) {
        errorPresent = true;
      }
    }

    if (errorPresent) {
      this.hasError = true;
      this.forgotPasswordValidationMessage = 'The email that you entered is invalid';
      this.form.controls.email.setErrors({ valid: false });
    } else {
      this.hasError = false;
    }

    if (this.form.controls.email.value.trim() == '') {
      this.hasError = true;
      this.forgotPasswordValidationMessage = 'Email cannot be empty';
    }
  }
  
  onSubmit(form) {
    this.hasError = false;
    const trackData: any = this.browserHelper.getBrowserInfo()
    const validationExpression = new RegExp('\s*([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4})\s*', 'i');
    if (!this.form.controls.email.value.match(validationExpression)) {
      this.hasError = true;
      this.forgotPasswordValidationMessage = 'The email that you entered is invalid';
    }

    if (this.form.controls.email.value.trim() == '') {
      this.hasError = true;
      this.forgotPasswordValidationMessage = 'Email cannot be empty';
    }

    if (!this.hasError) {
      this.authService.trackPasswordAttempt(form.value.email, trackData).subscribe(response => {

        this.loading = true;
        this.authService.forgotPassword(form.value.email).subscribe(
          response => {
            this.loading = false;
            this.dialog.open(NotificationMessageComponent, {
              data: {
                heading: 'Successful!',
                body: response['message'],
                type: 'success'
              }
            });
            this.router.navigate(['/login']);
          },
          err => {
            this.dialog.open(NotificationMessageComponent, {
              // tslint:disable-next-line: max-line-length
              data: {
                heading: 'Something went wrong our side!',
                body:
                  'Please contact Bruce\'s and ask for assistance',
                type: 'danger'
              }
            });
          }
        );
      });
    }

  }

  goToCookies(): void {
    this.router.navigate(['cookies-public']);
  }

  ngOnDestroy() {
    this.breakpointSub.unsubscribe();
  }
}
