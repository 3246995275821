import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';
import { DogSummary } from 'src/app/shared/models/dog-summary';
import { DogSummaryApiObject } from 'src/app/shared/models/dog-summary';
import { BankedDay } from 'src/app/modules/main/pages/services/banked-days/banked-days.component';

@Injectable({
  providedIn: 'root'
})

export class DogService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  // HttpClient API get() method => Fetch dogs object with milestones
  getDogsSummary(): Observable<DogSummary[]> {
    const apiUrl = '/dogs/get_dogs_summary';
    const dog = this.http.get<DogSummary[]>(this.baseApiService.API_URL + apiUrl, {});

    return dog;
  }

  // HttpClient API get() method => Fetch dog object with milestones
  getDogSummary(dogId): Observable<DogSummary[]> {
    const params = new HttpParams().set('dog_id', dogId);

    const apiUrl = '/dogs/get_dog_summary';
    const dog = this.http.get<DogSummary[]>(this.baseApiService.API_URL + apiUrl, { params });

    return dog;
  }

  // HttpClient API get() method => Fetch dogs object with banked days
  getRegularSummaries(): Observable<DogSummary[]> {
    const apiUrl = '/dogs/get_regular_and_banked_days';
    const regularSummary = this.http.get<DogSummary[]>(this.baseApiService.API_URL + apiUrl, {});

    return regularSummary;
  }

  getAllBankedDays(): Observable<any> {
    const apiUrl = '/dogs/get_banked_days';
    const bankedDaySummary = this.http.get<BankedDay[]>(this.baseApiService.API_URL + apiUrl, {});

    return bankedDaySummary;
  }

  // HttpClient API post() method => Update dog profile image
  updateProfilePicture(formData): Observable<DogSummaryApiObject> {
    const apiUrl = '/dogs/update_profile_picture';
    const result = this.http.post<DogSummaryApiObject>(this.baseApiService.API_URL + apiUrl, formData);

    return result;
  }
}
