import { Component, Input, AfterViewInit } from '@angular/core';
import { ServicesRoutingModule } from 'src/app/modules/main/pages/services/services-routing.module';

@Component({
  selector: 'app-medium-image-card',
  templateUrl: './medium-image-card.component.html',
  styleUrls: ['./medium-image-card.component.scss']
})
export class MediumImageCardComponent {
  @Input() hasOverlay: boolean;
  @Input() hasNotification: boolean;
  @Input() overlayText: string;
  @Input() cardNotification: string;
  @Input() layout: string;
  @Input() bannerImage: string;
  @Input() cardText: string;
  @Input() cardPrice: string;
  @Input() cardId: string;

  constructor() { }

  textLengthCheck(cardText) {
    return (cardText.length <= 16 && window.innerWidth < 375) || (cardText.length <= 17 && window.innerWidth >= 375);
  }

  manipulateText(target: HTMLElement) {
    let titles = document.getElementsByClassName("title");
    let cards = document.getElementsByClassName("medium-image-card");

    for (let i = 0; i < titles.length; i++) {
      if (titles[i].id !== target.id) {
        titles[i].classList.remove('expanded-title');
      }
    }
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].id !== target.parentElement.parentElement.parentElement.id) {
        cards[i].classList.remove('expanded-card');
      }
    }

    if (target.classList.contains('expanded-title')) {
      target.classList.remove('expanded-title')
      target.parentElement.parentElement.parentElement.classList.remove('expanded-card');
    } else {
      target.classList.add('expanded-title');
      target.parentElement.parentElement.parentElement.classList.add('expanded-card');
    }
  }
}
