import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { catchError, } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestStoreService } from '../services/request-store.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private requestStore: RequestStoreService,) { }

  ieFix = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
    'If-Modified-Since': '0'
  };

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.toLowerCase().indexOf('amazonaws.com') > -1) {
      request = request.clone({
        setHeaders: this.ieFix
      });
      return next.handle(request);
    }
    // add authorization header with basic auth credentials if available
    const currentUser = this.authService.currentUser;
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.access_token}`,
          // tslint:disable-next-line: object-literal-key-quotes
          'DeviceId': this.authService.deviceId
          // ...this.ieFix
        }
      });

      let isRefreshing = false;
      let isAnAsset = false;
      let isGoogleAnalytics = false;

      if (request.url.toLowerCase().indexOf('/refresh') > -1) isRefreshing = true;
      if (request.url.toLowerCase().indexOf('/assets/') > -1) isAnAsset = true;
      if (request.url.toLowerCase().indexOf('google') > -1) isGoogleAnalytics = true;

      if ((this.authService.hasAccessTokenExpired && !this.authService.hasRefreshTokenExpired && this.authService.access_token !== '' && !isRefreshing) && !isAnAsset && !isGoogleAnalytics) {
          return from(this.handleRefresh(request, next));
      }
    } else {
      request = request.clone({
        setHeaders: {
          // tslint:disable-next-line: object-literal-key-quotes
          'DeviceId': this.authService.deviceId
          // ...this.ieFix
        }
      });
    }
    return next.handle(request).pipe(catchError(error => this.handleAuthError(error)));
  }

  async handleRefresh(request: HttpRequest<any>, next: HttpHandler) {
    let res = await this.authService.refreshToken(this.authService.refresh_token);
    if (res.token !== undefined) {
      this.authService.setUserAsLoggedIn(res, true);

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${res.token}`,
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.access_token}`,
        }
      });
    }
    return next.handle(request).toPromise();
  }

  handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.authService.logoutUser();
      return of(err.message);
    }

    throw err;
  }
}
