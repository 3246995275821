<app-page-header hideDebitStatus="true" extraClass="full-height" imageSrc="{{ logoImage }}" imageAlt="BDDC Logo">
</app-page-header>

<div class="password-form">
  <h1 class="mat-headline">{{ heading }}</h1>
  <div class="mat-body-1 password-form_error {{
                resetErrorMessage ? 'show-error' : ''
              }}">
    {{ resetErrorMessage }}
  </div>
  <div class="password-form_wrapper">
    <ng-container *ngIf="showLinkError">
      <div fxLayout="column" class="u-text_center" fxLayoutAlign="center center">
        <div class="mat-body-1 u-text-color_red margin-bottom_15">
          Reset password link expired!
        </div>
        <div class="mat-body-2 u-text-color_red margin-bottom_15">
          Please request a new password link
        </div>
        <button mat-raised-button color="primary" routerLink="/forgot">
          New reset link
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!showLinkError">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <mat-form-field hideRequiredMarker="true" floatLabel="always" class="password-form_input password-form-field"
          [ngClass]="{ 'mat-form-field-invalid ng-invalid': showError }">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" [(ngModel)]="password" name="password"
            type="{{ showPasswordValue ? 'text' : 'password' }}" tabindex="0" required />
          <mat-icon matSuffix (click)="showPassword()" color="primary">remove_red_eye</mat-icon>
        </mat-form-field>

        <mat-form-field hideRequiredMarker="true" floatLabel="always" class="password-form_input password-form-field"
          [ngClass]="{ 'mat-form-field-invalid ng-invalid': confirmError }">
          <mat-label>Confirm Password</mat-label>
          <input matInput placeholder="Confirm Password" [(ngModel)]="confirmPass" name="passwordConfirm"
            type="{{ showPasswordValue ? 'text' : 'password' }}" tabindex="0" required />
          <mat-icon matSuffix (click)="showPassword()" color="primary">remove_red_eye</mat-icon>
        </mat-form-field>

        <div class="password-form_buttons is-centered">
          <button *ngIf="loading" mat-raised-button class="rounded">
            <div class="u-flex-center">
              <mat-spinner color="primary" diameter="30"></mat-spinner>
            </div>
          </button>

          <button *ngIf="!loading" mat-raised-button color="primary" type="submit" [disabled]="!f.valid">
            Submit
          </button>
        </div>
        <div *ngIf="showError" fxLayout="column" fxLayoutGap="10px" class="u-text-color_red mat-body-2">
          <div>Password must follow these rules:</div>
          <div class="margin-left_15 mat-body-1">
            <div>* be at least 8 characters long</div>
            <div>* contain a numeric value</div>
            <div>* contain a lowercase letter</div>
            <div>* contain an uppercase letter</div>
            <div>* may not be more than 100 characters</div>
            <div>* may not contain HTML tags</div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
<div class="mat-body-1 copy-right-text">
  &copy; Bruce's {{ year }} |
  <span class="mat-body-2 copy-right-text_link" (click)="goToCookies()">Use of cookies</span>
</div>