<div class="share-photo-dialog">
  <div class="share-photo-dialog_title">
    <h1 class="mat-display-1">{{ data.title }}</h1>
  </div>
  <div class="share-photo-dialog_subtitle">
    <p class="mat-body-1 u-text-color_gray-blue-dark">{{ data.subtitle }}</p>
  </div>
  <div class="share-photo-dialog_tip">
    <p class="mat-body-1">{{ data.tip }}</p>
  </div>
  <div class="share-photo-dialog_actions">
    <button mat-raised-button class="social-button facebook-share" (click)="shareTo('facebook')">
      <mat-icon svgIcon="facebook_icon_2"></mat-icon>
      share on facebook
    </button>
    <button mat-raised-button class="social-button twitter" (click)="shareTo('twitter')">
      <mat-icon svgIcon="twitter_icon_2"></mat-icon>
      share on twitter
    </button>
    <button mat-raised-button class="social-button email" (click)="shareTo('email')">
      <mat-icon svgIcon="mail_icon"></mat-icon>
      share via email
    </button>
  </div>
  <div class="share-photo-dialog_cancel">
    <button mat-raised-button color="secondary" (click)="closeDialog()">
      Cancel
    </button>
  </div>
</div>