<div class="service-calendar-dialog">
  <ng-container *ngIf="hasData">
    <h1 id="modal-heading" class="mat-display-1">
      Select a service
      <span (click)="closeDialog()">
        <mat-icon svgIcon="close_icon"></mat-icon>
      </span>
    </h1>
    <div class="service-calendar-dialog_services">
      <div class="quick-booking-picker">
        <div class="swiper-custom-prev quick-booking-prev"></div>
        <swiper class="swiper-container-quick-booking" touch-action="none" data-swiper-parallax="100%"
          [config]="configQuickBooking" (swiperTouchStart)="handleTouchStart($event)"
          (swiperTouchMoveOpposite)="swiperTouch($event)">
          <app-thumbnail-icon-card (click)="
        viewService(
          thumbnailCard.cardText,
          'quick booking',
          thumbnailCard.cardText
        )
      " *ngFor="let thumbnailCard of thumbnailCards" thumbnail="{{ thumbnailCard.cardImage }}"
            text="{{ thumbnailCard.cardText }}" size="mini-card"></app-thumbnail-icon-card>
        </swiper>

        <div class="swiper-custom-next quick-booking-next"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!hasData">
    <div class="app-loader">
      <div class="u-flex-center">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
    </div>
  </ng-container>
</div>