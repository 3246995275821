import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { CustomerService } from '../../services/customer.service';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { ReviewDialogComponent } from 'src/app/modules/main/pages/home/review-dialog/review-dialog.component';
import { Router } from '@angular/router';
import { checkIfHtmlExists } from '../../validators/sanitize-validator';
import { SanitizeHelper } from '../../helpers/sanitizer';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rate-us-dialog',
  templateUrl: './rate-us-dialog.component.html',
  styleUrls: ['./rate-us-dialog.component.scss']
})
export class RateUsDialogComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router, public dialogRef: MatDialogRef<any>, public overlayContainer: OverlayContainer, private breakpointObserver: BreakpointObserver, private customerService: CustomerService, private dialog: MatDialog, private sanitizer: DomSanitizer) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('rate-us-dialog_overlay');
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
    this.initForm();

    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.closeAction && this.data.closeAction === 'redirect') {
        this.router.navigate(['/login']);
      }
    });
  }

  setMobileLayout() {
    this.dialogRef.updateSize('343px', '393px');
  }

  setDesktopLayout() {
    this.dialogRef.updateSize('583px', '326px');
  }

  initForm() {
    this.form = new UntypedFormGroup({
      customerRating: new UntypedFormControl('', [
        Validators.maxLength(1500), checkIfHtmlExists,
      ])
    });
  }

  saveRating(formData: any): void {
    let satisfactionTypeId = -1;

    switch (this.data.usersRating) {
      case 'poor': {
        satisfactionTypeId = 1;
        break;
      }
      case 'average': {
        satisfactionTypeId = 2;
        break;
      }
      case 'good': {
        satisfactionTypeId = 3;
        break;
      }
    }

    if (this.data.closeAction) {
      const customerId = this.data.customersId;
      this.customerService.sendEmailRating(satisfactionTypeId, customerId, formData.customerRating).subscribe((response) => {
        if (response['result'] === true) {
          this.dialogRef.close();

          const reviewDialog = this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Thank you', body: 'Your rating has been submitted.', type: 'success' }
          });

          reviewDialog.afterClosed().subscribe(() => {
            // Show review request if the rating was good
            if (response['satisfaction_type_id'] === 3) {
              this.data.closeAction = 'no_redirect';
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = {
                place_id: response['place_id'],
                customer_id: response['customer_id']
              };
              this.dialog.open(ReviewDialogComponent, dialogConfig);
            }
          });

        } else {
          console.error('SEND RATING ERROR');
        }
      });
    } else {
      this.customerService.sendRating(satisfactionTypeId, formData.customerRating).subscribe((response) => {
        if (response['result'] === true) {
          this.dialogRef.close();

          // Show review request if the rating was good
          if (response['satisfaction_type_id'] === 3) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              place_id: response['place_id']
            };
            this.dialog.open(ReviewDialogComponent, dialogConfig);
          }

          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Thank you', body: 'Your rating has been submitted.', type: 'success' }
          });
        } else {
          console.error('SEND RATING ERROR');
        }
      });
    }

    // this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('review-dialog_overlay');
  }
}
