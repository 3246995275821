import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { CookieTable } from 'src/app/shared/components/info/cookies/cookies.component';

const ELEMENT_DATA: CookieTable[] = [
  {
    cookie: 'Google Analytics', name: '_utma; _utmb; _utmc; _utmz; _ga; _gat_gtag_UA_125882190_2; DoubleClick', cookieDescription:
      // tslint:disable-next-line: max-line-length
      'These cookies are used to collect information about how visitors use our website. We use this information to help us improve our website. These cookies collect information in an anonymous form.' +
      // tslint:disable-next-line: max-line-length
      '<br><br>Find out more about Google Analytics privacy policy <a href="http://www.google.co.uk/intl/en/analytics/privacyoverview.html">here.</a>'
  },
  // tslint:disable-next-line: max-line-length
  { cookie: 'Website', name: '	has_js', cookieDescription: 'This cookie is used to determine if your web browser supports modern web pages. We use it to give you the best possible experience when visiting the site.' },
  { cookie: 'team.bruces.dog', name: 'branch_id', cookieDescription: 'This cookie is used to determine your branch ID' },
  // tslint:disable-next-line: max-line-length
  { cookie: 'Access Token', name: '_bddc_access_token', cookieDescription: 'This cookie is used to provide access to the customer portal' },
  // tslint:disable-next-line: max-line-length
  { cookie: 'Device Id', name: '_bddc_device_id', cookieDescription: 'This cookie is used to identify the device that is using the customer portal' },
  // tslint:disable-next-line: max-line-length
  { cookie: 'Refresh Token', name: '_bddc_refresh_token', cookieDescription: 'This cookie is used to refresh the customer portal when a new access token is issued' },
];

@Component({
  selector: 'cookies-public',
  templateUrl: './cookies-public.component.html',
  styleUrls: ['./cookies-public.component.scss']
})
export class CookiesPublicComponent implements OnInit, OnDestroy {
  private breakpointSub: Subscription;
  logoImage = './../../../../../assets/images/bddc-bouncer-logo.png';
  headerImage: string;
  isDesktopLayout: boolean;
  year: number;
  loading = false;

  displayedColumns: string[] = ['cookie', 'name', 'what_this_cookie_does'];
  dataSource = ELEMENT_DATA;

  // tslint:disable-next-line: max-line-length
  constructor(private titleService: Title, private dialog: MatDialog, private authService: AuthService, private router: Router, breakpointObserver: BreakpointObserver) {
    this.breakpointSub = breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/mobile_bg.jpg';
        this.isDesktopLayout = false;
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/desktop_bg.jpg';
        this.isDesktopLayout = true;
      }
    });
  }

  goToLogin(): void {
    this.router.navigate(['login']);
  }

  ngOnInit() {
    this.setTitle('Bruce\'s | Use of cookies');
    this.year = new Date().getFullYear();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnDestroy() {
    this.breakpointSub.unsubscribe();
  }

}
