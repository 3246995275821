import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  // HttpClient API get() method => Fetch account details
  getAccountBalance(): Observable<any> {
    const apiUrl = '/customer_object/get_customer_balance';
    const accountDetails = this.http.get<any>(this.baseApiService.API_URL + apiUrl);

    return accountDetails;
  }

  getDirectDebitStatus(): Observable<any> {
    const apiUrl = '/customer_object/has_direct_debit_update';
    const result = this.http.get<any>(this.baseApiService.API_URL + apiUrl);

    return result;
  }

  getDirectDebitLinks(): Observable<any> {
    const apiUrl = '/customer_object/account_direct_debit_status';
    const result = this.http.get<any>(this.baseApiService.API_URL + apiUrl);

    return result;
  }

  getGoCardlessUrl(id: string) {
    const apiUrl = '/customer_object/gocardless_auth?id=' + id;
    const result = this.http.get(this.baseApiService.API_URL + apiUrl);

    return result;
  }
}
