import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { UUID } from 'angular2-uuid';

import { Observable, timer, Subscription, BehaviorSubject } from 'rxjs';
import { map, retry, catchError, takeWhile, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { BaseApiService } from 'src/app/core/services/base-api.service';
import { User } from 'src/app/shared/models/user';
import { Password } from 'src/app/shared/interfaces/password.interface';
import { SessionDialogComponent } from 'src/app/modules/core/session/session-dialog/session-dialog.component';
import { ProfilePassword } from 'src/app/shared/interfaces/profile-password.interface';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthService {
  rememberMe: boolean;
  currentUser: User;
  diffTime = 0;
  diffTimeSeconds = 0;
  dateNow: Date;
  accessToken = '';
  reloadToken = '';
  userSessionInfo: any;
  userAction = '';
  authSubscription: Subscription;
  expireToken: any;
  loginMethod = false;
  fromProfile = new BehaviorSubject<boolean>(false);
  fromProfileEmail: string;
  jwtHelper: JwtHelperService;
  tokenRefreshing: Boolean = false;

  // tslint:disable-next-line: max-line-length
  constructor(
    private http: HttpClient,
    private baseApiService: BaseApiService,
    private cookieService: CookieService,
    private dialog: MatDialog,) {

    this.jwtHelper = new JwtHelperService();
    this.currentUser = {} as User;
    this.accessToken = this.cookieService.get('_bddc_access_token');
    this.reloadToken = this.cookieService.get('_bddc_refresh_token');

    if (!window.location.href.includes('login') && window.location.pathname !== '/') {
      if (localStorage.diffTimeSeconds) {
        // this.timerSubscription();
        // tslint:disable-next-line: radix
        // this.diffTimeSeconds = parseInt(localStorage.diffTimeSeconds);
      }
    }
    this.setDeviceId();

    this.fromProfile.next(false);
  }

  trackLoginAttempt(email: string, data: string) {
    const body = { email_address: email, browser_data: data };
    return this.http.post<any>(`${this.baseApiService.API_URL}/users/track_login_attempt`, body).pipe(map(res => {
      return res;
    }));
  }

  trackPasswordAttempt(email: string, data: string) {
    const body = { email_address: email, browser_data: data };
    return this.http.post<any>(`${this.baseApiService.API_URL}/users/track_password_attempt`, body).pipe(map(res => {
      return res;
    }));
  }

  restartTimer() {
    const body = { refreshToken: this.reloadToken };
    this.http.post<any>(`${this.baseApiService.API_URL}/users/refresh`, body).toPromise().then(resp => {
      if (resp.result) {
        this.cookieService.set('_bddc_refresh_token', resp.refresh_token, resp.expire_at, '/');
        this.cookieService.set('_bddc_access_token', resp.token, resp.expire_at, '/');
        // tslint:disable-next-line: radix
        this.expireToken = new Date(parseInt(resp.expire_at));
        this.diffTime = this.timeDiffCalculation(this.expireToken);
        this.reloadToken = this.cookieService.get('_bddc_refresh_token');
        if (!this.reloadToken) {
          document.cookie = '_bddc_refresh_token=' + resp.refresh_token + '; expires=' + resp.expire_at + '; path=/';
          this.reloadToken = this.cookieService.get('_bddc_refresh_token');
        }
        if (this.cookieService.check('_bddc_access_token')) {
          document.cookie = '_bddc_access_token=' + resp.token + '; expires=' + resp.expire_at + '; path=/';
        }
        // tslint:disable-next-line: radix
        this.diffTimeSeconds = parseInt((this.diffTime / 1000).toFixed(0));

        this.userSessionInfo = resp;

        localStorage.diffTimeSeconds = this.diffTimeSeconds;

        this.timerSubscription();
      }
    });
  }

  loginUser(form: User) {
    this.loginMethod = true;
    this.setDeviceId();
    return this.http.post<any>(`${this.baseApiService.API_URL}/users/auth`, { ...form, refresh_check: true })
      .pipe(map(res => {
        // Staff member tried to log in
        if (res.isStaff) {
          return res;
        }

        if (!res.result) {
          return false;
        } else {
          this.userSessionInfo = res;
          this.setUserAsLoggedIn(res);
          return res;
        }
      }));
  }

  logoutUser() {
    localStorage.removeItem('diffTimeSeconds');

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }

    this.http.post(`${this.baseApiService.API_URL}/users/logout`, { refreshToken: this.reloadToken })
      .subscribe((res) => {
        this.cookieService.deleteAll('/');
        // need to add loader when pathname is triggered
        // Clear all cookies
        document.cookie = '_bddc_refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = '_bddc_device_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = '_bddc_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        this.reloadToken = '';
        this.accessToken = '';
        location.pathname = '/login';
      });
  }

  setUserAsLoggedIn(res: any, refresh?: boolean) {
    // Set expiry to same as server
    // tslint:disable-next-line: radix
    this.expireToken = new Date(parseInt(res.expire_at));

    // if (this.rememberMe) {
    //   // Add 2 years to refresh token expiry [hours X minutes X seconds X miliseconds]
    //   this.expireToken.setTime(this.expireToken.getTime() + 17520 * 60 * 60 * 1000);
    // }

    // this.diffTime = this.timeDiffCalculation(this.expireToken);
    // // For testing subtract 10770 from diffTimeSeconds
    // // tslint:disable-next-line: radix
    // this.diffTimeSeconds = parseInt((this.diffTime / 1000).toFixed(0));

    // localStorage.diffTimeSeconds = this.diffTimeSeconds;
    // // User is inactive for 1 minute - start refresh token

    // if (this.userAction === 'refresh') {
    //   localStorage.removeItem('diffTimeSeconds');
    //   this.authSubscription.unsubscribe();
    //   this.restartTimer();
    // } else {
    //   this.timerSubscription();
    // }
    this.expireToken.setTime(this.expireToken.getTime() + 17520 * 60 * 60 * 1000);

    if (this.accessToken === '' || refresh) {
      this.cookieService.set('_bddc_access_token', res.token, this.expireToken, '/');
      this.accessToken = this.cookieService.get('_bddc_access_token');
      if (!this.accessToken || refresh) {
        document.cookie = '_bddc_access_token=' + res.token + '; expires=' + this.expireToken + '; path=/';
        this.accessToken = this.cookieService.get('_bddc_access_token');
      }
    }

    if (this.reloadToken === '' || refresh) {
      this.cookieService.set('_bddc_refresh_token', res.refresh_token, this.expireToken, '/');
      this.reloadToken = this.cookieService.get('_bddc_refresh_token');
      if (!this.reloadToken || refresh) {
        document.cookie = '_bddc_refresh_token=' + res.refresh_token + '; expires=' + this.expireToken + '; path=/';
        this.reloadToken = this.cookieService.get('_bddc_refresh_token');
      }
    }

    this.currentUser.isActive = res.user.is_active;
    localStorage.setItem('active', '' + this.currentUser.isActive + '');
    this.currentUser.email = res.user.email;
  }

  resetUserTokensAndLogIn(res: any) {
    this.expireToken = new Date(parseInt(res.expire_at));

    document.cookie = '_bddc_access_token=' + res.token + '; expires=' + this.expireToken + '; path=/';
    this.accessToken = this.cookieService.get('_bddc_access_token');

    document.cookie = '_bddc_refresh_token=' + res.refresh_token + '; expires=' + this.expireToken + '; path=/';
    this.reloadToken = this.cookieService.get('_bddc_refresh_token');

    this.currentUser.isActive = res.user.is_active;
    localStorage.setItem('active', '' + this.currentUser.isActive + '');
    this.currentUser.email = res.user.email;
  }

  timerSubscription() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = undefined;
    }
    this.authSubscription = timer(0, 1000).pipe(
      takeWhile(() => this.diffTimeSeconds > 0),
      tap(() => this.diffTimeSeconds--)
    ).subscribe(() => {
      if (this.diffTimeSeconds === 0) {
        this.displaySessionDialog();
      }
    });
  }

  displaySessionDialog() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'You have been idle too long.',
      description: 'If you wish to stay signed in, please select the stay button.'
    };

    const dialogRef = this.dialog.open(SessionDialogComponent, dialogConfig);

    dialogRef.componentInstance.userAction.subscribe(result => {
      this.userAction = result;
      if (result === 'refresh') {
        this.authSubscription.unsubscribe();
        if (this.loginMethod) {
          this.setUserAsLoggedIn(this.userSessionInfo);
        } else {
          this.restartTimer();
        }
      } else {
        this.authSubscription.unsubscribe();
        this.logoutUser();
      }
    });
  }

  timeDiffCalculation(token) {
    this.dateNow = new Date(Date.now());
    return Math.abs(token.getTime() - this.dateNow.getTime());
  }

  rememberMeSession(): void {
    this.rememberMe = true;
  }

  forgotPassword(user: User): Observable<User> {
    return this.http.post<User>(`${this.baseApiService.API_URL}/forgot`, user)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  validatResetKey(reset_key) {
    return this.http.post<Password>(`${this.baseApiService.API_URL}/validate_reset_key`, reset_key)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  resetPassword(password: Password): Observable<Password> {
    return this.http.post<Password>(`${this.baseApiService.API_URL}/password_reset`, password)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  resetPasswordFromProfile(password: ProfilePassword): Observable<ProfilePassword> {
    this.fromProfile.next(false);
    password.email = this.fromProfileEmail;
    return this.http.post<ProfilePassword>(`${this.baseApiService.API_URL}/password_reset_from_profile`, password)
      .pipe(
        retry(1),
        catchError(this.baseApiService.handleError)
      );
  }

  verifyPassword(form: User) {
    return this.http.post<any>(`${this.baseApiService.API_URL}/users/verify_password`, { password: form.password });
  }

  isAuth() {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      const cookieExists = this.cookieService.check('_bddc_access_token');
      if (cookieExists) {
        resolve(true);
      } else {
        if (this.reloadToken !== '' && !this.tokenRefreshing) {
          this.tokenRefreshing = true;
          const body = { refreshToken: this.reloadToken };
          return this.http.post<any>(`${this.baseApiService.API_URL}/users/refresh`, body)
            .subscribe(res => {
              this.tokenRefreshing = false;
              if (!res.result) {
                resolve(false);
              } else {
                this.setUserAsLoggedIn(res);
                resolve(true);
              }
            });
        } else {
          resolve(false);
        }
      }
    });
  }

  // tslint:disable-next-line: variable-name
  async refreshToken(_token: string) {
    const body = { refreshToken: _token, refresh_check: true };

    return await this.http.post<any>(`${this.baseApiService.API_URL}/users/refresh`, body).toPromise();
  }

  setDeviceId() {
    // tslint:disable-next-line: variable-name
    const cookieExists = this.cookieService.check('_bddc_device_id');

    if (!cookieExists) {
      const tempDeviceId = UUID.UUID();
      // tslint:disable-next-line: prefer-const
      let expire = new Date();
      expire.setTime(expire.getTime() + 17520 * 60 * 60 * 1000);
      this.cookieService.set('_bddc_device_id', tempDeviceId, expire, '/');
      if (!this.cookieService.check('_bddc_device_id')) {
        document.cookie = '_bddc_device_id=' + tempDeviceId + '; expires=' + expire + '; path=/';
      }
    }
  }

  get deviceId(): string {
    return this.cookieService.get('_bddc_device_id');
  }

  get access_token(): string {
    return this.cookieService.get('_bddc_access_token');
  }

  get refresh_token(): string {
    return this.cookieService.get('_bddc_refresh_token');
  }

  get hasAccessTokenExpired(): Boolean {
    return this.jwtHelper.isTokenExpired(this.access_token);
  }

  get hasRefreshTokenExpired(): Boolean {
    return this.jwtHelper.isTokenExpired(this.refresh_token);
  }
}
