<app-page-header headerImage="{{ headerImage }}"></app-page-header>
<div class="faq">
  <div class="faq_header">
    <h1 class="mat-display-1">
      {{ headerTitle }}
    </h1>
  </div>
  <div class="core-content">
    <mat-accordion class="flat">
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Having trouble sharing photos to Facebook or Twitter?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          You may need to disable your browser’s default popup blocker or any
          other external popup blockers you may have installed.
        </p>
        <p class="mat-body-1">
          Here are a few tips on how to disable the default popup blockers.
        </p>
        <p class="mat-body-1">
          Safari:
        </p>
        <ol>
          <li>
            From the Safari menu, choose Preferences… and click the Security
            tab. Ensure the Block pop-up windows option is not checked.
          </li>
          <li>Unchecking this option will allow pop-ups.</li>
          <li>
            To block pop-ups once again, check the Block pop-up windows
            checkbox.
          </li>
        </ol>
        <p class="mat-body-1">
          Google Chrome:
        </p>
        <ol>
          <li>
            Click the Chrome menu on the browser toolbar, then select Settings.
          </li>
          <li>Scroll Down and Click Show advanced settings.</li>
          <li>In the “Privacy” section, click the Content settings button.</li>
          <li>
            Scroll down to the “Pop-ups” section, click Manage exceptions….
          </li>
          <li>
            In the “Hostname pattern”, type the pattern,
            [*.]brucedoggydaycare.co.uk, then press Done to save your changes.
          </li>
        </ol>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Are you insured?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. Bruce’s is insured to professionally with specialist animal trade insurers. Our insurance
          covers
          public liability; day care, custody, control and transportation of your dog; loss of keys and boarding.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Are you licensed?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. We are licensed under the Animal Activities Regulations 2018.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Can I meet the team and see the facilities before sending my dog?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. We recommend you do this! Come and see the place in action. Meet the team, ask questions, see exactly
          where your
          dog will spend their day, who they’ll be with, and what they’ll be doing. We’re confident you will be
          impressed!
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            What happens if my dog doesn’t get on with another dog?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Bruce’s is extremely strict in only accepting social dogs. This ensures all of the dogs are
          happy in one
          another’s company, so it is unlikely your dog will not get on with another dog. In fact, the dogs usually form
          their own
          social groups with dogs at the Day Care Centre who enjoy similar activities!
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Do you only accept well-trained dogs?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          No. In fact, one of the reasons people choose Bruce’s is that our Day Care Centre often helps
          with
          training. Many owners have noticed improvement in their dog's behaviour after attending the Day Care Centre.
          However, we
          only accept social dogs that have no history of aggression
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Do I need to be home when you collect or drop off my dog?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          No. Most clients are comfortable sharing a set of keys with Bruce’s to enable us to collect and
          drop off
          your dog even if you are not home. All staff who have responsibility of client’s keys are CRB checked.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Will my dog be supervised at all times?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. Your dog will be under constant supervision by our team of experienced doggy supervisors at the Day Care
          Centre,
          and will NEVER be left unattended.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            What happens when it rains or is too cold for my dog to be outside?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          There is a heated building in every field. You dog will have a choice of comfy dog beds if they would like to
          escape the
          weather or have a snooze. The dogs have constant access to the building via a big dog flap, so they can use it
          whenever
          they like!
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            I have a puppy or small dog, can they come to the Day Care Centre?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. The day care centre is the perfect environment for your puppy to grow and socialise. We accept puppies
          from as
          young as 11 weeks old or once they are out of their vaccination period.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Do I need to book in the same days each week?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes, but you only need to commit to one fixed day each week. It is possible to add additional days as
          required.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            My dog doesn’t like crates or confined spaces, will he cope with
            travelling to and from the centre?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          Yes. The crates are vet approved and have more space than necessary for even the bigger breeds. Very rarely do
          we find
          that the dogs have any issues with the van or crates, since it has a hugely positive association for them – it
          means
          they get to have a fun day running around with all of their friends!
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Can my dog stay at the Day Care Centre overnight?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          No. Dogs cannot stay at the Day Care Centre overnight. If you require this service, we offer a pet sitting or
          boarding
          service.
        </p>
      </mat-expansion-panel>
      <hr class="hr-styling flat-hr" />
      <mat-expansion-panel>
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            Do you offer day care on the weekends and bank holidays?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="mat-body-1">
          The Bruce’s Centre only operates from Monday-Friday. However, upon request we do offer small
          group walks
          on weekends and bank holidays.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>