import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export class SanitizeHelper {
  constructor(private sanitizer: DomSanitizer) {}

  private sanitizeHtml(value: string): string {
    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }

  // Currently causes issues, for some reason picks up anything as an error
  private sanitizeScript(value: string): string {
    return this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
  }

  public fullSanitize(value: string): string {
    let htmlStripped: string = this.sanitizeHtml(value);
    return htmlStripped;
  }
}
