import { Component, Inject, InjectionToken, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

export const ERROR_INJECTOR_TOKEN: InjectionToken<any> = new InjectionToken('ErrorInjectorToken');

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  private isVisible = new Subject();
  dismiss$: Observable<{}> = this.isVisible.asObservable();
  backdropEl = document.querySelector('.cdk-overlay-backdrop.error-backdrop');
  headerImage = './../../../assets/images/backgrounds/error-header.png';
  buttonText: string;

  constructor(@Inject(ERROR_INJECTOR_TOKEN) public error, breakpointObserver: BreakpointObserver, private router: Router) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/error_mobile.jpg';
        this.buttonText = 'Ok';
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/error_desktop.jpg';
        this.buttonText = 'Okay';
      }
    });
  }

  ngOnInit() {
    const backdropEl = document.querySelector('.cdk-overlay-backdrop.error-backdrop');
    const panelEl = backdropEl.parentElement;

    panelEl.classList.add('error-panel');
  }

  dismiss() {
    this.isVisible.next(this);
    this.isVisible.complete();
    this.router.navigate(['/home']);
  }

  ngOnDestroy() {
    const panelEl = document.querySelector('.error-panel');
    panelEl.classList.remove('error-panel');
  }

}
