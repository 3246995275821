<div class="photo-view-dialog">
  <div class="photo-view-dialog_close">
    <mat-icon svgIcon="close_icon"
      (click)="closeDialog()"></mat-icon>
  </div>
  <div class="photo-view-dialog_header-action">
    <button *ngIf="userActive"
      mat-raised-button
      class="rounded-edge"
      color="white-transparent"
      (click)="makeProfilePic(data.photos[selectedImage])">
      <ng-container *ngIf="!showSpinner">Make profile picture</ng-container>
      <ng-container *ngIf="showSpinner">
        <mat-spinner color="primary"
          diameter="20"></mat-spinner>
      </ng-container>
    </button>
  </div>

  <div class="photo-view-dialog_swiper">
    <swiper [config]="config"
      (indexChange)="onIndexChange($event)">
      <ng-container *ngFor="let photo of data.photos">
        <div class="swiper-slide">
          <div class="image"
            [style.background-image]="'url(' + photo.path + ')'"></div>
        </div>
      </ng-container>
    </swiper>
  </div>

  <div class="photo-view-dialog_caption">
    <ng-container *ngIf="data.photos[selectedImage].taggedDogs">
      <ng-container *ngIf="data.photos[selectedImage].taggedDogs.includes('and ')">
        <p class="mat-body-1 u-text-color_white">
          {{ data.photos[selectedImage].taggedDogs }}
          <span class="u-text-weight_300">were tagged</span>
        </p>
      </ng-container>
      <ng-container *ngIf="!data.photos[selectedImage].taggedDogs.includes('and ')">
        <p class="mat-body-1 u-text-color_white">
          {{ data.photos[selectedImage].taggedDogs }}
          <span class="u-text-weight_300">was tagged</span>
        </p>
      </ng-container>
    </ng-container>
  </div>

  <div mat-dialog-actions
    class="photo-view-dialog_actions">
    <button *ngIf="userActive"
      mat-raised-button
      color="white-transparent"
      (click)="favouriteThisPhoto(data.photos[selectedImage])"
      [ngClass]="data.photos[selectedImage].isFavourite ? 'is-favourite' : ''">
      <mat-icon svgIcon="carousel-heart_icon"
        [ngClass]="data.photos[selectedImage].isFavourite ? 'mat-red' : ''">
      </mat-icon>Favourite
    </button>
    <a mat-raised-button
      color="white-transparent"
      class="mobile-menu_link"
      [disabled]="disable"
      (click)="download(selectedImage)">
      <mat-icon svgIcon="carousel-download_icon"></mat-icon>Download
    </a>
    <button mat-raised-button
      color="white-transparent"
      (click)="shareThisPhoto(selectedImage)">
      <mat-icon svgIcon="carousel-share_icon"></mat-icon>Share
    </button>
  </div>
</div>