<app-page-header headerImage="{{ headerImage }}"></app-page-header>
<div class="privacy-policy">
  <div class="privacy-policy_header">
    <h1 class="mat-display-1">Our privacy policy</h1>
  </div>
  <div class="core-content">
    <p class="mat-body-1">
      Bruce’s Doggy Day Care Privacy Statement
    </p>
    <p class="mat-body-1">
      This Privacy Statement was published in May 2018.
    </p>
    <p class="mat-body-1">
      At Bruce’s Pet Care Limited t/a Bruce Doggy Day Care (BDDC) we are committed to protect and respect your privacy in compliance with EU- General Data Protection Regulation (GDPR) 2016/679, dated April 27th 2016. This privacy statement explains when and why we collect personal information, how we use it, the conditions under which we may disclose it to others and how we keep it secure. This Privacy Statement applies to customer contract fulfillment activities and marketing. It also applies to individuals seeking a job in BDDC.
    </p>
    <p class="mat-body-1">
      When do we collect personal data about you?
    </p>
    <ul>
      <li>When you sign up to use our services.</li>
      <li>When you interact with us in person, through correspondence, by phone, by social media, or through our website.</li>
      <p class="mat-body-1">
        Why do we collect and use personal data?
      </p>
      <p class="mat-body-1">
        We collect and use personal data mainly to perform our customer service to you. We also collect data about suppliers, partners and persons seeking a job or working in our company.
      </p>
      <p class="mat-body-1">
        We may use your information for the following purposes:
      </p>
      <li>Send you marketing communications which you have requested. These may include information about our products and services, events, activities. This communication is subscription based and requires your consent.</li>
      <li>Send you information about the products and services that you have purchased from us.</li>
      <li>Reply to a ‘Contact us’ or ‘Apply for a job with us’ or other web forms you have completed on our website.</li>
      <li>Follow up on incoming requests (customer support, emails, or phone calls).</li>
      <li>Perform contractual obligations such as service confirmation, invoice, reminders, and similar.</li>
      <li>Notify you about any disruptions to our services (system messages).</li>
      <li>Contact you to conduct surveys about your opinion on our services.</li>
      <li>Process a job application.</li>
    </ul>
    <p class="mat-body-1">
      Our legal basis for collecting personal data
    </p>
    <p class="mat-body-1">
      Collecting personal data based on contracts
    </p>
    <p class="mat-body-1">
      We use personal information for fulfilling our obligations related to agreements with our customers, partners and suppliers.
    </p>
    <p class="mat-body-1">
      What type of personal data is collected?
    </p>
    <p class="mat-body-1">
      We collect customers’ name and contact details. We may also collect feedback, comments and questions received from you in service-related communication and activities, such as meetings, phone calls, documents, and emails. From our websites, we may collect IP-address and actions taken on the site.
    </p>
    <p class="mat-body-1">
      If you upload photos or videos, add posts or comments, etc. on our online customer portal, the information can be read by all BDDC staff at Operations level or above.
    </p>
    <p class="mat-body-1">
      If you apply for a job at BDDC, we collect the data you provide during the application process. Once hired, we collect employees’ name and contact details and details required to make payments.
    </p>
    <p class="mat-body-1">
      BDDC does not collect or process any special categories of personal data, such as public unique identifiers or sensitive personal data.
    </p>
    <p class="mat-body-1">
      How long do we keep your personal data?
    </p>
    <p class="mat-body-1">
      We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems, to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.
    </p>
    <p class="mat-body-1">
      This means that we may retain your personal data for a reasonable period of time after your last interaction with us. When the personal data that we have collected is no longer required, we will delete it in a secure manner. We may process data for internal statistical purposes and analysis.
    </p>
    <p class="mat-body-1">
      Your rights to your personal data
    </p>
    <p class="mat-body-1">
      You have the following rights with respect to your personal data:
    </p>
    <ul>
      <li>The right to request a copy of your personal data that BDDC holds about you.</li>
      <li>The right to request that BDDC corrects your personal data if inaccurate or out of date.</li>
      <li>If you are a customer, you may update your user profile by logging into the app or online customer portal.</li>
      <li>The right to request that your personal data is deleted when it is no longer necessary for BDDC to retain such data.</li>
      <li>The right to withdraw any consent to personal data processing at any time. For example, your consent to receive e-marketing communications:</li>
      <li>If you want to withdraw your consent to e-marketing, please make use of the unsubscribe link to manage your subscriptions included in our communication.</li>
      <p class="mat-body-1">
        Please note that you will still receive system messages and administrative communications from BDDC, such as check-in notifications, service request confirmations and notifications about your account activities.
      </p>
      <li>The right to request that BDDC provides you with your personal data and, if possible, to pass on this information directly (in a portable format) to another data controller when the </li>processing is based on consent or contract.
      <li>The right to request a restriction on further data processing, in case there is a dispute in relation to the accuracy or processing of your personal data.</li>
      <li>The right to object to the processing of personal data, in case data processing has been based on legitimate interest and/or direct marketing.</li>
    </ul>
    <p class="mat-body-1">
      Any query about your Privacy Rights should be made through our “Contact” form on our website.
    </p>
    <p class="mat-body-1">
      The use of cookies and beacons
    </p>
    <p class="mat-body-1">
      We may use cookies and web (‘Website Navigational Information’) to collect information as you navigate the company’s websites. Website Navigational Information includes standard information from your web browser, such as browser type and browser language; your Internet Protocol (“IP”) address; and the actions you take on the company’s websites, such as the web pages viewed and the links clicked.
    </p>
    <p class="mat-body-1">
      This information is used to make websites work more efficiently, as well as to provide business and marketing information to the owners of the site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use a website. Cookies and similar technologies help us tailor our website to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.
    </p>
    <p class="mat-body-1">
      Do we share your data with anyone?
    </p>
    <p class="mat-body-1">
      We do not share, sell, rent, or trade your information with any third parties without your consent, except from what is described below:
    </p>
    <p class="mat-body-1">
      Third-party Service Providers working on our behalf:
    </p>
    <p class="mat-body-1">
      We may pass your information on to our distributors, agents, sub-contractors and other associated organizations with the purpose of them providing services to you on our behalf.
    </p>
    <p class="mat-body-1">
      If required by law:
    </p>
    <p class="mat-body-1">
      We will disclose your personal information if required by law or if we, as a company, reasonably believe that disclosure is necessary to protect our company’s rights and/or to comply with a judicial proceeding, court order or legal process. However, we will do what we can to ensure that your privacy rights continue to be protected.
    </p>
    <p class="mat-body-1">
      Use of sub-contractors (processors and sub-processors)
    </p>
    <p class="mat-body-1">
      We may use sub-contractors to process personal data on our behalf. We are responsible for making sure they commit themselves to adhering to this Privacy Policy and applicable data protection legislation by signing a Data Processing Agreement.
    </p>
    <p class="mat-body-1">
      If the sub-contractor processes Personal Data outside the EU/EEA area, such processing must be in accordance with the EU Privacy Shield Framework, EU Standard Contractual Clauses for transfer to third countries, or another specifically stated lawful basis for the transfer of personal data to a third country.
    </p>
    <p class="mat-body-1">
      Changes to this Privacy Statement
    </p>
    <p class="mat-body-1">
      Bruce’s Pet Care Limited reserves the right to amend this Privacy Statement at any time. The applicable version will always be found on our websites. We encourage you to check this Privacy Statement occasionally to ensure that you are happy with any changes.
    </p>
    <p class="mat-body-1">
      If we make changes that significantly alter our privacy practices, we will notify you by email or post a notice on our websites prior to the change taking effect.
    </p>
    <p class="mat-body-1">
      Your right to complain with a supervisory authority
    </p>
    <p class="mat-body-1">
      If you are unhappy with the way in which your personal data has been processed, you may, in the first instance, contact Bruce Casalis using the “Contact” form on our website.
    </p>
    <p class="mat-body-1">
      If you are still dissatisfied, please contact the Information Commissioner’s Office (ICO): ico.org.uk
    </p>
    <p class="mat-body-1">
      37 Western Drive
    </p>
    <p class="mat-body-1">
      Shepperton
    </p>
    <p class="mat-body-1">
      Middlesex
    </p>
    <p class="mat-body-1">
      TW17 8HP
    </p>
    <p class="mat-body-1">
      United Kingdom
    </p>
    <p class="mat-body-1">
      When contacting us to ascertain the information we hold about you, it would be helpful to you could provide us with full details of your full name, company and email address
    </p>
  </div>
</div>
