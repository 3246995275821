import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { UploadVaccinationComponent } from './upload-vaccination/upload-vaccination.component';


@Component({
  selector: 'app-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss']
})
export class BannerNotificationComponent implements OnInit {

  container;
  keepContainer: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, overlayContainer: OverlayContainer, private dialog: MatLegacyDialog,) {
    overlayContainer.getContainerElement().classList.add('broadcast-dialog_overlay');
    this.container = overlayContainer;
  }

  ngOnInit(): void {
  }

  onUpdate(): void {
    this.keepContainer = true;
    const dialogRef = this.dialog.open(UploadVaccinationComponent, {});
  }

  ngOnDestroy(): void {
    if (!this.keepContainer) {
      this.container.getContainerElement().classList.remove('broadcast-dialog_overlay');
    }
  }
}
