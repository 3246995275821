import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { RequestStoreService } from "./request-store.service";

@Injectable({ providedIn: 'root' })
export class RefreshService {
  constructor(private authService: AuthService,) {}

  public async checkCurrentToken(requestStore?: RequestStoreService): Promise<boolean> {
    if (this.authService.hasAccessTokenExpired && !this.authService.hasRefreshTokenExpired && this.authService.access_token !== '') {
      if (requestStore) requestStore.isRefreshing = true;
      let hasRefreshed = await this.refreshToken();
      if (requestStore) requestStore.isRefreshing = false;
      if (hasRefreshed) {
        return true;
      } else {
        if (this.authService.hasAccessTokenExpired && !this.authService.hasRefreshTokenExpired && this.authService.access_token !== '') {
          this.authService.logoutUser();
          return false;
        }
        return true;
      }
    } else {
      return false;
    }
  }

  private async refreshToken(): Promise<boolean> {
    let res = await this.authService.refreshToken(this.authService.refresh_token);
    if (res.token !== undefined) {
      this.authService.setUserAsLoggedIn(res, true);
      return true;
    } else {
      return false;
    }
  }
}
