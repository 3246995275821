<div class="tagged-dog-card">
  <div class="u-flex-column icon-placement">
    <mat-icon svgIcon="close_icon" (click)="closeDialog()"></mat-icon>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <h2 class="mat-display-2">Set profile picture for:</h2>
    <div class="tagged-dog-card_dogs">
      <mat-radio-group formArrayName="dogs" class="u-flex-column">
        <mat-radio-button *ngFor="let dog of data.imageObj.taggedDogsObj; let i=index" color="primary"
          (change)="radioButtonOnChange(dog.dog_id)" [value]="dog.dog_id" class="margin-right_15 padding-bottom_10">
          {{ dog.dog_name }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <button *ngIf="!showSpinner" mat-raised-button color="primary" type="submit" class="margin-top_20-override">
      APPLY
    </button>

    <button *ngIf="showSpinner" mat-raised-button class="rounded u-margin-left_10">
      <div class="u-flex-center">
        <mat-spinner color="primary" diameter="30"></mat-spinner>
      </div>
    </button>
  </form>
</div>