<div class="error-dialog" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="error-dialog_header">
    <header
      class="error-dialog-header"
      [style.background-image]="'url(' + headerImage + ')'"
    >
      <div class="error-dialog-header_curve"></div>
    </header>
  </div>
  <div class="error-dialog_title">
    <h1 class="mat-display-1">{{ error.title }}</h1>
  </div>
  <div class="error-dialog_description">
    <p class="mat-body-1">{{ error.message }}</p>
  </div>
  <div class="error-dialog_action">
    <button mat-raised-button color="primary" (click)="dismiss()">
      {{ buttonText }}
    </button>
  </div>
</div>
