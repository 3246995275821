import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DownloadAppButtonsComponent } from '../download-app-buttons/download-app-buttons.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-download-app-notification',
  templateUrl: './download-app-notification.component.html',
  styleUrls: ['./download-app-notification.component.scss']
})
export class DownloadAppNotificationComponent implements OnInit, AfterViewInit {
  topImage = './../../../../../assets/images/backgrounds/components/top_image.png';
  bottomImage = './../../../../../assets/images/backgrounds/components/bottom_image.png';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const backdropEl = document.querySelector('.cdk-overlay-pane .mat-dialog-container');
    backdropEl.classList.add('padding_none');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
