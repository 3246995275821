<ng-container *ngIf="dogs">
  <ng-container *ngFor="let dog of dogs; let i = index">
    <mat-card class="dog-summary-background-blue" [class.no-milestones]="dog['milestones'].length === 0">
      <mat-card-header (click)="viewProfile(i)">
        <div mat-card-avatar [style.background-image]="dog['profile_picture']" class="card-image-expand"></div>
        <mat-card-title class="u-text-color_white"><span class="mat-display-2 u-text-color_white">{{
            dog["name"]
            }}
            <span class="mat-body-1 u-text-color_gray-blue">
              <ng-container *ngIf="dog['latest_milestone']"> -
                {{ rookieMilestone.includes(dog['latest_milestone']['id']) ? 'Rookie Rover' :
                dog["latest_milestone"]['internal_milestone_name'] }}
              </ng-container>
            </span>
          </span>
        </mat-card-title>
        <mat-card-subtitle class="dog-summary_sub {{!dog['latest_milestone'] ? 'fix-height' : ''}}">
          <ng-container *ngIf="deviceIsMobile">
            <a class="remove-styling" (click)="viewProfile(i)">profile ></a>
          </ng-container>
        </mat-card-subtitle>
        <mat-card-subtitle>
          <div class="mat-body-1 u-text-color_gray-blue">
            {{ dog["name"] + " is " + dog["dog_status"] }}
          </div>
        </mat-card-subtitle>
        <mat-card-subtitle class="no-wrap mat-body-1 u-text-color_gray-blue">Member since:
          {{ dog["first_check_in"] }}
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <ng-container *ngIf="deviceIsMobile">
          <mat-divider class="white-divider" [inset]="true"> </mat-divider>
        </ng-container>
        <ng-container *ngIf="dog['all_milestones'].length !== 0">
          <app-milestone-banner [milestones]="dog['all_milestones']"
            [completed_milestones]="dog['completed_milestones']" [dogId]="dog['id']" [page]="page">
          </app-milestone-banner>
        </ng-container>
        <ng-container *ngIf="!deviceIsMobile">
          <mat-divider class="white-divider" [inset]="false" [vertical]="true"></mat-divider>
          <app-arrow-text-button isMatButton="true" (click)="viewProfile(i)">
            <div matButtonText>profile</div>
          </app-arrow-text-button>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>