import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { DogSummary } from 'src/app/shared/models/dog-summary';
import { DogService } from 'src/app/core/services/dog.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestStoreService } from 'src/app/core/services/request-store.service';
import { RequestModel } from 'src/app/core/models/request.model';
import { RequestType } from 'src/app/core/enums/request-type.enum';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dog-summary-card',
  templateUrl: './dog-summary-card.component.html',
  styleUrls: ['./dog-summary-card.component.scss']
})
export class DogSummaryCardComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @Input() dogs: DogSummary[];
  @Input() dogId: DogSummary[];
  @Input() page: string;
  deviceIsMobile: boolean;
  userActive: boolean;
  placeholderImage = './../../../../../assets/images/backgrounds/photos/photos_placeholder_mobile.png';

  rookieMilestone: number[] = [1, 2];

  // tslint:disable-next-line: max-line-length
  constructor(
    private dogService: DogService,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    breakpoints: BreakpointObserver,
    private requestStore: RequestStoreService,) {
    breakpoints.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.deviceIsMobile = true;
      } else {
        this.deviceIsMobile = false;
      }
    });

    this.userActive = localStorage.getItem('active') === 'true';
  }

  ngOnInit() {
    if (this.dogId) { // Summary for single dog
      const params = new HttpParams().set('dog_id', this.dogId.toString());
      let requestModel: RequestModel = new RequestModel(
        '/dogs/get_dog_summary',
        RequestType.GET,
        null,
        params
      )
      this.requestStore.addRequest(requestModel);
      this.requestStore.dispatch().subscribe(result => {
        this.dogs = [result[0]['dogs_summary']];
        this.sanitizeImages(this.dogs);
      }, err => {
        console.error('FETCH SINGLE DOG SUMMARY ERROR! ', err);
      });
    } else { // Summaries for all dogs
      let requestModel: RequestModel = new RequestModel(
        '/dogs/get_dogs_summary',
        RequestType.GET,
      )
      this.requestStore.addRequest(requestModel);
      this.requestStore.dispatch().subscribe(result => {
        this.dogs = result[0]['api_dogs'];
        this.sanitizeImages(this.dogs);
      }, err => {
        console.error('FETCH MULTIPLE DOGS SUMMARY ERROR! ', err);
      });
    }
  }

  sanitizeImages(dogs: DogSummary[]) {
    dogs.forEach(dog => {
      if (dog['profile_picture']) {
        dog['profile_picture'] = this.domSanitizer.bypassSecurityTrustStyle(`url(${dog['profile_picture']})`);
      } else {
        dog['profile_picture'] = this.domSanitizer.bypassSecurityTrustStyle(`url(${this.placeholderImage})`);
      }
    });
  }

  viewProfile(index) {
    this
      .googleAnalyticsEvents
      .eventEmitter('dog profile', 'home', 'home', 10);
    // tslint:disable-next-line: radix
    this.router.navigate(['/profiles'], { queryParams: { tabIndex: parseInt(index) } });
  }

  ngOnDestroy() {

  }
}
