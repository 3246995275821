<div class="review-dialog">
  <div class="u-text-center">
    <h1 class="mat-display-1"
      ngClass.lt-sm="font-weight_700"
      ngClass.gt-sm="padding-sides_20">Feel free to leave a
      review
      about our services</h1>
  </div>
  <p class="mat-body-1 gray-blue margin-bottom_20">We appreciate your feedback!</p>
  <a href="https://search.google.com/local/writereview?placeid={{ data.place_id }}"
    target="_blank"
    mat-button
    class="social-button google margin-bottom_10 u-flex-center no-shadow"
    id="remove-cdk"
    (click)="updateReview(data.customer_id,'google')">
    <mat-icon svgIcon="google_icon_2"></mat-icon>
    Leave a review on Google
  </a>
  <a href="{{facebookUrl}}"
    target="_blank"
    mat-button
    class="social-button facebook margin-bottom_30 u-flex-center"
    (click)="updateReview(data.customer_id,'facebook')">
    <mat-icon svgIcon="facebook_icon_2"></mat-icon>
    Leave a review on Facebook
  </a>
  <button mat-raised-button
    (click)="closeDialog(data.customer_id, 'Remind me later')"
    class="margin-bottom_10 box-shadow bddc-dark-blue">REMIND ME
    LATER</button>
  <button mat-raised-button
    class="bddc-dark-blue box-shadow"
    (click)="closeDialog(data.customer_id, 'Dismiss')">DISMISS</button>
</div>