import { Component, OnInit, OnDestroy, AfterViewInit, HostListener, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { CalendarService } from 'src/app/core/services/calendar.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { FullcalendarInterface } from 'src/app/shared/interfaces/fullcalendar.interface';
import { CalendarDate } from 'src/app/shared/models/calendar-date';
import { NotificationMessageComponent } from '../../../notification/notification-message/notification-message.component';
import { CalendarOptions } from '@fullcalendar/core';

@Component({
  selector: 'app-mini-calendar-picker-swap-days-dialog',
  templateUrl: './mini-calendar-picker-swap-days-dialog.component.html',
  styleUrls: ['./mini-calendar-picker-swap-days-dialog.component.scss']
})
export class MiniCalendarPickerSwapDaysDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription;
  calendarData: FullcalendarInterface[];
  fromDate: string;
  dateTitle: string;
  hasService: boolean;
  tomorrowDate: string;
  tableHeaders: any;
  hasData = false;
  domEl: HTMLElement;
  currentDate = moment();
  firstDay: any;
  lastDay: any;
  todaysDateEl: any;
  calendarDateElements: any;
  selectedFromPicker: any;
  columnHeaderFormat = this.getWeekDayFormat();

  @Output() chosenDate: EventEmitter<any> = new EventEmitter();

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    plugins: [
      dayGridPlugin,
      interactionPlugin,
    ],
    initialView: 'dayGridMonth',
    selectable: true,
    timeZone: 'false',
    firstDay: 1,
    eventOverlap: true,
    dayMaxEventRows: 2,
    progressiveEventRendering: true,
    longPressDelay: 0,
    events: [],
    dayHeaderFormat: this.columnHeaderFormat,
    dayHeaderContent: this.getWeekDayText.bind(this),
    selectAllow: this.preventMultipleDates.bind(this),
    eventDidMount: this.displayEventIcon.bind(this),
    dateClick: this.dateSelected.bind(this)
  }

  appLoaderAnimation = './../../../../../assets/images/dogloading.gif';

  // tslint:disable-next-line: max-line-length
  constructor(private dialog: MatDialog, overlayContainer: OverlayContainer, private calendarService: CalendarService, public breakpointObserver: BreakpointObserver, public dialogRef: MatDialogRef<any>) {
    overlayContainer.getContainerElement().classList.add('mini-calendar-dialog_overlay');
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.domEl = event.target;
    if (this.domEl.classList.contains('fc-prev-button') || this.domEl.classList.contains('fc-icon-chevron-left')) {
      this.fetchMonthData('prev');
    } else if (this.domEl.classList.contains('fc-next-button') || this.domEl.classList.contains('fc-icon-chevron-right')) {
      this.fetchMonthData('next');
    }
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
    this.fetchMonthData('current');
  }

  getWeekDayText(date) {
    let day = '';

    if (window.innerWidth > 1130) {
      switch (date.dow) {
        case 1:
          day = 'Monday';
          break;
        case 2:
          day = 'Tuesday';
          break;
        case 3:
          day = 'Wednesday';
          break;
        case 4:
          day = 'Thursday';
          break;
        case 5:
          day = 'Friday';
          break;
        case 6:
          day = 'Saturday';
          break;
        case 0:
          day = 'Sunday';
      }
      return day;
    } else {
      switch (date.dow) {
        case 1:
          day = 'M';
          break;
        case 2:
          day = 'T';
          break;
        case 3:
          day = 'W';
          break;
        case 4:
          day = 'T';
          break;
        case 5:
          day = 'F';
          break;
        case 6:
          day = 'S';
          break;
        case 0:
          day = 'S';
      }
      return day;
    }
  }

  getWeekDayFormat() {
    if (window.innerWidth < 768) {
      return this.columnHeaderFormat = { weekday: 'short' };
    } else {
      return this.columnHeaderFormat = { weekday: 'long' };
    }
  }

  ngAfterViewInit() {
    this.todaysDateEl = document.getElementsByClassName("mini-calendar")[0].querySelector('.fc-day-today');
    this.calendarDateElements = document.getElementsByClassName("mini-calendar")[0].querySelectorAll('.fc-day');
  }

  setMobileLayout() {
    this.dialogRef.updateSize('343px', '501px');
    this.columnHeaderFormat = { weekday: 'short' };
  }

  setDesktopLayout() {
    this.dialogRef.updateSize('816px', '760px');
    this.columnHeaderFormat = { weekday: 'long' };
  }

  fetchMonthData(direction: string) {
    const dateRange = [];
    this.hasData = false;

    if (direction === 'next') {
      this.currentDate = this.currentDate.add(1, 'months');
    } else if (direction === 'prev') {
      this.currentDate = this.currentDate.subtract(1, 'months');
    } else {
      this.currentDate = moment();
    }

    this.firstDay = this.currentDate.startOf('month').format('YYYY-MM-DD');
    this.lastDay = this.currentDate.endOf('month').format('YYYY-MM-DD');
    dateRange.push(this.firstDay, this.lastDay);

    this.subscription = this.calendarService.getCalendarData(dateRange).subscribe(resp => {
      const apiResp: CalendarDate[] = resp['calendar_services']['api_customer_object'];
      const holidayData = resp['holidays_data'];
      this.setCalendarData(apiResp);
      this.setHolidaysCalendarData(holidayData);
    });
  }

  setCalendarData(apiResp: CalendarDate[]) {
    this.calendarData = [];

    apiResp.forEach(dateItem => {
      const CALENDAR_ITEM: FullcalendarInterface = {
        start: '',
        end: ''
      };

      CALENDAR_ITEM.start = dateItem.date;
      CALENDAR_ITEM.end = dateItem.date;
      CALENDAR_ITEM.rendering = 'background';
      CALENDAR_ITEM.title = dateItem.services[0].serviceType;
      CALENDAR_ITEM.services = [];

      if (dateItem.services.length) {
        dateItem.services.forEach(dateItemService => {
          CALENDAR_ITEM.services.push(dateItemService);
          CALENDAR_ITEM.backgroundColor = 'transparent';
        });
      }
      this.calendarData.push(CALENDAR_ITEM);
    });

    this.calendarOptions.events = this.calendarData;

    this.hasData = true;

    // build data for mobile:
    this.buildMobileDataStructure();
  }

  setHolidaysCalendarData(holidays: any) {
    const listOfholidaysToKick = [];

    this.calendarData.forEach(service => {
      holidays.forEach(holiday => {
        const holidayFormattedDate = moment(holiday.date).format('MM-DD').toString();
        const serviceFormattedDate = moment(service.start).format('MM-DD').toString();

        if (holidayFormattedDate === serviceFormattedDate) {
          listOfholidaysToKick.push(holiday);
        }
      });
    });

    holidays = holidays.filter(item => {
      return !listOfholidaysToKick.includes(item);
    });

    holidays.forEach(holiday => {
      this.calendarData.push({
        start: moment(holiday.date).format('YYYY-MM-DD'),
        end: moment(holiday.date).format('YYYY-MM-DD'),
        rendering: 'background',
        title: holiday.holiday_name,
        services: [{ serviceType: holiday.holiday_name, isHoliday: true }],
        backgroundColor: 'transparent'
      });
    });
  }

  buildMobileDataStructure() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.calendarData.forEach(calendarItem => {
          if (calendarItem.rendering === 'background') {
            calendarItem.backgroundColor = 'transparent';
          }
        });
      }
    });
  }

  displayEventIcon(fullCalendarObject: any) {

    const eventDate = fullCalendarObject.event.start;
    const calendarStartDate = fullCalendarObject.view.currentStart;
    const calendarEndDate = fullCalendarObject.view.currentEnd;
    const formattedDate = moment(fullCalendarObject.event.start).format('YYYY-MM-DD').toString();
    const outsideCurrentMonth =
      eventDate <= calendarStartDate || eventDate >= calendarEndDate;
    let services = '';
    let hasRequest = false;
    let notAttending = true;
    const serviceCategoryArray = [];

    const fromDate = moment(this.fromDate);
    const month_from_swapped_from = fromDate.add(2, 'month');

    // Get unique array of service categories
    fullCalendarObject.event.extendedProps.services.forEach(service => {
      // tslint:disable-next-line: max-line-length
      if (!service.isDayCare && !service.isRequest && service.serviceCategory && serviceCategoryArray.indexOf(service.serviceCategory) === -1) {
        serviceCategoryArray.push(service.serviceCategory);
      }
      if (!service['notAttending']) {
        notAttending = false;
      }
      if (service.isRequest || service.pendingSwap || service.pendingCancellation) {
        hasRequest = true;
      }
    });

    // Build the HTML categories list
    for (let i = 0; i < serviceCategoryArray.length; i++) {
      if (i > 1) {
        services += `<span>&#8226; plus ${serviceCategoryArray.length - 2} more</span><br/>`;
      } else {
        services += `<span>&#8226; ${serviceCategoryArray[i]}</span><br/>`;
      }
    }

    if (hasRequest) {
      services += `<span class="calendar-request-bullet">&#8226; Request pending</span><br/>`;
    }

    let servicesForDay = 0;
    fullCalendarObject.event.extendedProps.services.forEach(service => {
      // Inject the HTML categories list for the date
      if (service) {

        let singleServiceDisplay = '';

        if (!service.isDayCare && !service.isRequest) {
          servicesForDay++;
          singleServiceDisplay = `<span class="elipsed-service">&#8226; ${service.serviceType}</span><br/>`;
        }
        if (service.notAttending) {
          fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">•Not attending</div>`;
        }
        if (service.notAttending && outsideCurrentMonth) {
          fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point" style="color: #818181;">•Not attending</div>`;
        }
        if ((service.addhocServiceId !== null && !service.isDayCare) || hasRequest) {
          if (outsideCurrentMonth) {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">${(servicesForDay === 1 && !hasRequest) ? singleServiceDisplay : services}</div>`;
            if (service.isHoliday) {
              // tslint:disable-next-line: max-line-length
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">${fullCalendarObject.event.extendedProps.services[0].serviceType}</div>`;
            }
          } else {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">${(servicesForDay === 1 && !hasRequest) ? singleServiceDisplay : services}</div>`;
            if (service.isHoliday) {
              // tslint:disable-next-line: max-line-length
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">${fullCalendarObject.event.extendedProps.services[0].serviceType}</div>`;
            }
          }
        }
      }
      if (service.swapDay && !service.old_swap_day) {
        if (outsideCurrentMonth) {
          if (!fullCalendarObject.event.extendedProps.services.some(service => service.notAttending)) {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">•Swapped Day</div>`;
          }
        } else {
          if (!fullCalendarObject.event.extendedProps.services.some(service => service.notAttending)) {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">•Swapped Day</div>`;
          }
        }
      }

      // Add holiday CSS classes
      if (service.isHoliday) {
        const elements = document.getElementsByClassName("mini-calendar")[0].getElementsByClassName('fc-daygrid-day');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (outsideCurrentMonth) {
            elements[i].classList.add('holiday-grey');
          } else {
            elements[i].classList.add('holiday');
          }
        }
        return;
      }

      // Add sleepover CSS classes
      if (service.boardingServiceId) {
        const elements = document.getElementsByClassName("mini-calendar")[0].getElementsByClassName('fc-daygrid-day')
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (outsideCurrentMonth) {
            elements[i].classList.add('sleepover-day-grey');
          } else {
            elements[i].classList.add('sleepover-day');
          }

          elements[i].classList.remove('daycare-day');
          elements[i].classList.remove('daycare_day-grey');
        }
        return;
      }

      // Add daycare CSS classes
      if (service.regularServiceId || service.addhocServiceId || hasRequest) {
        const elements = document.getElementsByClassName("mini-calendar")[0].getElementsByClassName('fc-daygrid-day');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (elements[i].classList.contains('sleepover-day')) {
            break;
          }

          if (outsideCurrentMonth) {
            if (!service.old_swap_day) {
              elements[i].classList.add('daycare-day-grey');
            }
          } else {
            elements[i].classList.add('daycare-day');
            if (fullCalendarObject.event.extendedProps.services.every(service => service.old_swap_day)) {
              elements[i].classList.remove('fc-day-future');
              elements[i].classList.add('fc-day-past');
            }
            if (!notAttending) {
              if (outsideCurrentMonth) {
                elements[i].classList.add('daycare-day-grey');
              } else {
                if (moment(service.date).isAfter(month_from_swapped_from)) {
                  elements[i].classList.add('fc-day-past');
                } else {
                  elements[i].classList.add('daycare-day');
                }
              }
            }
            else {
              if (moment(service.date).isAfter(month_from_swapped_from)) {
                elements[i].classList.add('fc-day-past');
              }
            }

            if (service.addhocServiceId) {
              elements[i].classList.add('has-service');
            }

            if (hasRequest) {
              elements[i].classList.add('has-request');
            }
          }
          return;
        }
      }
    });

  }

  dateSelected(dateElement: any) {

    const selectedDay = moment(dateElement.date).endOf('day');
    const dateToday = moment().startOf('day');

    if (dateToday == selectedDay) {
      this.todaysDateEl.classList.remove('no-border');
    } else {
      this.todaysDateEl.classList.add('no-border');
    }


    this.selectedFromPicker = moment(dateElement.date).format('ddd, D MMMM YYYY');

    let holidays = [];

    this.calendarData.forEach(calendarItem => {
      const APIDate = moment(calendarItem.start).format('YYYY-MM-DD');
      const selectedDate = moment(dateElement.date).format('YYYY-MM-DD');

      // If the date selected matches the api data
      if (selectedDate !== APIDate) {
        return;
      } else {
        holidays = calendarItem.services.filter(item => item.isHoliday);
      }
    });

    // If date selected is a holiday
    if (holidays.length > 0) {
      this.dialog.open(NotificationMessageComponent, {
        // tslint:disable-next-line: max-line-length
        data: { heading: 'Holiday date selected', body: 'Can\'t select a holiday date', type: 'warning' }
      });
      return false;
    }

    if (selectedDay < dateToday) {
      this.dialog.open(NotificationMessageComponent, {
        // tslint:disable-next-line: max-line-length
        data: { heading: 'Past date selected', body: 'Can\'t select date in the past', type: 'warning' }
      });
      return false;
    }

    // Check if the selected date falls on a weekend
    if (dateElement.date.getDay() === 6 || dateElement.date.getDay() === 0) {
      this.dialog.open(NotificationMessageComponent, {
        // tslint:disable-next-line: max-line-length
        data: { heading: 'Weekend selected', body: 'Can\'t add a service on a weekend', type: 'warning' }
      });
      return false;
    }

    // If date selected is a month prior to selected month
    if (selectedDay.isBefore(moment(this.fromDate).startOf('month'))) {
      // If date lies within a week of the selected date
      if (selectedDay.isBefore(moment(this.fromDate).subtract(7, 'days'))) {
        this.dialog.open(NotificationMessageComponent, {
          // tslint:disable-next-line: max-line-length
          data: { heading: 'Previous month selected', body: 'Oops, can\'t select this date', type: 'warning' }
        });
        return false;
      }
    }

    // if date selected is 2 months after swap from date 
    const fromDate = moment(this.fromDate);
    const month_after_swapped_from = fromDate.add(2, 'month').clone().add(1, 'day');
    if (selectedDay.isAfter(moment(month_after_swapped_from))) {
      this.dialog.open(NotificationMessageComponent, {
        // tslint:disable-next-line: max-line-length
        data: { heading: 'Invalid date', body: 'You cannot swap beyond two months in the future', type: 'warning' }
      });
      return false;
    }

    // if (window.innerWidth > 959) {
    this.chooseThisDate();
    // }
  }

  chooseThisDate() {
    this.chosenDate.emit(this.selectedFromPicker);
  }

  preventMultipleDates(e) {
    if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400) {
      return true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
