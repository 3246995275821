<div class="account-balance-card">
  <ng-container *ngIf="!hasData">
    <div class="u-flex-center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="hasData">
    <div class="account-balance-card_content" (click)="navigateTo('billing')">
      <div class="account-balance-card_status {{ cardStatus }}"></div>
      <div class="account-balance-card_balance">
        <p class="mat-body-1 u-text-no-margin">
          &pound; {{ accountDetails ? accountDetails.accountBalance : "0.00" }}
        </p>
        <p class="mat-body-1 u-text-no-margin account-status">
          {{
            accountDetails ? accountDetails.accountStatus : "No balance found"
          }}
        </p>
      </div>
    </div>
    <div class="account-balance-card_button" *ngIf="!accountDetails.directDebitEnabled">
      <ng-container *ngIf="fetchingDirectDebitLink">
        <div class="u-flex-center">
          <mat-spinner color="primary" diameter="50"></mat-spinner>
        </div>
      </ng-container>
      <button *ngIf="!fetchingDirectDebitLink" mat-button class="flat-button_round warn" (click)="setupDirectDebit()"
        [disabled]="!userActive">
        <span>Enable direct debit</span>
      </button>
      <mat-icon *ngIf="!fetchingDirectDebitLink" svgIcon="chevron_right" (click)="setupDirectDebit()"></mat-icon>
    </div>
  </ng-container>
</div>