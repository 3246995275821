export const environment = {
  production: true,
  release: 'production',
  apiUrl: 'https://team.bruces.dog/customer_api/v1',
  base: 'https://bookings.brucesdoggydaycare.co.uk/',
  facebook_app_id: '191329791301747',
  social_share_base_url: 'https://team.bruces.dog',
  bug_snag_api_key: 'cf95d085d74a8e5e99c1bba160df5657',
  google_analytics_id: 'G-3F4CBV45LQ'
};
