<app-page-header headerImage="{{ headerImage }}"></app-page-header>
<div class="terms-conditions">
  <div class="terms-conditions_header">
    <h1 class="mat-display-1">Our terms &amp; conditions</h1>
  </div>
  <div class="core-content">
    <div *ngIf="termsandconditions">
      <div [innerHTML]="termsandconditions"></div>
    </div>
    <div class="app-loader" *ngIf="!termsandconditions">
      <div class="u-flex-center">
        <mat-spinner color="primary" diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>
