<div class="sidenav">
  <div class="sidenav_header">
    <a class="sidenav_header-logo" [routerLink]="['/home']" (click)="onSidenavClose()">
      <img img src="{{ logoPath }}" alt="logo" class="logo-img" />
    </a>

    <div class="sidenav_header-close">
      <mat-icon (click)="onSidenavClose()" class="close-icon">close</mat-icon>
    </div>
  </div>

  <div class="sidenav_list">
    <mat-nav-list>
      <a mat-list-item class="mobile-menu_link" [routerLink]="['/home']" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="home_icon"></mat-icon><span class="nav-caption">Home</span>
      </a>
      <a mat-list-item class="mobile-menu_link" [routerLink]="['/services']" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="services_icon"></mat-icon><span class="nav-caption">Bookings</span>
      </a>
      <a mat-list-item class="mobile-menu_link" [routerLink]="['/photos/my-photos']" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="photos_icon"></mat-icon><span class="nav-caption">Photos</span>
      </a>
      <a *ngIf="userActive" mat-list-item class="mobile-menu_link" [routerLink]="['/profiles']"
        routerLinkActive="active-menu-item" [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="profiles_icon"></mat-icon><span class="nav-caption">Profiles</span>
      </a>
      <a mat-list-item class="mobile-menu_link" [routerLink]="['/billing']" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="billing_icon"></mat-icon><span class="nav-caption">Billing</span>
      </a>
      <a mat-list-item class="mobile-menu_link" [routerLink]="['/settings']" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
        <mat-icon svgIcon="settings_icon"></mat-icon><span class="nav-caption">Settings</span>
      </a>
      <a mat-list-item class="mobile-menu_link" routerLinkActive="active-menu-item"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onLogOut()">
        <mat-icon svgIcon="logout_icon"></mat-icon><span class="nav-caption">Log out</span>
      </a>
    </mat-nav-list>
  </div>
  <div class="sidenav_footer">
    <button mat-raised-button class="mat-footer" [routerLink]="['/contact']" routerLinkActive="active-menu-item"
      [routerLinkActiveOptions]="{ exact: true }" (click)="onSidenavClose()">
      Contact Us
    </button>
  </div>
</div>