import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';

@Injectable({
  providedIn: 'root'
})

export class PhotoService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  getPhotos(limit: number): Observable<any[]> {
    const params = new HttpParams().set('limit', limit.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  getGalleryTypes(): Observable<any[]> {
    const API_URL = this.baseApiService.API_URL + '/customer_object/gallery_types';
    return this.http.get<any[]>(API_URL, {});
  }

  getAlbumPhotos(albumId: number, offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('album_id', albumId.toString())
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_album_photos/';
    return this.http.get<any[]>(API_URL, { params });
  }

  getFavouritePhotos(offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_favourite_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  getClassPhotos(offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_class_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  getBestiesPhotos(offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_besties_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  getCustomerPhotos(offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_customer_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  getTaggedDogs(limit: number): Observable<any[]> {
    const params = new HttpParams().set('limit', limit.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_tagged_dogs';
    return this.http.get<any[]>(API_URL, {});
  }

  getCompactAlbums(offsetStart: any, offsetEnd: any): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString());
    const API_URL = this.baseApiService.API_URL + '/customer_object/get_compact_albums';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchAlbums(offsetEnd, offsetStart, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_albums';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchPhotoAlbum(offsetEnd, offsetStart, album_id, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('album_id', album_id.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_photo_album';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchFavouritePhotos(offsetEnd, offsetStart, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_favourite_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchBestiesPhotos(offsetEnd, offsetStart, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_besties_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchClassPhotos(offsetEnd, offsetStart, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_class_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  searchCustomerPhotos(offsetEnd, offsetStart, searchTerm): Observable<any[]> {
    const params = new HttpParams()
      .set('offset_start', offsetStart.toString())
      .set('offset_end', offsetEnd.toString())
      .set('search_term', searchTerm);
    const API_URL = this.baseApiService.API_URL + '/customer_object/search_customer_photos';
    return this.http.get<any[]>(API_URL, { params });
  }

  setPhotoFavourite(photoId: number, isFavourite: boolean): Observable<any[]> {
    const API_URL = this.baseApiService.API_URL + '/customer_object/set_photo_favourite';
    return this.http.post<any[]>(API_URL, { photo_id: photoId, favourite: isFavourite });
  }

  setProfilePic(photoId: number): Observable<any[]> {
    const API_URL = this.baseApiService.API_URL + '/customer_object/set_profile_pic';
    return this.http.post<any[]>(API_URL, { photo_id: photoId });
  }

  setTaggedDogPic(dogObj) {
    const API_URL = this.baseApiService.API_URL + '/customer_object/set_tagged_photo';
    return this.http.post<any[]>(API_URL, { dog_id: dogObj.dog_id, photo_id: dogObj.photo_id });
  }

  downloadFile(path: string): Observable<Blob> {
    return this.http.get(path, { responseType: 'blob' });
  }
}
