<div class="session-dialog">
  <div class="session-dialog_title">
    <h1 class="mat-display-1">{{ data.title }}</h1>
  </div>

  <div class="session-dialog_description">
    <p class="mat-body-1">{{ data.description }}</p>
    <p class="mat-body-2">You will be logged out in {{counter}}</p>
  </div>

  <div mat-dialog-actions class="session-dialog_actions">
    <button mat-raised-button color="primary" (click)="actionByUser('refresh')">
      Stay
    </button>
    <button mat-raised-button class="margin-bottom_20" (click)="actionByUser('logout')">
      Logout
    </button>
  </div>
</div>