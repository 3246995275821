import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
  ViewChild,
  Input
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';

import { CalendarService } from 'src/app/core/services/calendar.service';
import { ServiceCalendarDialogComponent } from './service-calendar-dialog/service-calendar-dialog.component';
import { CalendarEventService } from 'src/app/shared/interfaces/calendar-event-service.interface';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { FullcalendarInterface } from 'src/app/shared/interfaces/fullcalendar.interface';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { CalendarDate } from 'src/app/shared/models/calendar-date';
import { Subscription } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { MiniCalendarPickerSwapDaysDialogComponent } from '../mini-calendar-picker-swap-days/mini-calendar-picker-swap-days-dialog/mini-calendar-picker-swap-days-dialog.component';
import { SwapDayDialogCardComponent } from '../../card/swap-day-dialog-card/swap-day-dialog-card.component';
import { AddServiceDialogComponent } from './add-service-dialog/add-service-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { RequestStoreService } from 'src/app/core/services/request-store.service';
import { RequestModel } from 'src/app/core/models/request.model';
import { RequestType } from 'src/app/core/enums/request-type.enum';
import { HttpParams } from '@angular/common/http';
import { DogService } from 'src/app/core/services/dog.service';
import { DogSummary } from 'src/app/shared/models/dog-summary';
import { CalendarDialogComponent } from '../calendar-dialog/calendar-dialog.component';
import { environment } from 'src/environments/environment';
import { SwapDayCutOffDialogComponent } from 'src/app/swap-day-cut-off-dialog/swap-day-cut-off-dialog.component';

export interface CalendarPanelService {
  id: number;
  status: string;
  dogName: string;
  dogId: number;
  date: string;
  serviceType: string;
  serviceCategory: string;
  canSwap?: boolean;
  pendingSwap: boolean;
  pendingCancellation: boolean;
  swapDay: boolean;
  oldSwap: {
    swapDay: boolean,
    swappedTo: string;
  };
  fiveDaysAWeek: boolean;
  isRequest: boolean;
  requestId: number;
  notAttending: boolean;
  requestType: string;
  refunded: boolean;
  pickupOwnersDrop: boolean;
  dropoffOwnersDrop: boolean;
}
@Component({
  selector: 'app-service-calendar',
  templateUrl: './service-calendar.component.html',
  styleUrls: ['./service-calendar.component.scss']
})
export class ServiceCalendarComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() page: string;
  private calendarDataSub: Subscription;
  calendarData: FullcalendarInterface[];
  dateHasSwapDay = false;
  dateTitle: string;
  tomorrowDate: string;
  tomorrowDateDisplay: string;
  tableHeaders: any;
  selectedDateServices: CalendarPanelService[];
  selectedDateServicesTomorrow: CalendarPanelService[];
  dateHasPendingCancellation: boolean;
  tomorrowHasPendingCancellation: boolean;
  dateHasPendingRequest: boolean;
  tomorrowHasPendingRequest: boolean;
  dateHasPendingSwap: boolean;
  tomorrowHasPendingSwap: boolean;
  displayOnlyForToday = true;
  addServiceButtonEnabled = true;
  hasData = false;
  domEl: HTMLElement;
  currentDate = moment();
  firstDay: any;
  lastDay: any;
  todaysDateEl: any;
  calendarDateElements: any;
  columnHeaderFormat = this.getWeekDayFormat();
  swapsRemaining = 0;
  selectConstraint: boolean;
  selectedDateNotAttending = false;
  notAttendingLegendDisplay = false;
  notAttendingRefundedLegendDisplay = false;
  showGreySwapButton = false;

  futureServiceId: number;
  dogId: number;
  toDate = '';
  fromDate = '';
  addServiceDate: any;
  activeUser: boolean;
  selectDate: boolean;

  dogs: DogSummary[];

  @ViewChild('calendar')
  calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next'
    },
    plugins: [
      dayGridPlugin,
      interactionPlugin,
    ],
    initialView: 'dayGridMonth',
    selectable: true,
    timeZone: 'false',
    firstDay: 1,
    eventOverlap: true,
    dayMaxEventRows: 2,
    progressiveEventRendering: true,
    longPressDelay: 1000,
    unselectAuto: false,
    selectAllow: this.preventMultipleDates.bind(this),
    events: [],
    dayHeaderFormat: this.columnHeaderFormat,
    dayHeaderContent: this.getWeekDayText.bind(this),
    eventDidMount: this.displayEventIcon.bind(this),
    dateClick: this.dateSelected.bind(this)
  }

  appLoaderAnimation = './../../../../../assets/images/dogloading.gif';
  dayIcon = './../../../../../assets/images/icons/sun_icon.svg';
  nightIcon = './../../../../../assets/images/icons/moon_icon.svg';

  customImages = [
    {
      image_name: 'minus_icon',
      image_path: 'assets/images/icons/minus_icon.svg'
    },
    {
      image_name: 'sort_icon',
      image_path: 'assets/images/icons/sort_icon.svg'
    },
    {
      image_name: 'list_icon',
      image_path: 'assets/images/icons/list_icon.svg'
    },
    {
      image_name: 'calendar_icon',
      image_path: 'assets/images/icons/calendar_icon.svg'
    }
  ];

  // tslint:disable-next-line: max-line-length
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver,
    private calendarService: CalendarService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private authService: AuthService,
    private requestStore: RequestStoreService,
    private dogService: DogService,
  ) {

    this.activeUser = localStorage.getItem('active') === 'true';
    this.customImages.forEach(image => {
      this.matIconRegistry.addSvgIcon(
        image.image_name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + image.image_path)
      );
    });
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.domEl = event.target;
    if (
      this.domEl.classList.contains('fc-prev-button') ||
      this.domEl.classList.contains('fc-icon-chevron-left')
    ) {
      this.fetchMonthData('prev');
      this.googleEvent('services calendar', 'calendar previous');
    } else if (
      this.domEl.classList.contains('fc-next-button') ||
      this.domEl.classList.contains('fc-icon-chevron-right')
    ) {
      this.fetchMonthData('next');
      this.googleEvent('services calendar', 'calendar next');
    }
  }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.columnHeaderFormat = { weekday: 'short' };
      } else {
        this.columnHeaderFormat = { weekday: 'long' };
      }
    });
    this.fetchMonthData('current');
    this.addServiceDate = moment().format('YYYY-MM-DD');
    this.dogService.getDogsSummary().subscribe(result => {
      this.dogs = result['api_dogs'];
      if (this.dogs.length > 0) {
        this.determineShowGreySwapButton();
      }
    }, err => {
      console.error('FETCH MULTIPLE DOGS SUMMARY ERROR! ', err);
    });
  }
  determineShowGreySwapButton() {
    this.showGreySwapButton = !this.dogs[0]['can_swap']
  }

  ngAfterViewInit() {
    this.todaysDateEl = document.getElementsByClassName('fc-theme-standard')[0].querySelector('.fc-day-today');
    this.calendarDateElements = document.getElementsByClassName('fc-theme-standard')[0].querySelectorAll('.fc-day');
  }

  fetchMonthData(direction: string) {
    const dateRange = [];
    this.hasData = false;
    if (direction === 'next') {
      this.selectDate = false;
      this.currentDate = this.currentDate.add(1, 'months');
    } else if (direction === 'prev') {
      this.selectDate = false;
      this.currentDate = this.currentDate.subtract(1, 'months');
    } else if (direction === 'current') {
      this.selectDate = true;
      // Determine if initial load is services page and adjust selected date to be used for API call accordingly
      if ((!this.page || this.page !== 'home') && (!this.page || this.page !== 'services') && direction === 'current' && localStorage.getItem('selected_date') !== 'undefined') {
        this.currentDate = moment(localStorage.getItem('selected_date'), 'YYYY-MM-DD');
      } else {
        this.currentDate = moment();
      }
    } else {
      this.selectDate = true;
      this.currentDate = moment(direction, 'YYYY-MM-DD');
    }

    this.firstDay = this.currentDate.startOf('month').format('YYYY-MM-DD');
    this.lastDay = this.currentDate.endOf('month').format('YYYY-MM-DD');
    dateRange.push(this.firstDay, this.lastDay);

    const params = new HttpParams()
      .set('start_date', dateRange[0])
      .set('end_date', dateRange[1]);

    this.requestStore.addRequest(new RequestModel(
      '/customer_object/calendar_initialize',
      RequestType.GET,
      null,
      params,
    ));

    this.requestStore.dispatch().subscribe(
      resp => {
        const apiResp: CalendarDate[] = resp[0]['calendar_services']['api_customer_object'];
        const holidayData = resp[0]['holidays_data'];
        if (apiResp) {
          this.setCalendarData(apiResp);
          this.setHolidaysCalendarData(holidayData);
          this.generateSelectedDateServices();
        } else {
          this.hasData = true;
        }
      },
      err => {
        this.hasData = true;
        this.dialog.open(NotificationMessageComponent, {
          // tslint:disable-next-line: max-line-length
          data: {
            heading: 'Something went wrong our side!',
            body:
              'Please contact Bruce\'s and ask for assistance',
            type: 'danger'
          }
        });
      }
    );
  }

  setCalendarData(apiResp: CalendarDate[]) {
    this.calendarData = [];
    apiResp.forEach(dateItem => {
      const CALENDAR_ITEM: FullcalendarInterface = {
        start: '',
        end: ''
      };

      CALENDAR_ITEM.start = dateItem.date;
      CALENDAR_ITEM.end = dateItem.date;
      CALENDAR_ITEM.rendering = 'background';
      CALENDAR_ITEM.title = dateItem.services[0].serviceType;
      CALENDAR_ITEM.services = [];

      if (dateItem.services.length) {
        dateItem.services.forEach(dateItemService => {
          CALENDAR_ITEM.services.push(dateItemService);
          CALENDAR_ITEM.backgroundColor = 'transparent';
        });
      }

      this.calendarData.push(CALENDAR_ITEM);
    });
    this.hasData = true;

    this.calendarOptions.events = this.calendarData;

    // build data for mobile:
    this.buildMobileDataStructure();
  }

  setHolidaysCalendarData(holidays: any) {
    const listOfholidaysToKick = [];

    this.calendarData.forEach(service => {
      holidays.forEach(holiday => {
        const holidayFormattedDate = moment(holiday.date).format('MM-DD').toString();
        const serviceFormattedDate = moment(service.start).format('MM-DD').toString();

        if (holidayFormattedDate === serviceFormattedDate) {
          listOfholidaysToKick.push(holiday);
        }
      });
    });

    holidays = holidays.filter(item => {
      return !listOfholidaysToKick.includes(item);
    });

    holidays.forEach(holiday => {
      this.calendarData.push({
        start: moment(holiday.date).format('YYYY-MM-DD'),
        end: moment(holiday.date).format('YYYY-MM-DD'),
        rendering: 'background',
        title: holiday.holiday_name,
        services: [{ serviceType: holiday.holiday_name, isHoliday: true }],
        backgroundColor: 'transparent'
      });
    });
  }

  buildMobileDataStructure() {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.calendarData.forEach(calendarItem => {
          if (calendarItem.rendering === 'background') {
            calendarItem.backgroundColor = 'transparent';
          }
        });
      }
    });
  }

  preSelectDate() {
    let dateElement;
    const calendarApi = this.calendarComponent.getApi();

    if (calendarApi != null) {
      if ((!this.page || this.page !== 'home') && (!this.page || this.page !== 'services') && this.selectDate) {
        dateElement = localStorage.getItem('selected_date');
        calendarApi.gotoDate(dateElement);
        const selectedDate = moment(dateElement).format('YYYY-MM-DD');
        this.setGlobalDate(selectedDate);
        calendarApi.select(dateElement);

        this.calendarData.forEach(calendarItem => {
          const APIDate = moment(calendarItem.start).format('YYYY-MM-DD');
          // If the date selected matches the api data
          if (selectedDate !== APIDate) {
            return;
          } else {
            this.selectedDateServices = this.setServices(calendarItem, false);
          }
        });

        this.todaysDateEl.classList.add('no-border');
        this.dateTitle = moment(dateElement).format('ddd, D MMMM YYYY');
      } else {
        const today = moment().format('YYYY-MM-DD');

        // this.calendarComponent.options.initialDate = today
        calendarApi.select(today);
        this.dateTitle = moment(today).format('ddd, D MMMM YYYY');
      }
    }
  }

  generateSelectedDateServices() {
    const date = moment().format('YYYY-MM-DD');
    this.dateTitle = moment().format('ddd, D MMMM YYYY');
    this.tomorrowDate = moment()
      .add(1, 'day')
      .format('YYYY-MM-DD');
    this.tomorrowDateDisplay = moment()
      .add(1, 'day')
      .format('ddd, D MMMM YYYY');
    this.selectedDateServices = [];
    this.selectedDateServicesTomorrow = [];

    this.resetPendingStatusses();

    this.calendarData.forEach(calendarItem => {
      const thisDate = moment(calendarItem.start).format('YYYY-MM-DD');
      if (thisDate === date && calendarItem.services) {
        this.selectedDateServices = this.setServices(calendarItem, false);
      } else if (
        thisDate ===
        moment()
          .add(1, 'day')
          .format('YYYY-MM-DD') &&
        calendarItem.services
      ) {
        this.selectedDateServicesTomorrow = this.setServices(
          calendarItem,
          true
        );
      }
    });
    this.preSelectDate();
  }

  displayEventIcon(fullCalendarObject: any) {
    const eventDate = fullCalendarObject.event.start;
    const calendarStartDate = fullCalendarObject.view.currentStart;
    const calendarEndDate = fullCalendarObject.view.currentEnd;
    const formattedDate = moment(fullCalendarObject.event.start)
      .format('YYYY-MM-DD')
      .toString();
    const outsideCurrentMonth =
      eventDate < calendarStartDate || eventDate >= calendarEndDate;
    let services = '';
    let hasRequest = false;
    let notAttending = true;
    const serviceCategoryArray = [];

    // Get unique array of service categories
    fullCalendarObject.event.extendedProps.services.forEach(service => {
      if (
        !service.isDayCare &&
        !service.isRequest &&
        service.serviceCategory &&
        serviceCategoryArray.indexOf(service.serviceCategory) === -1
      ) {
        serviceCategoryArray.push(service.serviceCategory);
      }

      if (
        service.isRequest ||
        service.pendingSwap ||
        service.pendingCancellation
      ) {
        hasRequest = true;
      }
      if (!service['notAttending']) {
        notAttending = false;
      }
    });

    // Build the HTML categories list
    for (let i = 0; i < serviceCategoryArray.length; i++) {
      if (i > 1) {
        services += `<span>&#8226; plus ${serviceCategoryArray.length -
          2} more</span><br/>`;
      } else {
        services += `<span>&#8226; ${serviceCategoryArray[i]}</span><br/>`;
      }
    }

    if (hasRequest) {
      services += `<span class="calendar-request-bullet">&#8226; Request pending</span><br/>`;
    }

    let servicesForDay = 0;
    fullCalendarObject.event.extendedProps.services.forEach(service => {
      // Inject the HTML categories list for the date
      if (service) {
        let singleServiceDisplay = '';
        if (!service.isDayCare && !service.isRequest) {
          servicesForDay++;
          singleServiceDisplay = `<span class="elipsed-service">&#8226; ${service.serviceType}</span><br/>`;
        }
        if (service.notAttending) {
          fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">•Not attending</div>`;
        }
        if (service.notAttending && outsideCurrentMonth) {
          fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point" style="color: #818181;">•Not attending</div>`;
        }
        if (
          (service.addhocServiceId !== null && !service.isDayCare) ||
          hasRequest
        ) {
          if (outsideCurrentMonth) {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">${(servicesForDay === 1 && !hasRequest) ? singleServiceDisplay : services}</div>`;
            if (service.isHoliday) {
              // tslint:disable-next-line: max-line-length
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">${fullCalendarObject.event.extendedProps.services[0].serviceType}</div>`;
            }
          } else {
            fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">${(servicesForDay === 1 && !hasRequest) ? singleServiceDisplay : services}</div>`;
            if (service.isHoliday) {
              // tslint:disable-next-line: max-line-length
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">${fullCalendarObject.event.extendedProps.services[0].serviceType}</div>`;
            }
          }
        }
        if (service.swapDay && !service.old_swap_day) {
          if (outsideCurrentMonth) {
            if (!fullCalendarObject.event.extendedProps.services.some(service => service.notAttending)) {
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point grey">•Swapped Day</div>`;
            }
          } else {
            if (!fullCalendarObject.event.extendedProps.services.some(service => service.notAttending)) {
              fullCalendarObject.el.innerHTML = `<div class="bddc-calendar_bullet-point">•Swapped Day</div>`;
            }
          }
        }
      }

      // Add holiday CSS classes
      if (service.isHoliday) {
        const elements = document.getElementsByClassName('fc-theme-standard')[0].getElementsByClassName('fc-daygrid-day');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (outsideCurrentMonth) {
            elements[i].classList.add('holiday-grey');
          } else {
            elements[i].classList.add('holiday');
          }
        }
        return;
      }

      // Add sleepover CSS classes
      if (service.boardingServiceId) {
        const elements = document.getElementsByClassName('fc-theme-standard')[0].getElementsByClassName('fc-daygrid-day');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (outsideCurrentMonth) {
            elements[i].classList.add('sleepover-day-grey');
          } else {
            elements[i].classList.add('sleepover-day');
          }

          elements[i].classList.remove('daycare-day');
          elements[i].classList.remove('daycare_day-grey');
        }
        return;
      }

      // Add daycare CSS classes
      if (service.regularServiceId || service.addhocServiceId || hasRequest) {
        const elements = document.getElementsByClassName('fc-theme-standard')[0].getElementsByClassName('fc-daygrid-day');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].getAttribute('data-date') !== formattedDate) {
            continue;
          }

          if (elements[i].classList.contains('sleepover-day')) {
            break;
          }

          if (outsideCurrentMonth) {
            if (!service.old_swap_day) {
              elements[i].classList.add('daycare-day-grey');
            }
          } else {
            elements[i].classList.add('daycare-day');
            if (fullCalendarObject.event.extendedProps.services.every(service => service.old_swap_day)) {
              elements[i].classList.remove('fc-day-future');
              elements[i].classList.add('fc-day-past');
            }
            if (!notAttending) {
              if (outsideCurrentMonth) {
                elements[i].classList.add('daycare-day-grey');
              } else {
                elements[i].classList.add('daycare-day');
              }
            }
            if (notAttending) {
              elements[i].classList.remove('fc-day-future');
              elements[i].classList.add('fc-day-past');
            }
            if (service.addhocServiceId) {
              elements[i].classList.add('has-service');
            }

            if (hasRequest) {
              elements[i].classList.add('has-request');
            }
          }
          return;
        }
      }
    });
  }

  dateSelected(dateElement: any) {
    this.notAttendingLegendDisplay = false;
    this.notAttendingRefundedLegendDisplay = false;
    if (dateElement.end) {
      this.selectConstraint = false;
      const timeComparison =
        dateElement.end.getTime() / 1000 - dateElement.start.getTime() / 1000;
      if (timeComparison !== 86400) {
        this.selectConstraint = true;
      }
    } else {
      // Programmatically set selected date on click to handle swipe/touch on mobile devices
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.select(dateElement.date);

      const selectedDate = moment(dateElement.date).format('YYYY-MM-DD');
      const today = moment().format('YYYY-MM-DD');
      let holidays = [];

      this.todaysDateEl.classList.add('no-border');
      this.dateTitle = moment(dateElement.date).format('ddd, D MMMM YYYY');
      this.setGlobalDate(selectedDate);
      this.addServiceDate = selectedDate;
      this.displayOnlyForToday = false;
      this.addServiceButtonEnabled = true;
      this.selectedDateServices = [];

      this.resetPendingStatusses();

      // Reset tomorrow date to remove those services from panel if date selected is not today
      if (selectedDate === today) {
        this.tomorrowDate = moment()
          .add(1, 'day')
          .format('YYYY-MM-DD');
      } else {
        this.tomorrowDate = null;
      }

      this.calendarData.forEach(calendarItem => {
        const APIDate = moment(calendarItem.start).format('YYYY-MM-DD');

        // If the date selected matches the api data
        if (selectedDate !== APIDate) {
          return;
        } else {
          this.selectedDateServices = this.setServices(calendarItem, false);
          holidays = calendarItem.services.filter(item => item.isHoliday);
        }
      });

      // Disable button for past dates and weekends
      if (today > selectedDate || [0, 6].includes(dateElement.date.getDay()) || holidays.length > 0) {
        this.addServiceButtonEnabled = false;
      }

      this.googleEvent('services calendar', 'date selected');
    }
  }

  setGlobalDate(paramDate) {
    localStorage.setItem('selected_date', paramDate);
  }

  addService() {
    this.googleEvent('services calendar', 'add service');
    this.dialog.open(AddServiceDialogComponent, {
      data: {
        date: this.addServiceDate.toString()
      }
    });
  }

  swapService(service: any) {
    let selectedDog = this.dogs.find(dog => service.dogId == dog['id']);

    let displaySwapModal = false;

    if (selectedDog != null) {
      // If customer can swap, don't display the modal and vise versa
      displaySwapModal = !selectedDog['can_swap']
    }

    if (displaySwapModal) {
      const config = new MatDialogConfig();
      config.data = {
        body: 'Upgrade to 2 regular days per week to enable swaps',
      }

      let dialogRef = this.dialog.open(CalendarDialogComponent, config,);

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'add service') {
          this.router.navigate(['/services/regular-services']);
        }
      })
    } else {
      this.calendarService
        .swapCutOffReached(service.id)
        .subscribe(swapCutOffResponse => {
          if (swapCutOffResponse['valid']) {

            this.fromDate = service.date;
            this.dogId = service.dogId;
            this.futureServiceId = service.id;

            if (swapCutOffResponse['cut_off_reached']) {
              const swapDayCutoffDialog = this.dialog.open(SwapDayCutOffDialogComponent, {
                data: {
                  message: swapCutOffResponse['message'],
                }
              });

              swapDayCutoffDialog.componentInstance.confirm.subscribe(
                dialogResponse => {
                  if (dialogResponse) {
                    this.googleEvent('services calendar', 'cancel service, cut off reached');

                    // Cancel the day
                    this.requestStore.addRequest(new RequestModel(
                      '/dogs/service_cancellation_request',
                      RequestType.POST,
                      { future_service_id: service.id },
                    ));

                    this.requestStore.dispatch().subscribe(
                      resp => {
                        this.fetchMonthData(service.date);

                        // Update the calendar event itself as well
                        this.updateCalendarEvent(service.id);

                        this.dialog.open(NotificationMessageComponent, {
                          data: {
                            heading: 'Success!',
                            body: resp[0].html_message,
                            type: 'success'
                          }
                        });

                        this.fetchMonthData(this.fromDate);
                        swapDayCutoffDialog.close();
                      },
                      _ => {
                        this.dialog.open(NotificationMessageComponent, {
                          // tslint:disable-next-line: max-line-length
                          data: {
                            heading: 'Something went wrong our side!',
                            body:
                              'Please contact Bruce\'s and ask for assistance',
                            type: 'danger'
                          }
                        });
                        swapDayCutoffDialog.close();
                      }
                    );
                  }
                })
            }
            else {
              const dialogRef = this.dialog.open(
                MiniCalendarPickerSwapDaysDialogComponent
              );
              dialogRef.componentInstance.fromDate = this.fromDate;
              dialogRef.componentInstance.chosenDate.subscribe(result => {
                this.calendarService
                  .isSwapValid(this.dogId, result, this.fromDate)
                  .subscribe(response => {
                    if (response['valid']) {
                      dialogRef.close();
                      if (response['has_service']) {
                        this.dialog.open(NotificationMessageComponent, {
                          data: {
                            heading: 'Service already present',
                            body: 'Service already present on this date',
                            type: 'warning'
                          }
                        });
                      }
                      else {
                        const swapDayDialog = this.dialog.open(SwapDayDialogCardComponent, {
                          data: {
                            dateFrom: moment(this.fromDate).format('ddd, D MMMM YYYY'),
                            dateTo: result
                          }
                        });
                        swapDayDialog.componentInstance.confirm.subscribe(
                          dialogResponse => {

                            if (dialogResponse) {
                              this.calendarService
                                .requestSwap(this.futureServiceId, result, this.fromDate)
                                .subscribe(
                                  swapResponse => {
                                    if (swapResponse['valid']) {
                                      this.googleEvent(
                                        'services calendar',
                                        'submit swap day request'
                                      );

                                      this.dialog.open(NotificationMessageComponent, {
                                        data: {
                                          heading: swapResponse['header'],
                                          body: swapResponse['validation_error'],
                                          type: 'success'
                                        }
                                      });

                                      this.fetchMonthData(this.fromDate);

                                      swapDayDialog.close();
                                    } else {
                                      this.dialog.open(NotificationMessageComponent, {
                                        // tslint:disable-next-line: max-line-length
                                        data: {
                                          heading: 'Oops!',
                                          body: response['validation_error'],
                                          type: 'warning'
                                        }
                                      });
                                      swapDayDialog.close();
                                    }
                                  },
                                  err => {
                                    this.dialog.open(NotificationMessageComponent, {
                                      // tslint:disable-next-line: max-line-length
                                      data: {
                                        heading: 'Something went wrong our side!',
                                        body:
                                          'Please contact Bruce\'s and ask for assistance',
                                        type: 'danger'
                                      }
                                    });
                                  }
                                );
                            }
                          }
                        );
                      }
                    } else {
                      this.dialog.open(NotificationMessageComponent, {
                        // tslint:disable-next-line: max-line-length
                        data: {
                          heading: 'Oops!',
                          body: response['validation_error'],
                          type: 'warning'
                        }
                      });
                    }
                  });
              });
            }
          }
          else {
            this.dialog.open(NotificationMessageComponent, {
              // tslint:disable-next-line: max-line-length
              data: {
                heading: 'Oops!',
                body: swapCutOffResponse['message'],
                type: 'warning'
              }
            });
          }
        })
    }
  }

  googleEvent(section, cardTitle) {
    this.googleAnalyticsEvents.eventEmitter(
      cardTitle,
      'home - ' + section,
      'home',
      10
    );
  }

  remove(service: CalendarEventService) {
    if (service.id) {
      this.removeService(service);
    }

    if (service.requestId) {
      this.removeRequest(service);
    }
  }

  removeService(service: CalendarEventService) {

    // Check validity of cancellation
    this.requestStore.addRequest(new RequestModel(
      `/services/check_cancellation_validity?future_service_id=${service.id}`,
      RequestType.GET,
    ));

    this.hasData = false;

    this.requestStore.dispatch().subscribe((resp) => {
      const canCancelService = resp[0]['is_valid'];
      const message = resp[0]['message'];
      const warning = resp[0]['warning'];
      this.hasData = true;

      // Check server response
      if (canCancelService) {
        // Display a modal with the body text from the API
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
          id: service.id,
          title: 'Cancel service?',
          description:
            message,
          warning: warning,
          confirmButton: 'Yes, Cancel',
          cancelButton: 'Back'
        };

        this.googleEvent('services calendar', 'remove service');

        const dialogRef = this.dialog.open(
          ServiceCalendarDialogComponent,
          dialogConfig
        );

        // If user clicks yes, submit the canellation request
        dialogRef.componentInstance.confirmRemove.subscribe(result => {
          if (result === true) {
            this.googleEvent('services calendar', 'yes, cancel service');

            // Cancel the day
            this.requestStore.addRequest(new RequestModel(
              '/dogs/service_cancellation_request',
              RequestType.POST,
              { future_service_id: service.id },
            ));

            this.requestStore.dispatch().subscribe(
              resp => {
                this.fetchMonthData(service.date);

                // Update the calendar event itself as well
                this.updateCalendarEvent(service.id);

                this.dialog.open(NotificationMessageComponent, {
                  data: {
                    heading: 'Success!',
                    body: resp[0].html_message,
                    type: 'success'
                  }
                });
              },
              _ => {
                this.dialog.open(NotificationMessageComponent, {
                  // tslint:disable-next-line: max-line-length
                  data: {
                    heading: 'Something went wrong our side!',
                    body:
                      'Please contact Bruce\'s and ask for assistance',
                    type: 'danger'
                  }
                });
              }
            );
          }
        });
      } else {
        this.dialog.open(NotificationMessageComponent, {
          data: {
            heading: 'Unable to cancel day',
            body: message,
            type: 'danger'
          }
        });
      }
    }, (err) => {
      const message = err['message'];

      this.dialog.open(NotificationMessageComponent, {
        data: {
          heading: 'Something went wrong our side!',
          body: message,
          type: 'danger'
        }
      });
    });
  }

  removeRequest(service: CalendarEventService) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: service.id,
      title: 'Cancel this request?',
      description:
        ' Are you sure you want to cancel this request? This will not affect your regular booking.',
      confirmButton: 'Yes, Cancel',
      cancelButton: 'Back'
    };

    this.googleEvent('services calendar', 'cancel request');

    const dialogRef = this.dialog.open(
      ServiceCalendarDialogComponent,
      dialogConfig
    );
    dialogRef.componentInstance.confirmRemove.subscribe(result => {
      if (result === true) {
        this.googleEvent('services calendar', 'yes, cancel request');

        const params = { request_id: service.requestId, request_type: service.requestType }

        this.requestStore.addRequest(new RequestModel(
          '/dogs/remove_request',
          RequestType.POST,
          params,
        ));

        this.requestStore.dispatch().subscribe(
          resp => {
            this.fetchMonthData(service.date);

            this.dialog.open(NotificationMessageComponent, {
              data: {
                heading: 'Success!',
                body: resp[0].message,
                type: 'success'
              }
            });
          },
          err => {
            this.dialog.open(NotificationMessageComponent, {
              // tslint:disable-next-line: max-line-length
              data: {
                heading: 'Something went wrong our side!',
                body:
                  'Please contact Bruce\'s and ask for assistance',
                type: 'danger'
              }
            });
          }
        );
      }
    });
  }

  updateCalendarEvent(id: number) {
    this.calendarData.map(event => {
      event.services.map(service => {
        if (service.futureServiceId === id) {
          service.pendingCancellation = true;
        }
      });
    });
  }

  getWeekDayText(date) {
    let day = '';

    if (window.innerWidth > 1130) {
      switch (date.dow) {
        case 1:
          day = 'Monday';
          break;
        case 2:
          day = 'Tuesday';
          break;
        case 3:
          day = 'Wednesday';
          break;
        case 4:
          day = 'Thursday';
          break;
        case 5:
          day = 'Friday';
          break;
        case 6:
          day = 'Saturday';
          break;
        case 0:
          day = 'Sunday';
      }
      return day;
    } else {
      switch (date.dow) {
        case 1:
          day = 'M';
          break;
        case 2:
          day = 'T';
          break;
        case 3:
          day = 'W';
          break;
        case 4:
          day = 'T';
          break;
        case 5:
          day = 'F';
          break;
        case 6:
          day = 'S';
          break;
        case 0:
          day = 'S';
      }
      return day;
    }
  }

  getWeekDayFormat() {
    if (window.innerWidth < 768) {
      return (this.columnHeaderFormat = { weekday: 'short' });
    } else {
      return (this.columnHeaderFormat = { weekday: 'long' });
    }
  }

  preventMultipleDates(e) {
    if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400) {
      return true;
    }
  }

  setServices(calendarItem, tomorrow) {
    const services = [];
    this.dateHasSwapDay = false;
    this.dateHasPendingCancellation = !tomorrow
      ? false
      : this.dateHasPendingCancellation;
    this.tomorrowHasPendingCancellation = tomorrow
      ? false
      : this.tomorrowHasPendingCancellation;
    this.dateHasPendingRequest = !tomorrow ? false : this.dateHasPendingRequest;
    this.tomorrowHasPendingRequest = tomorrow
      ? false
      : this.tomorrowHasPendingRequest;
    this.dateHasPendingSwap = !tomorrow ? false : this.dateHasPendingSwap;
    this.tomorrowHasPendingSwap = tomorrow
      ? false
      : this.tomorrowHasPendingSwap;
    calendarItem.services.forEach(service => {
      const tempObj: CalendarPanelService = {
        id: 0,
        status: '',
        dogName: '',
        dogId: 0,
        date: '',
        serviceType: '',
        serviceCategory: '',
        pendingCancellation: false,
        pendingSwap: false,
        swapDay: false,
        oldSwap: {
          swapDay: false,
          swappedTo: ''
        },
        canSwap: false,
        fiveDaysAWeek: false,
        isRequest: false,
        requestId: 0,
        requestType: '',
        notAttending: false,
        refunded: false,
        pickupOwnersDrop: false,
        dropoffOwnersDrop: false
      };

      if (service.swapDay) {
        if (!service.old_swap_day) {
          tempObj.swapDay = service.swapDay;
          this.dateHasSwapDay = true;
        }
      }

      // Flag the service on date as an old swap day
      if (service.old_swap_day) {
        tempObj.oldSwap.swapDay = true;
        tempObj.oldSwap.swappedTo = service.swappedTo;
      }

      tempObj.id = service.futureServiceId;
      if (!service.serviceCategory) {
        tempObj.status = 'yellow';
      } else if (service.serviceCategory) {
        tempObj.status = 'purple';
      }
      tempObj.fiveDaysAWeek = service.fiveDaysAWeek;
      tempObj.pendingSwap = service.pendingSwap;
      tempObj.date = service.date;
      tempObj.dogId = service.dogId;
      tempObj.canSwap = this.canSwapCheck(service);
      tempObj.dogName = service.dogName;
      tempObj.serviceType = service.serviceType;
      tempObj.serviceCategory = service.serviceCategory;
      tempObj.pendingCancellation = service.pendingCancellation;
      tempObj.isRequest = service.isRequest;
      tempObj.requestId = service.requestId;
      tempObj.requestType = service.requestType;
      tempObj.notAttending = service['notAttending'];
      tempObj.refunded = service.refunded;
      tempObj.pickupOwnersDrop = service.pickupOwnersDrop;
      tempObj.dropoffOwnersDrop = service.dropoffOwnersDrop;
      services.push(tempObj);

      // Flag this date as having at least one pending cancellation request (any date except tomorrow)
      if (
        !tomorrow &&
        service.pendingCancellation === true &&
        !this.dateHasPendingCancellation
      ) {
        this.dateHasPendingCancellation = true;
      }

      // Flag this date as having at least one pending cancellation request (for tomorrow)
      if (
        tomorrow &&
        service.pendingCancellation === true &&
        !this.tomorrowHasPendingCancellation
      ) {
        this.tomorrowHasPendingCancellation = true;
      }

      // Flag this date as having at least one pending request (any date except tomorrow)
      if (
        !tomorrow &&
        service.isRequest === true &&
        !this.dateHasPendingRequest
      ) {
        this.dateHasPendingRequest = true;
      }

      // Flag this date as having at least one pending request (for tomorrow)
      if (
        tomorrow &&
        service.isRequest === true &&
        !this.tomorrowHasPendingRequest
      ) {
        this.tomorrowHasPendingRequest = true;
      }

      // Flag this date as having at least one pending swap day (any date except tomorrow)
      if (!tomorrow && service.pendingSwap && !this.dateHasPendingSwap) {
        this.dateHasPendingSwap = true;
      }

      // Flag this date as having at least one pending swap day (for tomorrow)
      if (tomorrow && !this.tomorrowHasPendingSwap) {
        this.tomorrowHasPendingSwap = true;
      }
    });
    this.selectedDateNotAttending = true;
    this.notAttendingLegendDisplay = false;
    services.forEach(service => {
      if (service) {
        if (service.notAttending && service.refunded) {
          this.notAttendingRefundedLegendDisplay = true;
        }
        if (service.notAttending && !service.refunded) {
          this.notAttendingLegendDisplay = true;
        }
        if (!service.notAttending) {
          this.selectedDateNotAttending = false;
        }
      }
    });
    return services.sort((a, b) => (a.serviceType > b.serviceType ? 1 : -1));
  }

  resetPendingStatusses() {
    this.dateHasPendingCancellation = false;
    this.tomorrowHasPendingCancellation = false;
    this.dateHasPendingRequest = false;
    this.tomorrowHasPendingRequest = false;
    this.dateHasPendingSwap = false;
    this.tomorrowHasPendingSwap = false;
    this.dateHasSwapDay = false;
  }

  canSwapCheck(service) {

    let expiryDatePast = moment().subtract(2, 'months');
    let expiryDateFuture = moment().add(2, 'months');

    // Only dates not older than 2 months or not more than 2 months in the future can be swapped
    let validDate = moment(service.date).isBetween(expiryDatePast, expiryDateFuture);

    let futureDateNotRefunded = moment(service.date) > moment() && !service.refunded;
    let notAttendingNotRefunded = service.notAttending && !service.refunded;
    return service.swapsRemaining > 0 && validDate && (futureDateNotRefunded || notAttendingNotRefunded);
  }

  ngOnDestroy() {

  }
}
