<div class="mini-calendar-picker-dialog">
  <div class="mini-calendar-picker-dialog_heading">
    <h2 class="mat-display-3 margin_none-override">
      Choose a swap <b>to</b> date
    </h2>
    <mat-icon (click)="closeDialog()"
      class="close-icon"
      color="light-blue">close</mat-icon>
  </div>

  <div class="mini-calendar-picker-dialog_wrapper">
    <div class="app-loader"
      *ngIf="!hasData">
      <div class="u-flex-center">
        <mat-spinner color="primary"
          diameter="50"></mat-spinner>
      </div>
    </div>
    <full-calendar #calendar
      [options]="calendarOptions"
      class="mini-calendar"></full-calendar>
  </div>
  <div class="mini-calendar-picker-dialog_action">
    <button mat-raised-button
      color="primary"
      class="longer-button"
      (click)="chooseThisDate()">
      Choose swap to date
    </button>
  </div>
</div>