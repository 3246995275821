import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DogService } from 'src/app/core/services/dog.service';
import { Title } from '@angular/platform-browser';

export interface MilestoneStep {
  id: number;
  icon: string;
  title: string;
  description: string;
  active: boolean;
}

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss']
})
export class MilestonesComponent implements OnInit {
  @Input() id;
  milestones: MilestoneStep[];
  milestoneAPIData = [];
  hasActiveStep = false;
  step = 0;
  hasData = false;
  dogId: string;

  appLoaderAnimation = './../../../../../../assets/images/dogloading.gif';

  constructor(private dogService: DogService, private route: ActivatedRoute, private title: Title) { }

  ngOnInit() {
    this.title.setTitle('Bruce\'s | Milestones');
    this.milestones = [];
    this.assembleMilestones();
  }

  assembleMilestones() {
    this.dogId = this.route.snapshot.queryParamMap.get('dogId');
    this.dogService.getDogSummary(this.dogId).subscribe({
      next: resp => {
        // tslint:disable-next-line: no-string-literal
        this.milestoneAPIData = resp['dogs_summary'];
        this.milestoneAPIData['all_milestones'].forEach((milestone, index) => {
          const tempObj: MilestoneStep = {
            id: index,
            icon: milestone.badge_image ? milestone.badge_image : 'icon missing',
            title: milestone.internal_milestone_name ? milestone.internal_milestone_name : 'title missing',
            description: milestone.customer_milestone_description ? milestone.customer_milestone_description : 'desc missing',
            active: resp['dogs_summary']['completed_milestones'].includes(milestone.id)
          };
          this.milestones.push(tempObj);
          this.hasData = true;
        });
      },
      error: error => {
        console.warn('GET DOG MILESTONES ERROR! ', error);
      },
      complete: () => {
        this.scrollToMilestone(this.route.snapshot.queryParamMap.get('milestoneId'));
      }
    });
  }

  scrollToMilestone(id) {
    if (id) {
      setTimeout(() => {
        document.getElementById('milestone_' + id).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      }, 100);
    }
  }

  setStep(indexNUmber: number) {
    this.step = indexNUmber;
  }

}
