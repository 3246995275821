import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/core/helpers/auth.guard';

import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { RegisterComponent } from './core/register/register.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { LoginComponent } from './core/login/login.component';

import { MainComponent } from './modules/main/main.component';
import { TermsComponent } from './shared/components/info/terms/terms.component';
import { PrivacyComponent } from './shared/components/info/privacy/privacy.component';
import { FaqComponent } from './shared/components/info/faq/faq.component';
import { CookiesComponent } from './shared/components/info/cookies/cookies.component';
import { CookiesPublicComponent } from './core/cookies-public/cookies-public.component';

import { NotificationSettingsComponent } from './modules/main/pages/settings/notification-settings/notification-settings.component';
import { MilestonesComponent } from './modules/main/pages/profiles/milestones/milestones.component';
import { EmailRatingComponent } from './core/email/email-rating/email-rating.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'email-rating/:rating/:customer', component: EmailRatingComponent },
  { path: 'cookies-public', component: CookiesPublicComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: 'home', loadChildren: () => import('./modules/main/pages/home/home.module').then(m => m.HomeModule) },
      { path: 'services', loadChildren: () => import('./modules/main/pages/services/services.module').then(m => m.ServicesModule) },
      { path: 'photos', loadChildren: () => import('./modules/main/pages/photos/photos.module').then(m => m.PhotosModule) },
      { path: 'profiles', loadChildren: () => import('./modules/main/pages/profiles/profiles.module').then(m => m.ProfilesModule) },
      { path: 'billing', loadChildren: () => import('./modules/main/pages/billing/billing.module').then(m => m.BillingModule) },
      { path: 'settings', loadChildren: () => import('./modules/main/pages/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'contact', loadChildren: () => import('./modules/main/pages/contact/contact.module').then(m => m.ContactModule) },
      { path: 'terms', component: TermsComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'cookies', component: CookiesComponent },
      { path: 'notification_settings', component: NotificationSettingsComponent },
      { path: 'milestones', component: MilestonesComponent },
      { path: '**', redirectTo: '/home' }
    ]
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
