import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DownloadAppNotificationComponent } from 'src/app/shared/components/download-app-notification/download-app-notification.component';

@Injectable({
  providedIn: 'root'
})

export class MobileAppService {
  // Timer housing
  _appCheckInterval;
  _appCheckTime = 5000;

  // List of all un-authed routes
  _pageBlacklist = ['login', 'forgot', 'register', 'reset', 'cookies', 'email-rating', 'terms', 'faq', 'privacy',];

  constructor(private deviceService: DeviceDetectorService, private dialog: MatDialog, private cookieService: CookieService) { }

  // Use to display the dialog if the customer has not seen it before and they
  // are on a mobile device.
  // Utilizes the DeviceDetector npm package to check browser details 
  // for mobile and tablet devices.
  checkIfAppDownloadShouldDisplay(): void {
    // Mobile device checks using browser userAgent
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    // Checks cookies to see if dialog has shown in the last 2 weeks
    const hasSeenBefore = this.cookieService.get('_bddc_download_app_reminder');

    // Check that the app is not in any of the following pages
    let blacklistedRoute = false;
    for (let route of this._pageBlacklist) {
      if (location.href.match(route)) {
        blacklistedRoute = true;
        break;
      }
    }

    if ((!hasSeenBefore || hasSeenBefore === "") && (isMobile || isTablet) && !isDesktop && !blacklistedRoute) {
      // Resets cookie if dialog is seen to 2 weeks
      const today = new Date();
      const expire = new Date();
      expire.setTime(today.getTime() + 3600000 * 24 * 14);
      document.cookie = '_bddc_download_app_reminder=true; expires=' + expire + '; path=/';

      this.dialog.closeAll();
      this.dialog.open(DownloadAppNotificationComponent, { disableClose: true });
    }
  }

  // Code that checks for the app download dialog every few seconds
  startAppChecker(): void {
    this._appCheckInterval = setInterval(() => {
      this.checkIfAppDownloadShouldDisplay();
    }, this._appCheckTime);
  }

  killAppChecker(): void {
    if (this._appCheckInterval) {
      clearInterval(this._appCheckInterval);
    }
  }

  resetAppChecker(): void {
    this.killAppChecker();
    this.startAppChecker();
  }

  shouldOnShowMobileDevice(): boolean {
    return this.deviceService.isMobile() || this.deviceService.isTablet();
  }
}
