import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any, overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('broadcast-dialog_overlay');
  }

  ngOnInit(): void {
  }

}
