import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';
import { CalendarDate } from 'src/app/shared/models/calendar-date';

@Injectable({
  providedIn: 'root'
})

export class CalendarService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }

  // HttpClient API get() method => Fetch calendarData object
  getCalendarData(dateRange: string[]): Observable<CalendarDate[]> {
    const apiUrl = '/customer_object/calendar_initialize';
    // TODO: get date range dynamically:
    const params = new HttpParams()
      .set('start_date', dateRange[0])
      .set('end_date', dateRange[1]);
    const calendarData = this.http.get<CalendarDate[]>(this.baseApiService.API_URL + apiUrl, { params });
    return calendarData;
  }

  // HttpClient API get() method => Fetch calendarData object
  removeService(futureServiceId: number): any {
    const apiUrl = '/dogs/service_cancellation_request';
    const response = this.http.post(this.baseApiService.API_URL + apiUrl, { future_service_id: futureServiceId });

    return response;
  }

  removeRequest(requestId: number, requestType: string): any {
    const apiUrl = '/dogs/remove_request';
    const response = this.http.post(this.baseApiService.API_URL + apiUrl, { request_id: requestId, request_type: requestType });

    return response;
  }

  isSwapValid(dogId, toDate, fromDate) {
    const apiUrl = '/services/swap_is_valid';
    const response = this.http.post(this.baseApiService.API_URL + apiUrl, { dog_id: dogId, new_date: toDate, date: fromDate });

    return response;
  }

  swapCutOffReached(futureServiceId: number) {
    const apiUrl = '/services/swap_cut_off_reached';
    const response = this.http.post(this.baseApiService.API_URL + apiUrl, { future_service_id: futureServiceId });

    return response;
  }

  requestSwap(futureServiceId, toDate, fromDate) {
    const apiUrl = '/services/request_swap_day';
    // tslint:disable-next-line: max-line-length
    const response = this.http.post(this.baseApiService.API_URL + apiUrl, { future_service_id: futureServiceId, new_date: toDate, date: fromDate });

    return response;
  }
}
