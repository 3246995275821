import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';

@Component({
  selector: 'app-email-share-dialog',
  templateUrl: './email-share-dialog.component.html',
  styleUrls: ['./email-share-dialog.component.scss']
})
export class EmailShareDialogComponent implements OnInit, OnDestroy {
  @Output() authoriseViewing: EventEmitter<boolean> = new EventEmitter();
  showPasswordValue = false;
  titleClass: string;
  form: UntypedFormGroup;
  email: string;
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public overlayContainer: OverlayContainer,
    public breakpointObserver: BreakpointObserver,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private googleAnalyticsEvents: GooleAnalyticsEventsService) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('email-share_overlay');
  }

  ngOnInit() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });

    this.initForm();
  }

  initForm(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(this.email, [Validators.required])
    });
  }

  sendEmail(data: any): void {
    const email = Object.assign({}, data);
    if (this.form.valid) {
      this.loading = true;
      this.customerService.shareViaEmail(email.email, this.data.photoPath).subscribe((resp) => {
        if (resp['result'] === true) {
          this.loading = false;
          this.dialogRef.close();
          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Successful!', body: 'Photo shared via email.', type: 'success' }
          });
        } else {
          console.error('SHARE EMAIL FAILED');
        }
      });
    }
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    this.authoriseViewing.emit(false);
  }

  setMobileLayout() {
    this.titleClass = 'mat-headline u-margin-bottom-15';
    this.dialogRef.updateSize('343px', '335px');
  }

  setDesktopLayout() {
    this.titleClass = 'mat-display-1';
    this.dialogRef.updateSize('377px', '353px');
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'profile - ' + section, 'profile', 10);
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('email-share_overlay');
  }
}
