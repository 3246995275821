import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';

import { CustomerService } from 'src/app/core/services/customer.service';
import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { RequestStoreService } from 'src/app/core/services/request-store.service';
import { ThisReceiver } from '@angular/compiler';
import { RequestModel } from 'src/app/core/models/request.model';
import { RequestType } from 'src/app/core/enums/request-type.enum';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  headerImage = '';
  appLoaderAnimation = './../../../../../../assets/images/dogloading.gif';
  domEl: HTMLElement;
  emailForm: UntypedFormGroup;
  pushForm: UntypedFormGroup;
  emailNotificationsEnabled = true;
  pushNotificationsEnabled = true;
  isEdit = false;
  hasData = false;
  emailNotifications = [];
  pushNotifications = [];
  originalEmailNotifications = [];
  originalPushNotifications = [];
  apiResp = [];
  notificationsPayload = [];

  // tslint:disable-next-line: max-line-length
  constructor(private customerService: CustomerService, private requestStore: RequestStoreService, private dialog: MatDialog, private googleAnalyticsEvents: GooleAnalyticsEventsService, breakpointObserver: BreakpointObserver, private title: Title) {
    breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../../assets/images/backgrounds/settings/settings_header_mobile1.jpg';
      } else {
        this.headerImage = './../../../../../../assets/images/backgrounds/settings/settings_header_desktop1.jpg';
      }
    });
  }

  ngOnInit() {
    this.title.setTitle('Bruce\'s | Notification Settings');
    this.fetchApiData();
  }

  fetchApiData() {
    this.requestStore.addRequest(new RequestModel(
      '/customer_object/get_customer_notification_settings',
      RequestType.GET
    ));

    this.requestStore.dispatch().subscribe((data) => {
      this.apiResp = data[0];
      const emails = this.apiResp['email_notifications'];
      const pushes = this.apiResp['push_notifications'];

      this.assembleNotificationsData(emails[0], pushes[0]);
      this.initForms(this.emailNotifications, this.pushNotifications);
    }, err => {
      this.dialog.open(NotificationMessageComponent, {
        data: { heading: 'Failed!', body: 'Something went wrong. Please contact BDDC.', type: 'danger' }
      });
    });
  }

  assembleNotificationsData(emailNotifications: any, pushNotifications: any) {
    this.emailNotifications = [
      {
        id: 'email_dog_tagged',
        notification_name: 'Dog tagged in photo',
        active: emailNotifications.email_dog_tagged
      },
      {
        id: 'email_service_completed',
        notification_name: 'Service completion',
        active: emailNotifications.email_service_completed
      },
      {
        id: 'email_monthly_worming_date',
        notification_name: 'Monthly worming date',
        active: emailNotifications.email_monthly_worming_date
      },
      {
        id: 'email_annual_vaccination_date',
        notification_name: 'Annual vaccination date',
        active: emailNotifications.email_annual_vaccination_date
      },
      {
        id: 'email_check_in',
        notification_name: 'Dog check in',
        active: emailNotifications.email_check_in
      },
      {
        id: 'email_check_out',
        notification_name: 'Dog check out',
        active: emailNotifications.email_check_out
      },
      {
        id: 'email_birthday',
        notification_name: 'Dog birthday',
        active: emailNotifications.email_birthday
      },
      {
        id: 'email_anniversary',
        notification_name: 'Dog anniversary at day care centre',
        active: emailNotifications.email_anniversary
      }
    ];

    this.pushNotifications = [
      {
        id: 'dog_tagged_in_photo',
        notification_name: 'Dog tagged in photo',
        active: pushNotifications.dog_tagged_in_photo
      },
      {
        id: 'dynamic_service_completed',
        notification_name: 'Service completion',
        active: pushNotifications.dynamic_service_completed
      },
      {
        id: 'worming_reminder',
        notification_name: 'Monthly worming date',
        active: pushNotifications.worming_reminder
      },
      {
        id: 'vaccination_due',
        notification_name: 'Annual vaccination date',
        active: pushNotifications.vaccination_due
      },
      {
        id: 'check_in',
        notification_name: 'Dog check in',
        active: pushNotifications.check_in
      },
      {
        id: 'check_out',
        notification_name: 'Dog check out',
        active: pushNotifications.check_out
      },
      {
        id: 'dog_birthday',
        notification_name: 'Dog birthday',
        active: pushNotifications.dog_birthday
      },
      {
        id: 'anniversary',
        notification_name: 'Dog anniversary at day care centre',
        active: pushNotifications.anniversary
      }
    ];

    // Clone the arrays to preserve original settings when toggling between checkbox states
    this.originalEmailNotifications = this.emailNotifications.map(x => Object.assign({}, x));
    this.originalPushNotifications = this.pushNotifications.map(x => Object.assign({}, x));

    if (!this.emailNotifications.some(e => e.active === true)) {
      this.emailNotificationsEnabled = false;
    }

    if (!this.pushNotifications.some(e => e.active === true)) {
      this.pushNotificationsEnabled = false;
    }
  }

  initForms(emailData, pushData) {

    this.emailForm = new UntypedFormGroup({
      emailCheckbox: new UntypedFormControl(this.emailNotificationsEnabled),
      email_dog_tagged: new UntypedFormControl(emailData[0].email_dog_tagged),
      email_service_completed: new UntypedFormControl(emailData[1].email_service_completed),
      email_monthly_worming_date: new UntypedFormControl(emailData[2].email_monthly_worming_date),
      email_annual_vaccination_date: new UntypedFormControl(emailData[3].email_annual_vaccination_date),
      email_check_in: new UntypedFormControl(emailData[4].email_check_in),
      email_check_out: new UntypedFormControl(emailData[5].email_birthday),
      email_birthday: new UntypedFormControl(emailData[6].email_birthday),
      email_anniversary: new UntypedFormControl(emailData[7].email_anniversary)
    });

    this.pushForm = new UntypedFormGroup({
      pushCheckbox: new UntypedFormControl(this.pushNotificationsEnabled),
      dog_tagged_in_photo: new UntypedFormControl(pushData[0].dog_tagged_in_photo),
      dynamic_service_completed: new UntypedFormControl(pushData[1].dynamic_service_completed),
      worming_reminder: new UntypedFormControl(pushData[2].worming_reminder),
      vaccination_due: new UntypedFormControl(pushData[3].vaccination_due),
      check_in: new UntypedFormControl(pushData[4].check_in),
      check_out: new UntypedFormControl(pushData[5].check_out),
      dog_birthday: new UntypedFormControl(pushData[6].dog_birthday),
      anniversary: new UntypedFormControl(pushData[7].anniversary)
    });

    const emailCheckBoxEl = this.emailForm.controls.emailCheckbox;
    emailCheckBoxEl.valueChanges.subscribe(emailSelected => {
      if (!emailSelected) {
        this.clearValues(this.emailNotifications);
      } else {
        this.emailNotifications = this.originalEmailNotifications.map(x => Object.assign({}, x));
      }
    });

    const pushCheckBoxEl = this.pushForm.controls.pushCheckbox;
    pushCheckBoxEl.valueChanges.subscribe(pushSelected => {
      if (!pushSelected) {
        this.clearValues(this.pushNotifications);
      } else {
        this.pushNotifications = this.originalPushNotifications.map(x => Object.assign({}, x));
      }
    });
    this.hasData = true;
  }

  editNotifications() {
    this.isEdit = !this.isEdit;
  }

  clearValues(notifications) {
    notifications.forEach(item => {
      item.active = false;
    });
  }

  saveNotifications() {
    this.isEdit = false;
    this.customerService.setCustomerNotificationSettings({ email: this.emailNotifications, push: this.pushNotifications })
      .subscribe(resp => {
        this.hasData = true;
        this.dialog.open(NotificationMessageComponent, {
          data: { heading: 'Success!', body: ' Notifications updated.', type: 'success' }
        });
        this.googleAnalyticsEvents.eventEmitter('save', 'settings - notification settings', 'notifications', 10);
      }, err => {
        this.dialog.open(NotificationMessageComponent, {
          data: { heading: 'Failed!', body: 'Something went wrong. Please contact BDDC.', type: 'danger' }
        });
      });
  }

  checkValue(event, toggle, type) {
    toggle.active = event.checked;

    if (type === 'email' && !this.emailNotifications.some(e => e.active === true)) {
      this.emailNotificationsEnabled = false;
    }

    if (type === 'push' && !this.pushNotifications.some(e => e.active === true)) {
      this.pushNotificationsEnabled = false;
    }
  }
}
