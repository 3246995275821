<mat-card class="medium-image-card {{ layout ? layout : '' }}" id="card_{{ cardId }}">
  <div class="medium-image-card_header">
    <ng-container *ngIf="hasOverlay">
      <div class="medium-image-card_image-overlay">
        <span>{{ overlayText }}</span>
      </div>
    </ng-container>
    <div class="medium-image-card_image" [style.background-image]="'url(' + bannerImage + ')'"></div>
    <ng-container *ngIf="hasNotification">
      <div class="medium-image-card_notification">
        {{ cardNotification }}
      </div>
    </ng-container>
  </div>
  <mat-card-content>
    <mat-card-title>
      <ng-content select="[card-title]"></ng-content>
    </mat-card-title>
    <div class="medium-image-card_body-text">
      <p (click)="manipulateText($event.currentTarget)" class="title" id="{{ cardId }}">{{ cardText }}</p>
      <p class="grid-view-price">From &#163; {{ cardPrice }}</p>
    </div>
    <div class="medium-image-card_more-info">
      <div class="price">From &#163; {{ cardPrice }}</div>
      <div class="medium-image-card_more-info_button {{
          textLengthCheck(cardText) ? 'large-container' : ''
        }}">
        <ng-content select="[card-button]"></ng-content>
      </div>
    </div>
  </mat-card-content>
</mat-card>