<div class="modal-container">
  <div>
    <h1 *ngIf="!uploadLoading" mat-dialog-title>Upload vaccination records</h1>
    <h1 *ngIf="uploadLoading" mat-dialog-title>Uploading vaccination records</h1>
  </div>
  <mat-dialog-content *ngIf="uploadLoading" class="form-loader">
    <mat-spinner [diameter]="60"></mat-spinner>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="!uploadLoading" class="form-container">
    <ng-container *ngFor="let dog of dogData" class="u-flex-column">
      <div class="vax-section">
        <p class="mat-body-1">
          {{ dog.name }}
        </p>

        <input id="file-input-{{dog.id}}" style="display: none;" type="file" #fileInput
          accept="image/*,.pdf,image/heic,image/heif" (change)="onFileSelected($event, dog.id)" multiple />
        <label mat-raised-button style="cursor: pointer;" id="file-input-label" for="file-input-{{dog.id}}">
          Upload file(s) for {{ dog.name }}
        </label>

        <div *ngFor="let file of (dogFormData.get(dog.id)?.files ?? [])" class="vax-remove-section">
          <span class="vax-file-name">{{ file.name }}</span>
          <button class="vax-file-remove" mat-raised-button (click)="onRemoveFile($event, file, dog.id)">Remove</button>
        </div>

        <div id="date-picker-{{dog.id}}" class="hidden">
          <p>Vaccination record expiry date (optional)</p>

          <mat-form-field floatLabel="always" class="vaccination-uploads">
            <input matInput (click)="vaxDatepicker.open()" [matDatepicker]="vaxDatepicker"
              (dateChange)="onDateSelected($event, dog.id)" [min]="vaxMinDate" readonly>
            <mat-datepicker-toggle matSuffix [for]="vaxDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #vaxDatepicker [startAt]="startDate" (dateChange)="onDateSelected($event, dog.id)"
              color="accent"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <hr *ngIf="dogData.length > 1" />
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="vax-actions" *ngIf="!uploadLoading">
    <button mat-raised-button [disabled]="disableUploadButton && !uploadLoading"
      (click)="onSubmit($event)">Upload</button>
    <button mat-raised-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>