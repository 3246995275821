import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { PasswordFormCardComponent } from 'src/app/shared/components/card/password-form-card/password-form-card.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  headerImage: string;
  isDesktopLayout: boolean;
  private breakpointSub: Subscription;

  constructor(private titleService: Title, private dialog: MatDialog, breakpointObserver: BreakpointObserver) {
    this.breakpointSub = breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/mobile_bg.jpg';
        this.isDesktopLayout = false;
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/desktop_bg.jpg';
        this.isDesktopLayout = true;
      }
    });
  }

  ngOnInit() {
    this.setTitle('Bruce\'s | Registration');
  }

  onFormSubmit($event) { }

  state($event) {
    if ($event) {
      this.dialog.open(NotificationMessageComponent, {
        data: { heading: 'Registration Successful!', body: 'Welcome to Bruce\'s', type: 'success' }
      });
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
