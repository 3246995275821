<div class="service-calendar-dialog">
  <h1 class="{{ titleClass }}">Swap day cut-off reached</h1>
  <p class="mat-body-1 margin-top_10-override">

  </p>
  <p class="mat-body-1">
    <i>{{ data.message }}</i>
  </p>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="cancelService()" [mat-dialog-close]="true">
      YES, CANCEL DAY
    </button>
    <a mat-raised-button (click)="closeDialog()" class="cancel-button medium">Cancel</a>
  </div>
</div>