import { Injectable, Sanitizer, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';
import { CustomerSummary } from 'src/app/shared/models/customer-summary';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService, private sanitizer: Sanitizer) { }

  getCustomerSummary(): Observable<CustomerSummary> {
    const apiUrl = '/customer_object/get_customer_summary';
    const customer = this.http.get<CustomerSummary>(this.baseApiService.API_URL + apiUrl);

    return customer;
  }

  updateCustomer(formData): Observable<CustomerSummary> {
    const apiUrl = '/customer_object/update_customer';
    const customer = this.http.post<CustomerSummary>(this.baseApiService.API_URL + apiUrl, formData);

    return customer;
  }

  updateReview(customerId) {
    let apiUrl: string;

    // Check if the customer is logged in or not
    if (customerId) {
      apiUrl = '/customer_object/update_review_logged_out';
    } else {
      apiUrl = '/customer_object/update_review';
    }

    const customer = this.http.post(this.baseApiService.API_URL + apiUrl, { customer_id: customerId });

    return customer;
  }

  updateRemindMeLater(customerId) {
    let apiUrl: string;

    // Check if the customer is logged in or not
    if (customerId) {
      apiUrl = '/customer_object/review_date_recurrence_logged_out';
    } else {
      apiUrl = '/customer_object/review_date_recurrence';
    }
    const customer = this.http.post(this.baseApiService.API_URL + apiUrl, { customer_id: customerId });

    return customer;
  }

  getCustomerNotificationSettings(): Observable<any> {
    const apiUrl = '/customer_object/get_customer_notification_settings';
    const customerNotificationSettings = this.http.get<any>(this.baseApiService.API_URL + apiUrl);

    return customerNotificationSettings;
  }

  setCustomerNotificationSettings(formData): Observable<any> {
    const apiUrl = '/customer_object/set_customer_notification_settings';
    const customerNotificationSettings = this.http.post<any>(this.baseApiService.API_URL + apiUrl, formData);

    return customerNotificationSettings;
  }

  getCustomerBranchInformation() {
    const apiUrl = '/customer_object/get_customer_branch_info';
    const customerBranchInfo = this.http.get(this.baseApiService.API_URL + apiUrl);

    return customerBranchInfo;
  }

  sendCustomerFeedback(customerData: any) {
    const apiUrl = '/customer_object/feedback_request';
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, customerData);

    return request;
  }

  sendCustomerFeedbackDebug(customerData: any) {
    const apiUrl = '/customer_object/feedback_request_with_debug';
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, customerData);

    return request;
  }

  sendRating(satisfactionTypeId: number, comments: string) {
    const apiUrl = '/customer_object/your_feelings';
    // tslint:disable-next-line: max-line-length
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, { satisfaction_type_id: satisfactionTypeId.toString(), comments: comments, platform: 'Customer Portal' });

    return request;
  }

  sendEmailRating(satisfactionTypeId: number, customerId: number, userComments: string) {
    const apiUrl = '/customer_object/your_email_feelings';
    const params = {
      satisfaction_type_id: satisfactionTypeId.toString(),
      customer_id: customerId.toString(),
      comments: userComments,
      platform: 'Customer Portal - From Email'
    };
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, params);

    return request;
  }

  shareViaEmail(email: string, photoPath: string) {
    const apiUrl = '/customer_object/send_photo_email';
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, { email_address: email, photo_path: photoPath });

    return request;
  }

  uploadVaccinationRecords(formData) {
    const apiUrl = '/dogs/update_vax_records';
    const request = this.http.post(this.baseApiService.API_URL + apiUrl, formData);

    return request;
  }
}
