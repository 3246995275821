/// <reference path="../../../../../../../node_modules/@types/facebook-js-sdk/index.d.ts" />

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { environment } from 'src/environments/environment';
import { EmailShareDialogComponent } from './email-share-dialog/email-share-dialog.component'
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';


declare var FB: facebook.FacebookStatic;

@Component({
  selector: 'app-share-photo',
  templateUrl: './share-photo.component.html',
  styleUrls: ['./share-photo.component.scss']
})
export class SharePhotoComponent implements OnInit, OnDestroy {

  customImages = [
    {
      image_name: 'facebook_icon_2',
      image_path: 'assets/images/icons/facebook_icon_2.svg'
    },
    {
      image_name: 'mail_icon',
      image_path: 'assets/images/icons/mail_icon.svg'
    },
    {
      image_name: 'twitter_icon_2',
      image_path: 'assets/images/icons/twitter_icon_2.svg'
    }
  ];

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    public overlayContainer: OverlayContainer,
    public breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private dialog: MatDialog) {

    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('share-photo-dialog_overlay');

    this.customImages.forEach(image => {
      this.matIconRegistry.addSvgIcon(
        image.image_name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + image.image_path)
      );
    });
  }

  ngOnInit() {
  }

  defineModalSizing() {
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
  }

  setMobileLayout() {
    this.dialogRef.updateSize('343px', '339px');
  }

  setDesktopLayout() {
    this.dialogRef.updateSize('377px', '449px');
  }

  shareTo(platform: string) {
    if (platform === 'facebook') {

      // https://grobmeier.solutions/sharing-images-facebook-javascript-19102015.html

      // tslint:disable-next-line: variable-name
      const init_params: facebook.InitParams = {
        appId: environment.facebook_app_id,
        xfbml: true,
        version: 'v2.8'
      };

      const open_share_params: facebook.ShareOpenGraphDialogParams = {
        method: 'share_open_graph',
        action_type: 'og.shares',
        href: this.data.photoPath,
        action_properties: {
          'object': {
            'og:url': this.data.photoPath,
            'og:type': 'image',
            'og:title': 'galleryItem.title',
            'og:description': 'galleryItem.description',
            'og:image:width': '2560',
            'og:image:height': '960',
            'og:image': this.data.photoPath,
            'og:image:type': 'image/jpeg',
            'og:image:alt': 'actionphoto'
          }
        }
      };

      FB.init(init_params);
      FB.ui(open_share_params);

      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(event => {
        setTimeout(() => {
          // delaying until user submits fb
          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Successful!', body: 'Photo shared via facebook.', type: 'success' }
          });
        }, 10000);
      });

      this.googleEvent('share photo to facebook', 'sharing photo');
    } else if (platform === 'twitter') {

      // https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/abouts-cards

      const left = (screen.width) / 2;
      const top = (screen.height) / 4;

      // tslint:disable-next-line: max-line-length
      const specs = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + 600 + ', height=' + 500 + ', top=' + top + ', left=' + left;
      // tslint:disable-next-line: max-line-length
      const url = encodeURI(`${environment.social_share_base_url}/twitter/${this.data.photoPath.substring(this.data.photoPath.lastIndexOf('/') + 1)}`) + `?d=${encodeURI(this.data.dogsTagged)}`;
      // tslint:disable-next-line: max-line-length
      window.open(`https://twitter.com/intent/tweet?text=Having%20fun%20at%20day%20care&url=${url}&hashtags=awardwinning,brucesdoggydaycare`, 'Share to Twitter', specs);

      this.dialogRef.close();
      this.dialogRef.afterClosed().subscribe(event => {
        setTimeout(() => {
          // delaying until user submits tweet
          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Successful!', body: 'Photo shared via twitter.', type: 'success' }
          });
        }, 10000);
      });

      this.googleEvent('share photo to twitter', 'sharing photo');

    } else if (platform === 'email') {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: 'Please enter email to share',
        confirmButton: 'Submit',
        cancelButton: 'Cancel',
        photoPath: this.data.photoPath
      };

      this.dialogRef.close();

      this.dialog.open(EmailShareDialogComponent, dialogConfig);

      this.googleEvent('share photo via email', 'sharing photo');

    } else {
      return;
    }
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'share photo - ' + section, 'share photo', 10);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('share-photo-dialog_overlay');
  }

}
