import { Component, OnInit, OnDestroy, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-service-calendar-dialog',
  templateUrl: './service-calendar-dialog.component.html',
  styleUrls: ['./service-calendar-dialog.component.scss']
})
export class ServiceCalendarDialogComponent implements OnInit, OnDestroy {
  @Output() confirmRemove: EventEmitter<boolean> = new EventEmitter();
  private subscription: Subscription;
  titleClass: string;

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>, public overlayContainer: OverlayContainer, public breakpointObserver: BreakpointObserver) {
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('services-calendar-dialog_overlay');
    if (this.data.title.toLowerCase().includes('cancel')) {
      overlayContainer.getContainerElement().classList.add('services-calendar-cancel_overlay');
    }
  }

  ngOnInit(): void {
    this.subscription = this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout();
      } else {
        this.setDesktopLayout();
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
    this.confirmRemove.emit(false);
  }

  readyToRemoveService() {
    this.dialogRef.close();
    this.confirmRemove.emit(true);
  }

  setMobileLayout() {
    this.titleClass = 'mat-headline u-margin-bottom-15';
    if (this.data.title.toLowerCase().includes('cancel')) {
      this.dialogRef.updateSize('343px');
    } else {
      this.dialogRef.updateSize('343px');
    }
  }

  setDesktopLayout() {
    this.titleClass = 'mat-display-1';
    this.dialogRef.updateSize('377px');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.overlayContainer.getContainerElement().classList.remove('services-calendar-dialog_overlay');
  }

}
