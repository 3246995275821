<div class="customer-feedback-dialog">
  <div class="customer-feedback-dialog_title">
    <h1 class="{{ titleClass }}">{{ data.title }}</h1>
  </div>
  <div class="customer-feedback-dialog_form">
    <form [formGroup]="form" (ngSubmit)="sendCustomerMessage(form.value)" class="feedback_form">
      <mat-form-field hideRequiredMarker="true" floatLabel="never" class="feedback_textarea">
        <textarea matInput class="mat-body-1 bddc-dark-blue bold" formControlName="customer_feedback"
          placeholder="What's on your mind?"></textarea>

        <ng-container *ngIf="data.additionalInfo">
          <input type="hidden" formControlName="bug_report">
        </ng-container>
      </mat-form-field>

      <div class="errors">
        <mat-error *ngIf="form.invalid && form.controls['customer_feedback'].value.trim() != '' && form.controls['customer_feedback'].value.trim().length >= 1500">Must be less than 1500 characters.</mat-error>
        <mat-error *ngIf="form.invalid && form.touched && form.controls['customer_feedback'].value.trim() == ''">Message cannot be empty.</mat-error>
        <mat-error *ngIf="form.invalid && form.touched && form.controls['customer_feedback'].errors.htmlExists != null">This input contains invalid characters.</mat-error>
      </div>

      <div mat-dialog-actions>
        <button mat-raised-button color="primary" [disabled]="this.form.invalid">
          {{ data.confirmButton }}
        </button>
      </div>
    </form>
    <a mat-raised-button (click)="closeDialog()" class="cancel-button large">{{
      data.cancelButton
      }}</a>
  </div>
</div>
