import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { GooleAnalyticsEventsService } from 'src/app/core/services/goole-analytics-events.service';
import { PhotoService } from 'src/app/core/services/photo.service';

import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { SwiperPhotos } from 'src/app/shared/interfaces/swiper-photos.interface';

import { saveAs } from 'file-saver';
import { TaggedDogsDialogComponent } from './tagged-dogs-dialog/tagged-dogs-dialog.component';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photo-view',
  templateUrl: './photo-view.component.html',
  styleUrls: ['./photo-view.component.scss']
})
export class PhotoViewComponent implements OnInit, OnDestroy {
  @Output() photoToBeShared = new EventEmitter<string>();
  config: SwiperConfigInterface;
  customImages = [
    {
      image_name: 'carousel-download_icon',
      image_path: 'assets/images/icons/carousel-download_icon.svg'
    },
    {
      image_name: 'carousel-heart_icon',
      image_path: 'assets/images/icons/carousel-heart_icon.svg'
    },
    {
      image_name: 'carousel-share_icon',
      image_path: 'assets/images/icons/carousel-share_icon.svg'
    },
    {
      image_name: 'close_icon',
      image_path: 'assets/images/icons/close_icon.svg'
    }
  ];
  showSpinner = false;
  userActive: boolean;

  disable = false;

  @Output() selectedImage: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    public overlayContainer: OverlayContainer,
    public breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsEvents: GooleAnalyticsEventsService,
    private photoService: PhotoService,) {
    this.userActive = localStorage.getItem('active') === 'true';
    overlayContainer.getContainerElement().classList.remove('mini-calendar-dialog_overlay');
    overlayContainer.getContainerElement().classList.add('photo-view-dialog_overlay');

    this.customImages.forEach(image => {
      this.matIconRegistry.addSvgIcon(
        image.image_name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(environment.base + image.image_path),
      );
    });
  }

  ngOnInit() {
    this.defineModalSizing();
  }

  defineModalSizing() {
    this.selectedImage = this.data.id;
    this.breakpointObserver.observe([
      Breakpoints.Handset
    ]).subscribe(result => {
      if (result.matches) {
        this.setMobileLayout(this.selectedImage);
      } else {
        this.setDesktopLayout(this.selectedImage);
      }
    });
  }

  setMobileLayout(photoId: number) {
    this.dialogRef.updateSize('100vw', '80vh');
    this.config = {
      containerModifierClass: 'photo-viewer ',
      scrollbar: false,
      pagination: false,
      navigation: true,
      centeredSlides: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: false,
      initialSlide: photoId,
      slidesPerView: 1,
      breakpoints: {
        320: {
          width: 247
        },
        375: {
          width: 311
        }
      }
    };
  }

  setDesktopLayout(photoId: number) {
    this.dialogRef.updateSize('70vw', '90vh');
    this.config = {
      containerModifierClass: 'photo-viewer ',
      scrollbar: false,
      pagination: false,
      keyboard: {
        enabled: true,
      },
      initialSlide: photoId,
      navigation: true,
      centeredSlides: true,
      simulateTouch: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      preventInteractionOnTransition: true
    };
  }

  closeDialog() {
    this.dialogRef.close();
    this.overlayContainer.getContainerElement().classList.remove('photo-view-dialog_overlay');
  }

  favouriteThisPhoto(image) {
    const tempObj: SwiperPhotos = {
      id: image.id,
      path: image.path,
      isFavourite: true,
      dogsTagged: image.dogsTagged
    };

    this.updateFavouritedPhotos(tempObj);
  }

  shareThisPhoto(photoIdx: string) {
    this.photoToBeShared.emit(photoIdx);
    this.googleEvent('share photo', 'viewing photo');
    this.closeDialog();
  }

  updateFavouritedPhotos(photoFavourited) {
    let message = '';

    this.data.photos.forEach(photo => {
      if (photoFavourited.id === photo.id) {
        this.photoService.setPhotoFavourite(photoFavourited.id, !photo.isFavourite).subscribe(resp => {
          if (resp['success']) {
            photo.isFavourite = !photo.isFavourite;
          }
        }, err => {
          console.error('FAILED TO UPDATE PHOTO AS FAVOURITE! ', err);
        });
        message = !photo.isFavourite ? 'Photo tagged as a favourite.' : 'Favourite tag removed.';
      }
    });

    this.dialog.open(NotificationMessageComponent, {
      data: { heading: 'Success', body: message, type: 'success' }
    });

    this.googleEvent('set photo as favourite', 'viewing photo');
  }

  public onIndexChange(index: number): void {
    this.selectedImage = index;
  }

  makeProfilePic(image) {
    if (image.taggedDogsObj.length > 1) {
      this.dialog.closeAll();

      setTimeout(() => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          imageObj: image
        };

        const previewPhotoDialog = this.dialog.open(
          TaggedDogsDialogComponent,
          dialogConfig
        );

      }, 500);

    } else {
      this.showSpinner = true;
      this.photoService.setProfilePic(image.id).subscribe(resp => {
        if (resp['success']) {
          this.googleEvent('set profile photo', 'viewing photo');
          this.showSpinner = false;
          this.dialog.open(NotificationMessageComponent, {
            data: { heading: 'Success', body: 'Your profile picture has been updated.', type: 'success' }
          });
        }
      }, err => {
        console.warn('FAILED!', err);
      });
    }
  }

  googleEvent(section, cardTitle) {
    this
      .googleAnalyticsEvents
      .eventEmitter(cardTitle, 'photo viewer - ' + section, 'photo viewer', 10);
  }

  download(idx: number): void {
    const photo = this.data.photos[idx];
    if (photo && !this.disable) {

      this.disable = true;
      this.photoService.downloadFile(photo.path).subscribe((blob) => {
        const nameArray = photo.path.split('/');
        // Removing file extension from name as the blob already has the file type
        const fileName = nameArray[nameArray.length - 1].split('.')[0];

        let fileType = blob.type;

        if (fileType.includes('xml')) {
          blob = new Blob([blob], { type: "image/jpeg" })
        }
        saveAs(blob, fileName);
        setTimeout(() => { this.disable = false; }, 1000);

      });
      // saveAs(photo.path, photo.taggedDogs + '.jpeg');
    }
  }

  ngOnDestroy() {
    this.overlayContainer.getContainerElement().classList.remove('photo-view-dialog_overlay');
  }
}
