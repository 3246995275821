import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SessionDialogComponent } from '../core/session/session-dialog/session-dialog.component';
import { Subscription } from 'rxjs';
import { PhotoViewComponent } from './pages/photos/photo-view/photo-view.component';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild('photoView', { static: true }) photoView!: PhotoViewComponent;
  // @ViewChild(PhotoViewComponent, { static: false }) photoView !: PhotoViewComponent;
  imagePath = './../../assets/images/bddc-bouncer-logo.png';
  disableFooter: boolean;
  disableBoxShadow: boolean;
  subscription: any;

  timedOut = false;
  dialogPresent = false;

  constructor(
    private router: Router,
    private idle: Idle,
    private authService: AuthService,
    private dialog: MatDialog,
    private overlayContainer: OverlayContainer
  ) {
    // this.startIdleInstance();
  }

  startIdleInstance() {
    this.subscription = new Subscription();
    this.dialogPresent = false;
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(120);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(120);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscription = this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    this.subscription = this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      if (!this.dialogPresent) {
        this.displaySessionDialog();
      }
    });
    this.reset();
  }

  displaySessionDialog() {
    if (this.overlayContainer.getContainerElement().classList.contains('photo-view-dialog_overlay')) {
      this.overlayContainer.getContainerElement().classList.remove('photo-view-dialog_overlay');
    }
    this.dialog.closeAll();

    this.dialogPresent = true;
    this.idle.stop();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'You have been idle too long.',
      description: 'If you wish to stay signed in, please select the stay button.'
    };

    const dialogRef = this.dialog.open(SessionDialogComponent, dialogConfig);

    dialogRef.componentInstance.userAction.subscribe(result => {
      if (result === 'refresh') {
        this.authService.restartTimer();
        this.startIdleInstance();
      } else {
        this.authService.logoutUser();
      }
    });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnInit() {
    if (this.router.url.includes('/services/booking')) {
      this.disableFooter = true;
    } else {
      this.disableFooter = false;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const main = document.querySelector('mat-sidenav-content');
        if (main) {
          main.scrollTop = 0;
        }
        if (event.url.includes('/services/booking')) {
          this.disableFooter = true;
        } else {
          this.disableFooter = false;
        }
      }
    });
  }

  ngOnDestroy() {
    // this.idle.stop();
  }
}
