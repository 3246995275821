<app-page-header hideDebitStatus="true" extraClass="full-height {{isDesktopLayout ? 'hidden' : ''}}"
  headerImage="{{ headerImage }}" imageSrc="{{ logoImage }}" imageAlt="BDDC Logo"></app-page-header>
<div class="forgot-form_wrapper" [style.background-image]="isDesktopLayout ? 'url(' + headerImage + ')' : ''">
  <ng-container *ngIf="isDesktopLayout">
    <img img src="{{ logoImage }}" alt="BDDC Logo" />
  </ng-container>
  <div class="forgot-form {{ this.hasError ? 'form-has-error' : '' }}">
    <h1 class="mat-headline u-margin-bottom-15">Forgot Password?</h1>
    <div class="mat-body-1 forgot-form_error {{
               this.hasError ? 'show-error' : ''
            }}">
      {{ this.hasError ? this.forgotPasswordValidationMessage : forgotErrorMessage }}
    </div>
    <p class="mat-body-1 forgot-form_instructions u-text-center">
      Enter your email to receive instructions
    </p>

    <div class="forgot-form_wrapper">
      <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <mat-form-field hideRequiredMarker="true" class="forgot-form_input password-form-field" floatLabel="always">
          <mat-label>Email</mat-label>
          <input matInput (blur)="onBlurMethod()" formControlName="email" placeholder="example@email.co.uk" required
            autocomplete="email" />
        </mat-form-field>

        <div class="forgot-form_buttons">

          <button disabled *ngIf="loading" mat-raised-button class="rounded">
            <div class="u-flex-center">
              <mat-spinner color="primary" diameter="30"></mat-spinner>
            </div>
          </button>

          <button *ngIf="!loading" mat-raised-button color="primary" type="submit" class="rounded">
            Reset Password
          </button>

          <button mat-raised-button [routerLink]="['/', 'login']" class="rounded mat-footer box-shadow ">
            Back to login
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="mat-body-1 copy-right-text">
    &copy; Bruce's {{year}} |
    <span class="mat-body-2 copy-right-text_link" (click)="goToCookies()">Use of cookies</span>
  </div>
</div>