import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DogService } from 'src/app/core/services/dog.service';
import { DogSummary } from 'src/app/shared/models/dog-summary';
import { CustomerService } from 'src/app/core/services/customer.service';
import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Observable, Subscription, forkJoin } from 'rxjs';

interface DogFormData {
  files: File[],
  dateSelected?: Date,
}

@Component({
  selector: 'app-upload-vaccination',
  templateUrl: './upload-vaccination.component.html',
  styleUrls: ['./upload-vaccination.component.scss'],
})
export class UploadVaccinationComponent implements OnInit {
  container: OverlayContainer;

  // Loading data
  uploadLoading: boolean = false;

  // State data
  disableUploadButton: boolean = true;
  dogData: DogSummary[] = [];
  dogFormData: Map<number, DogFormData> = new Map<number, DogFormData>();
  vaxMinDate = new Date();
  startDate = new Date(this.vaxMinDate.getFullYear() + 1, this.vaxMinDate.getMonth(), this.vaxMinDate.getDate());

  constructor(overlayContainer: OverlayContainer, private dialog: MatLegacyDialog, private dogService: DogService, private customerSrevice: CustomerService) {
    // Creates the blue backdrop for the dialog.
    overlayContainer.getContainerElement().classList.add('broadcast-dialog_overlay');
    this.container = overlayContainer;
  }

  ngOnInit(): void {
    this.fetchDogInfo();
  }

  ngOnDestroy(): void {
    // Removes the blue backdrop for the dialog.
    this.container.getContainerElement().classList.remove('broadcast-dialog_overlay');
  }
  onFileSelected(event, dogId: number) {
    let currentData: DogFormData = this.dogFormData.get(dogId);
    if (!currentData) {
      currentData = { files: [], dateSelected: null };
    };
    for (let file of event.target.files) {
      currentData.files.push(file);
    }
    this.dogFormData.set(dogId, currentData);

    // State checks
    this.checkDatePickerRemoval(currentData.files, dogId);
    this.checkUploadButtonState();
  }

  onRemoveFile(_, file: File, dogId: number) {
    let currentData: DogFormData = this.dogFormData.get(dogId);
    currentData.files = currentData.files.filter((filterData: File) => {
      return filterData.name !== file.name;
    });
    this.dogFormData.set(dogId, currentData);

    // State checks
    this.checkDatePickerRemoval(currentData.files, dogId);
    this.checkUploadButtonState();
  }

  checkDatePickerRemoval(data: File[], dogId: number) {
    if (data.length > 0) {
      document.getElementById(`date-picker-${dogId.toString()}`).classList.remove('hidden');
    } else {
      document.getElementById(`date-picker-${dogId.toString()}`).classList.add('hidden');
    }
  }

  checkUploadButtonState() {
    this.disableUploadButton = true;
    this.dogFormData.forEach((formData: DogFormData) => {
      if (formData.files.length > 0) {
        this.disableUploadButton = false;
      }
    });
  }

  onDateSelected(event, dogId: number) {
    let currentData: DogFormData = this.dogFormData.get(dogId);
    currentData.dateSelected = new Date(event.value);
    this.dogFormData.set(dogId, currentData);
  }

  onSubmit(_) {
    this.uploadLoading = true;

    let subscriptions: Observable<any>[] = [];

    this.dogFormData.forEach((data: DogFormData, dogId: number) => {
      const vaxRecordsFormData = new FormData();

      vaxRecordsFormData.append('dog_id', dogId.toString());

      if (data.dateSelected) {
        vaxRecordsFormData.append('expiry_date', data.dateSelected.toString());
      }

      for (let file of data.files) {
        vaxRecordsFormData.append('files[]', file, file.name);
      }

      subscriptions.push(this.customerSrevice.uploadVaccinationRecords(vaxRecordsFormData));
    });

    // Wait for all subscriptions to finish before continuing
    forkJoin([...subscriptions]).subscribe((res) => {
      this.showSuccessDialog();
    });
  }

  showSuccessDialog() {
    const successDialog = this.dialog.open(NotificationMessageComponent, {
      data: { heading: 'Records uploaded!', body: 'Your records were updated successfully.', type: 'success' }
    });

    successDialog.afterClosed().subscribe(() => {
      window.location.reload();
    });
  }

  fetchDogInfo() {
    this.dogService.getDogsSummary().subscribe((res: any) => {
      this.dogData = res.api_dogs;
    });
  }
}
