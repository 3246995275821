<div class="rate-us-dialog">
  <div class="rate-us-dialog_title">
    <h1 class="mat-display-1">
      <strong>You rated us {{data.usersRating}}.<br class="title-break"> Would you like to submit this rating?</strong>
    </h1>
  </div>
  <div class="rate-us-dialog_form">
    <form [formGroup]="form" (ngSubmit)="saveRating(form.value)">
      <mat-form-field hideRequiredMarker="true" floatLabel="never" class="rating_textarea">
        <textarea matInput class="mat-body-1 bddc-dark-blue bold borderless {{form.invalid ? 'invalid' : ''}}" formControlName="customerRating"
          placeholder="Enter comment or feedback here (optional)"></textarea>
      </mat-form-field>
      <mat-error *ngIf="form.invalid && form.controls.customerRating.value.length > 1500">Message cannot be more than 1500 characters.</mat-error>
      <mat-error *ngIf="form.invalid && form.controls.customerRating.errors.htmlExists != null">This message contains invalid content.</mat-error>
      <div mat-dialog-actions class="rate-us-dialog_form-actions">
        <button mat-raised-button color="primary" [disabled]="form.invalid">Submit</button>
        <a mat-raised-button (click)="closeDialog()" class="cancel-button">Cancel</a>
      </div>
    </form>
  </div>
</div>
