import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from 'src/app/core/services/base-api.service';
import { Terms } from 'src/app/shared/interfaces/terms.interface';

@Injectable({
  providedIn: 'root'
})

export class TermsService {
  constructor(private http: HttpClient, private baseApiService: BaseApiService) { }


  getTerms(): Observable<Terms> {
    const apiUrl = '/customer_object/terms_and_conditions';
    return this.http.get<Terms>(this.baseApiService.API_URL + apiUrl);
  }

}
