<ng-container *ngIf="!hasData">
  <div class="u-flex-center">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="hasData">
  <app-page-header headerImage="{{ headerImage }}"></app-page-header>
  <div class="notification-settings">
    <div class="notification-settings_title">
      <h1 class="mat-display-1">Notification settings</h1>
    </div>
    <div class="notification-settings_description">
      <div>
        <p class="mat-body-1">Customise your notification preferences below.</p>
        <p class="mat-body-1">
          <b>Please note: </b>You will always be notified of changes to your
          service requests.
        </p>
      </div>
    </div>
    <div class="edit-button">
      <button *ngIf="!isEdit" mat-raised-button color="primary" class="compact" (click)="editNotifications()">
        Edit
      </button>
      <button *ngIf="isEdit" mat-raised-button color="primary" class="compact" (click)="saveNotifications()">
        Save
      </button>
    </div>
    <div class="notification-settings_form {{ isEdit ? '' : 'read-only' }}">
      <div class="notification-settings_form-section">
        <form [formGroup]="emailForm">
          <mat-checkbox formControlName="emailCheckbox" color="primary" [checked]="emailNotificationsEnabled"
            (change)="emailNotificationsEnabled = !emailNotificationsEnabled">Email</mat-checkbox>
          <div class="notification-settings_form-container {{
              isEdit && emailNotificationsEnabled ? 'enabled' : ''
            }}">
            <ng-container *ngFor="let emailNotification of emailNotifications">
              <div class="notification-settings_form-row">
                <span class="mat-body-1">{{
                  emailNotification.notification_name
                }}</span>
                <mat-slide-toggle color="primary" (change)="checkValue($event, emailNotification, 'email')" [checked]="
                    emailNotificationsEnabled ? emailNotification.active : false
                  "></mat-slide-toggle>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="notification-settings_form-section">
        <form [formGroup]="pushForm">
          <mat-checkbox color="primary" formControlName="pushCheckbox" [checked]="pushNotificationsEnabled"
            (change)="pushNotificationsEnabled = !pushNotificationsEnabled">Push notifications</mat-checkbox>
          <div class="notification-settings_form-container {{
              isEdit && pushNotificationsEnabled ? 'enabled' : ''
            }}">
            <ng-container *ngFor="let pushNotification of pushNotifications">
              <div class="notification-settings_form-row">
                <span class="mat-body-1">{{
                  pushNotification.notification_name
                }}</span>
                <mat-slide-toggle color="primary" (change)="checkValue($event, pushNotification, 'push')" [checked]="
                    pushNotificationsEnabled ? pushNotification.active : false
                  "></mat-slide-toggle>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>