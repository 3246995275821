<div class="service-calendar-dialog">
  <h1 class="{{ titleClass }}">Swap Days Confirmation</h1>
  <p class="mat-body-1 margin-top_10-override">
    Confirm that the dates selected are correct
  </p>
  <p class="mat-body-1">
    <i>Please note that any ad hoc services present on the date you are swapping from will also be requested to be
      swapped</i>
  </p>
  <div class="swap-day-labels">
    <div class="u-flex-column">
      <div class="sub-title-headings">From Date</div>
      <hr class="hr-styling margin-top_10-override margin-bottom_10-override-default" />
      <div class="mat-body-2 u-text-color_gray-blue-dark">
        {{ data.dateFrom }}
      </div>
      <hr class="hr-styling margin-top_10-override margin-bottom_10-override-default" />
    </div>

    <div class="u-flex-column">
      <div class="sub-title-headings">To Date</div>
      <hr class="hr-styling margin-top_10-override margin-bottom_10-override-default" />
      <div class="mat-body-2 u-text-color_gray-blue-dark">
        {{ data.dateTo }}
      </div>
      <hr class="hr-styling margin-top_10-override margin-bottom_10-override-default" />
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="requestService()" [mat-dialog-close]="true">
      SUBMIT REQUEST
    </button>
    <a mat-raised-button (click)="closeDialog()" class="cancel-button medium">Cancel</a>
    <!-- <button mat-raised-button (click)="closeDialog()">
      CANCEL
    </button> -->
  </div>
</div>