<mat-sidenav-container>
  <!-- SideNav -->
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav (sidenavClose)="sidenav.close()"></app-sidenav>
  </mat-sidenav>
  <!-- Header with body -->
  <mat-sidenav-content>
    <app-header (sidenavToggle)="sidenav.toggle()"></app-header>

    <!-- Body of app -->
    <main>
      <!-- All main content will go through the router -->
      <router-outlet></router-outlet>
    </main>
    <!-- Footer -->
    <app-footer *ngIf="!disableFooter"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>