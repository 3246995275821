import { Component, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { NotificationMessageComponent } from 'src/app/shared/components/notification/notification-message/notification-message.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-password-form-card',
  templateUrl: './password-form-card.component.html',
  styleUrls: ['./password-form-card.component.scss']
})
export class PasswordFormCardComponent implements OnDestroy {
  private subscription: Subscription;
  @Output() formObj: EventEmitter<any> = new EventEmitter();
  @Output() formState: EventEmitter<any> = new EventEmitter();
  @Input() heading: string;

  headerImage: string;
  showPasswordValue = false;
  showError = false;
  confirmError = false;
  logoImage = './../../../../../assets/images/bddc-bouncer-logo.png';
  year: number;
  loading = false;
  resetErrorMessage: string;
  private passVal: string;
  private confirmPassVal: string;
  signUp = false;

  resetKey = '';
  showLinkError = false;

  get password() {
    return this.passVal;
  }
  set password(value: string) {
    if (value.trim() !== '') {
      this.showError = false;
    }
    this.passVal = value;
  }
  get confirmPassword() {
    return this.confirmPassVal;
  }
  set confirmPass(value: string) {
    if (value.trim() !== '') {
      this.confirmError = false;
    }
    this.confirmPassVal = value;
  }

  constructor(
    private dialog: MatDialog,
    private router: Router,
    breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private authService: AuthService) {
    this.subscription = breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.headerImage = './../../../../../assets/images/backgrounds/mobile_bg.jpg';
      } else {
        this.headerImage = './../../../../../assets/images/backgrounds/desktop_bg.jpg';
      }
    });
    this.authService.validatResetKey({ key: this.route.snapshot.queryParams.key }).subscribe(result => {
      if (result['reset_link_activated']) {
        this.showLinkError = true;
      }
    });
    this.signUp = this.route.snapshot.queryParams.signUp;

    this.year = new Date().getFullYear();
  }

  validatePassword(password: string): boolean {
    if (password === null) {
      this.showError = true;
      return false;
    }

    if (password.length > 100) {
      this.showError = true;
      return false;
    }

    let hasMatch: RegExpMatchArray = password.match(/<[\/]*[a-z][\s\S]*[\/]*>/i);
    if (hasMatch) {
      this.showError = true;
      return false;
    }

    if (password.trim().length < 8 ||
      !password.match(/(?=.*[0-9])/) ||
      !password.match(/(?=.*[a-z])/) ||
      !password.match(/(?=.*[A-Z])/)
    ) {
      this.showError = true;
      return false;
    } else {
      return true;
    }

  }

  onSubmit(form: NgForm) {
    if ((form.value.password === form.value.passwordConfirm) && !form.invalid && this.validatePassword(form.value.password)) {
      form.value['signUp'] = this.signUp;
      this.formState.emit(true);
      this.showError = false;
      this.confirmError = false;
      this.formObj.emit(form);
    } else {
      if (form.value.password !== form.value.passwordConfirm) {
        this.resetErrorMessage = "Passwords do not match! Make sure both passwords are the same."
      } else {
        this.resetErrorMessage = null;
        this.showError = true;
      }
    }
  }

  showPassword(): void {
    this.showPasswordValue = !this.showPasswordValue;
  }

  goToCookies(): void {
    this.router.navigate(['cookies-public']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
